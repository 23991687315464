/* eslint-disable */
import { put, call } from "redux-saga/effects";
import { CREATE_BLANK_WEEKS_FAILED, CREATE_BLANK_WEEKS_SUCCESS, CREATE_BLOCKS_FAILED, CREATE_BLOCKS_SUCCESS, CREATE_EXERCISE_FAILED, CREATE_EXERCISE_SUCCESS, CREATE_GROUPS_FAILED, CREATE_GROUPS_SUCCESS, CREATE_PROGRAM_DAYS_FAILED, CREATE_PROGRAM_DAYS_SUCCESS, CREATE_PROGRAM_EXERCISE_FAILED, CREATE_PROGRAM_EXERCISE_SUCCESS, CREATE_PROGRAM_FAILED, CREATE_PROGRAM_SUCCESS, CREATE_PROGRAM_WEEKS_FAILED, CREATE_PROGRAM_WEEKS_SUCCESS, DELETE_BLOCKS_FAILED, DELETE_BLOCKS_SUCCESS, DELETE_EXCERSIZE_DATA_FAILED, DELETE_EXCERSIZE_DATA_SUCCESS, DELETE_GROUPS_FAILED, DELETE_GROUPS_SUCCESS, DELETE_PROGRAM_DAYS_FAILED, DELETE_PROGRAM_DAYS_SUCCESS, DELETE_PROGRAM_EXERCISE_FAILED, DELETE_PROGRAM_EXERCISE_SUCCESS, DELETE_PROGRAM_FAILED, DELETE_PROGRAM_SUCCESS, DELETE_PROGRAM_WEEKS_FAILED, DELETE_PROGRAM_WEEKS_SUCCESS, GET_BLOCK_FAILED, GET_BLOCK_SUCCESS, GET_EXERCISE_FAILED, GET_EXERCISE_SUCCESS, GET_GROUPS_FAILED, GET_GROUPS_SUCCESS, GET_LIST_BLOCKS_FAILED, GET_LIST_BLOCKS_SUCCESS, GET_LIST_EXERCISE_FAILED, GET_LIST_EXERCISE_SUCCESS, GET_LIST_GROUPS_FAILED, GET_LIST_GROUPS_SUCCESS, GET_LIST_PROGRAM_DAYS_FAILED, GET_LIST_PROGRAM_DAYS_SUCCESS, GET_LIST_PROGRAM_EXERCISE_FAILED, GET_LIST_PROGRAM_EXERCISE_SUCCESS, GET_LIST_PROGRAM_FAILED, GET_LIST_PROGRAM_SUCCESS, GET_LIST_PROGRAM_WEEKS_FAILED, GET_LIST_PROGRAM_WEEKS_SUCCESS, GET_PROGRAM_DAYS_FAILED, GET_PROGRAM_DAYS_SUCCESS, GET_PROGRAM_EXERCISE_FAILED, GET_PROGRAM_EXERCISE_SUCCESS, GET_PROGRAM_WEEKS_FAILED, GET_PROGRAM_WEEKS_SUCCESS, UPDATE_BLOCKS_FAILED, UPDATE_BLOCKS_SUCCESS, UPDATE_EXCERSIZE_DATA_FAILED, UPDATE_EXCERSIZE_DATA_SUCCESS, UPDATE_GROUPS_FAILED, UPDATE_GROUPS_SUCCESS, UPDATE_PROGRAM_DAYS_FAILED, UPDATE_PROGRAM_DAYS_SUCCESS, UPDATE_PROGRAM_EXERCISE_FAILED, UPDATE_PROGRAM_EXERCISE_SUCCESS, UPDATE_PROGRAM_FAILED, UPDATE_PROGRAM_SUCCESS, UPDATE_PROGRAM_WEEKS_FAILED, UPDATE_PROGRAM_WEEKS_SUCCESS } from "Redux/Action/constant";
import Axios from "../../axiosConfig";

// <-----------------------EXERCISES----------------------------->

export function* CreateExerciseSaga(action) {
    try {
        const response = yield call(Axios.post, `/api/builder/exercises/`, action.payload)
        if (response.status === 201) {
            yield put({
                type: CREATE_EXERCISE_SUCCESS,
                payload: response.data
            })
        }
    } catch (e) {
        yield put({
            type: CREATE_EXERCISE_FAILED,
            payload: action.payload
        })
    }
}

export function* GetListExerciseSaga() {
    try {
        const response = yield call(Axios.get, `/api/builder/exercises/`);
        if (response.status === 200) {
            yield put({
                type: GET_LIST_EXERCISE_SUCCESS,
                data: response?.data
            })
        }
    } catch (e) {
        yield put({
            type: GET_LIST_EXERCISE_FAILED
        })
    }
}

export function* GetExerciseSaga(data) {
    try {
        if (false) {
            yield put({
                type: GET_EXERCISE_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: GET_EXERCISE_FAILED
        })
    }
}

export function* UpdateExerciseSaga(data) {
    try {
        const { id, updateData } = data.payload
        const response = yield call(Axios.put, `/api/builder/exercises/${id}/`, updateData);
        yield put({
            type: UPDATE_EXCERSIZE_DATA_SUCCESS,
            data: response?.data
        })
    } catch (e) {
        yield put({
            type: UPDATE_EXCERSIZE_DATA_FAILED
        })
    }
}

export function* DeleteExerciseSaga(data) {
    try {
        const id = data.payload
        const response = yield call(Axios.delete, `/api/builder/exercises/${id}/`);
        if (response.status === 204) {
            yield put({
                type: DELETE_EXCERSIZE_DATA_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: DELETE_EXCERSIZE_DATA_FAILED
        })
    }
}


// <-----------------------PROGRAM EXERCISES----------------------------->

export function* GetListProgramExerciseSaga() {
    try {
        const response = yield call(Axios.get, `/api/builder/program_exercises/`);
        if (response.status === 200) {
            yield put({
                type: GET_LIST_PROGRAM_EXERCISE_SUCCESS,
                payload: response?.data
            })
        }
    } catch (e) {
        yield put({
            type: GET_LIST_PROGRAM_EXERCISE_FAILED
        })
    }
}

export function* GetProgramExerciseSaga(data) {
    try {
        if (false) {
            yield put({
                type: GET_PROGRAM_EXERCISE_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: GET_PROGRAM_EXERCISE_FAILED
        })
    }
}

export function* CreateProgramExerciseSaga(data) {
    try {
        const response = yield call(Axios.post, `/api/builder/program_exercises/`, data.payload);
        yield put({
            type: CREATE_PROGRAM_EXERCISE_SUCCESS,
            payload: response.data
        })
    } catch (e) {
        yield put({
            type: CREATE_PROGRAM_EXERCISE_FAILED
        })
    }
}

export function* UpdateProgramExerciseSaga(data) {
    try {
        const { id, updateData } = data.payload
        const response = yield call(Axios.put, `/api/builder/program_exercises/${id}/`, updateData);
        yield put({
            type: UPDATE_PROGRAM_EXERCISE_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: UPDATE_PROGRAM_EXERCISE_FAILED
        })
    }
}

export function* DeleteProgramExerciseSaga(data) {
    try {
        const id = data.payload
        const response = yield call(Axios.delete, `/api/builder/program_exercises/${id}/`);
        if (response.status === 204) {
            yield put({
                type: DELETE_PROGRAM_EXERCISE_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: DELETE_PROGRAM_EXERCISE_FAILED
        })
    }
}

// <-----------------------BLOCKS----------------------------->

export function* GetListBlocksSaga() {
    try {
        const response = yield call(Axios.get, `/api/builder/blocks/`);
        if (response.status === 200) {
            yield put({
                type: GET_LIST_BLOCKS_SUCCESS,
                payload: response?.data
            })
        }
    } catch (e) {
        yield put({
            type: GET_LIST_BLOCKS_FAILED
        })
    }
}

export function* GetBlockSaga(data) {
    try {
        if (false) {
            yield put({
                type: GET_BLOCK_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: GET_BLOCK_FAILED
        })
    }
}

export function* CreateBlockSaga(data) {
    try {
        const response = yield call(Axios.post, `/api/builder/blocks/`, data.payload);
        yield put({
            type: CREATE_BLOCKS_SUCCESS,
            payload: response.data
        })
    } catch (e) {
        yield put({
            type: CREATE_BLOCKS_FAILED
        })
    }
}

export function* UpdateBlockSaga(data) {
    try {
        const { id, updateData } = data.payload
        const response = yield call(Axios.put, `/api/builder/blocks/${id}/`, updateData);
        yield put({
            type: UPDATE_BLOCKS_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: UPDATE_BLOCKS_FAILED
        })
    }
}

export function* DeleteBlockSaga(data) {
    try {
        const id = data.payload
        const response = yield call(Axios.delete, `/api/builder/blocks/${id}/`);
        if (response.status === 204) {
            yield put({
                type: DELETE_BLOCKS_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: DELETE_BLOCKS_FAILED
        })
    }
}

// <-----------------------GROUPS----------------------------->

export function* GetListGroupsSaga() {
    try {
        const response = yield call(Axios.get, `/api/builder/groups/`);
        if (response.status === 200) {
            yield put({
                type: GET_LIST_GROUPS_SUCCESS,
                payload: response?.data
            })
        }
    } catch (e) {
        yield put({
            type: GET_LIST_GROUPS_FAILED
        })
    }
}

export function* GetGroupsSaga(data) {
    try {
        if (false) {
            yield put({
                type: GET_GROUPS_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: GET_GROUPS_FAILED
        })
    }
}

export function* CreateGroupsSaga(data) {
    try {
        const response = yield call(Axios.post, `/api/builder/groups/`, data.payload);
        yield put({
            type: CREATE_GROUPS_SUCCESS,
            payload: response.data
        })
    } catch (e) {
        yield put({
            type: CREATE_GROUPS_FAILED
        })
    }
}

export function* UpdateGroupsSaga(data) {
    try {
        const { id, updateData } = data.payload
        const response = yield call(Axios.put, `/api/builder/groups/${id}/`, updateData);
        yield put({
            type: UPDATE_GROUPS_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: UPDATE_GROUPS_FAILED
        })
    }
}

export function* DeleteGroupsSaga(data) {
    try {
        const id = data.payload
        const response = yield call(Axios.delete, `/api/builder/groups/${id}/`);
        if (response.status === 204) {
            yield put({
                type: DELETE_GROUPS_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: DELETE_GROUPS_FAILED
        })
    }
}

// <--------------------------PROGRAMS------------------------------>

export function* GetListProgramSaga() {
    try {
        const response = yield call(Axios.get, `/api/metrics/program/`);
        if (response.status === 200) {
            yield put({
                type: GET_LIST_PROGRAM_SUCCESS,
                payload: response?.data
            })
        }
    } catch (e) {
        yield put({
            type: GET_LIST_PROGRAM_FAILED
        })
    }
}

export function* CreateProgramSaga(data) {
    try {
        const response = yield call(Axios.post, `/api/metrics/program/`, data.payload);
        yield put({
            type: CREATE_PROGRAM_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: CREATE_PROGRAM_FAILED
        })
    }
}

export function* UpdateProgramSaga(data) {
    try {
        const { id, updateData } = data.payload
        const response = yield call(Axios.put, `/api/metrics/program/${id}/`, updateData);
        yield put({
            type: UPDATE_PROGRAM_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: UPDATE_PROGRAM_FAILED
        })
    }
}

export function* DeleteProgramSaga(data) {
    try {
        const id = data.payload
        const response = yield call(Axios.delete, `/api/metrics/program/${id}/`);
        if (response.status === 204) {
            yield put({
                type: DELETE_PROGRAM_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: DELETE_PROGRAM_FAILED
        })
    }
}

// <-----------------------PROGRAM DAYS----------------------------->

export function* GetListProgramDaysSaga() {
    try {
        const response = yield call(Axios.get, `/api/builder/program_days/`);
        if (response.status === 200) {
            yield put({
                type: GET_LIST_PROGRAM_DAYS_SUCCESS,
                payload: response?.data
            })
        }
    } catch (e) {
        yield put({
            type: GET_LIST_PROGRAM_DAYS_FAILED
        })
    }
}

export function* GetProgramDaysSaga(data) {
    try {
        if (false) {
            yield put({
                type: GET_PROGRAM_DAYS_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: GET_PROGRAM_DAYS_FAILED
        })
    }
}

export function* CreateProgramDaysSaga(data) {
    try {
        const response = yield call(Axios.post, `/api/builder/program_days/`, data.payload);
        yield put({
            type: CREATE_PROGRAM_DAYS_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: CREATE_PROGRAM_DAYS_FAILED
        })
    }
}

export function* UpdateProgramDaysSaga(data) {
    try {
        const { id, updateData } = data.payload
        const response = yield call(Axios.put, `/api/builder/program_days/${id}/`, updateData);
        yield put({
            type: UPDATE_PROGRAM_DAYS_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: UPDATE_PROGRAM_DAYS_FAILED
        })
    }
}

export function* DeleteProgramDaysSaga(data) {
    try {
        const id = data.payload
        const response = yield call(Axios.delete, `/api/builder/program_days/${id}/`);
        if (response.status === 204) {
            yield put({
                type: DELETE_PROGRAM_DAYS_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: DELETE_PROGRAM_DAYS_FAILED
        })
    }
}

// <-----------------------PROGRAM WEEKS----------------------------->

export function* GetListProgramWeeksSaga() {
    try {
        const response = yield call(Axios.get, `/api/builder/program_weeks/`);
        if (response.status === 200) {
            yield put({
                type: GET_LIST_PROGRAM_WEEKS_SUCCESS,
                payload: response?.data
            })
        }
    } catch (e) {
        yield put({
            type: GET_LIST_PROGRAM_WEEKS_FAILED
        })
    }
}

export function* GetProgramWeeksSaga(data) {
    try {
        if (false) {
            yield put({
                type: GET_PROGRAM_WEEKS_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: GET_PROGRAM_WEEKS_FAILED
        })
    }
}

export function* CreateProgramWeeksSaga(data) {
    try {
        const response = yield call(Axios.post, `/api/builder/program_weeks/`, data.payload);
        yield put({
            type: CREATE_PROGRAM_WEEKS_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: CREATE_PROGRAM_WEEKS_FAILED
        })
    }
}
export function* CreateBlankProgramWeeksSaga(data) {
    try {
        const response = yield call(Axios.post, `/api/builder/program_weeks/`, data.payload);
        yield put({
            type: CREATE_BLANK_WEEKS_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: CREATE_BLANK_WEEKS_FAILED
        })
    }
}

export function* UpdateProgramWeeksSaga(data) {
    try {
        const { id, updateData } = data.payload
        const response = yield call(Axios.put, `/api/builder/program_weeks/${id}/`, updateData);
        yield put({
            type: UPDATE_PROGRAM_WEEKS_SUCCESS,
            payload: response?.data
        })
    } catch (e) {
        yield put({
            type: UPDATE_PROGRAM_WEEKS_FAILED
        })
    }
}

export function* DeleteProgramWeeksSaga(data) {
    try {
        const id = data.payload
        const response = yield call(Axios.delete, `/api/builder/program_weeks/${id}/`);
        if (response.status === 204) {
            yield put({
                type: DELETE_PROGRAM_WEEKS_SUCCESS,
                payload: data
            })
        }
    } catch (e) {
        yield put({
            type: DELETE_PROGRAM_WEEKS_FAILED
        })
    }
}


