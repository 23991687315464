function getExercisesMapByGroups(exercises) {
  const exercisesMap = new Map()
  exercises.forEach((exercise) => {
    if (exercisesMap.has(exercise.group)) {
      exercisesMap.get(exercise.group).push(exercise)
    } else {
      exercisesMap.set(exercise.group, [exercise])
    }
  })

  return exercisesMap
}

export default function getExercisesMapByGroupsAndBlocks(exercises) {
  const exercisesMapByGroups = getExercisesMapByGroups(exercises)

  exercisesMapByGroups.forEach((exerciseItems, group) => {
    const blockMap = new Map()

    exerciseItems.forEach((exercise) => {
      if (blockMap.has(exercise.block)) {
        blockMap.get(exercise.block).push(exercise)
      } else {
        blockMap.set(exercise.block, [exercise])
      }
    })

    exercisesMapByGroups.get(group).blockMap = blockMap
  })

  return exercisesMapByGroups
}
