import React from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useDispatch } from "react-redux";
import { CreateProgramExerciseAction } from "../../Redux/Action/programBuilderAct"

function CopyRenderer ({ data }) {
  const dispatch = useDispatch()

  const buttonClicked = () => {

    const payload = {
        exercise_pk: data.exercise_pk,
        exercise: data.exercise,
        set_number: Number(data.set_number),
        weight_lbs: Number(data.weight_lbs),
        distance_meters: Number(data.distance_meters),
        reps: Number(data.reps),
        reps_in_reserve: Number(data.reps_in_reserve),
        one_rep_max_percent: Number(data.one_rep_max_percent),
        tempo: data.tempo,
        work_time_seconds: Number(data.work_time_seconds),
        rest_time_seconds: Number(data.rest_time_seconds),
        notes: data.notes,
        kpi: data.kpi,
        block_id: data.block_id,
        source: data?.source
    }
      dispatch(CreateProgramExerciseAction(payload))
  }

  return (
    <span>
      <ContentCopyIcon style={{ color: "teal" }} onClick={() => buttonClicked()} />
    </span>
  );
};

export default CopyRenderer
