/* eslint-disable */
import { useEffect, useState } from "react";
import SearchIcon from '@material-ui/icons/Search';
import { Box, Divider, Modal, TextField, Button, CircularProgress, Chip } from "@material-ui/core";
import { useFormik } from 'formik';
import TablePagination from '@material-ui/core/TablePagination';
import CustomCheckbox from "./CustomCheckbox";
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Close'
import { useDispatch, useSelector } from "react-redux";
import { FetchExcersizedata, deleteExcersize, EditExcersizedata, GetListExerciseAction, CreateExerciseAction, DeleteExerciseAction, UpdateExerciseAction } from "Redux/Action/programBuilderAct";
import { Widgets } from "@material-ui/icons"
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete'

const exerciseData = []
const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    DemoLink: Yup.string()
        .min(2, 'Too Short!')
        .url("Demo Link must be a valid URL")
        .notRequired()
        .nullable(),
    Description: Yup.string()
        .max(250, 'Too Long!')
        .notRequired(),
});

function SidePanelCheckBox({ label, key, onChange, value }) {
    return (
        <CustomCheckbox
            textStyle={{
                py: 1.5,
                px: 3,
                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            }}
            key={key}
            value={value}
            label={label}
            onChange={onChange}
        />
    )
};

function SidebarExerciseTab() {
    const dispatch = useDispatch();
    const { isDeleteExercise, isCreatedExercise, exerciseInfo, isExerciseUpdated } = useSelector(state => state.rootReducer.programBuilder)
    const [open, setOpen] = useState(false);
    const [editdata, setEditdata] = useState({});
    const [deleteIdList, setDeleteIdList] = useState(null)
    const [searchInput, setSearchInput] = useState("")
    const [postCreation, setPostCreation] = useState(false)
    const [tagValue, setTagValue] = useState(null)

    let list = []

    const handleOpen = () => {
        setEditdata({})
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
        setTagValue('')
        formik.resetForm()
    }

    const handleAddTag = () => {
        if(tagValue){
            if (editdata?.id) {
                if (formik.values.name) {
                    editdata?.tags?.push(tagValue)
                    const payload = {
                        name: formik.values.name,
                        video: formik.values.DemoLink,
                        description: formik.values.Description,
                        tags: editdata?.tags,
                    }
                    dispatch(UpdateExerciseAction({ updateData: payload, id: editdata.id }))
                    formik.setFieldValue("Tags", '')
                    setTagValue('')
                } else {
                    alert('Add a exercise name before adding tags')
                }
            } else {
                if (formik.values.name) {
                    const payload = {
                        name: formik.values.name,
                        video: formik.values.DemoLink,
                        description: formik.values.Description,
                        tags: [tagValue],
                    }
                    dispatch(CreateExerciseAction(payload))
                    formik.setFieldValue("Tags", '')
                    setPostCreation(true)
                    setTagValue('')
                } else {
                    alert('Add a exercise name before adding tags')
                }
            }
            dispatch(GetListExerciseAction())
        }
    }

    const handleSearch = (e) => {
      e.preventDefault();
      setSearchInput(e.target.value);
    }
    const handleClearSearchInput = () => {
      setSearchInput('');
    }

    if (searchInput.length > 0) {
        list = exerciseInfo.data.filter((exercise) => {
            RegExp.quote = function(str) {
                 return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
            }
            if (exercise.name?.match(new RegExp(RegExp.quote(searchInput), "i"))){
                return exercise.name?.match(new RegExp(RegExp.quote(searchInput), "i"))
            }
            for (let i = 0; i < exercise.tags.length; i++) {
              if (exercise.tags[i]?.match(new RegExp(RegExp.quote(searchInput), "i"))) {
                return exercise.tags[i]?.match(new RegExp(RegExp.quote(searchInput), "i"))
              }
            }
        })
    } else {
        list = exerciseInfo.data
    }

    useEffect(() => {
        dispatch(GetListExerciseAction())
    }, [])

    useEffect(() => {
        if (!isDeleteExercise) {
            setDeleteIdList(null)
        }
    }, [isDeleteExercise])
    useEffect(() => {
        if (postCreation) {
            const list = exerciseInfo?.data?.map((item) => Number(item?.id))
            const newId = Math.max(...list)
            let findObj =  exerciseInfo?.data?.find(item => item?.id === newId)
            setEditdata(findObj)
            setPostCreation(false)
        }
    }, [exerciseInfo?.data?.length])

    const deleteExcersidedata = (id) => {
        setDeleteIdList(id)
        dispatch(DeleteExerciseAction(id))
    }

    const deleteTag = (id) => {
        editdata?.tags?.splice(id,1)
        const payload = {
                name: formik.values.name,
                video: formik.values.DemoLink,
                description: formik.values.Description,
                tags: editdata?.tags,
            }
        dispatch(UpdateExerciseAction({ updateData: payload, id: editdata?.id }))
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            DemoLink: "",
            Description: "",
            Tags: "",
        },
        onSubmit: values => {
            const payload = {
                name: values.name,
                video: values.DemoLink,
                description: values.Description,
                tags: editdata?.tags || [],
            }
            if (editdata?.id) {
                dispatch(UpdateExerciseAction({ updateData: payload, id: editdata.id }))
            } else {
                dispatch(CreateExerciseAction(payload))
                handleClose()
            }
            handleClose()
        },
        validationSchema
    });

    const editModalFun = (data) => {
        setEditdata(data);
        setOpen(true);
        formik.setFieldValue("name", data?.name)
        formik.setFieldValue("DemoLink", data?.video)
        formik.setFieldValue("Description", data?.description)
    }
    function handleAutocompleteChange(event, value) {
        setTagValue(value)
      }

    return (
        <SidebarExerciseTab.Stylesheet>
            <div>
                <Box className="sidebar-search" style={{padding: '0px'}}>
                    <TextField
                        fullWidth
                        onChange={handleSearch}
                        value={searchInput}
                        style={{
                            backgroundColor: "#0000000A",
                            color: "#0000003D",
                        }}
                        placeholder="search"
                        InputProps={{
                            startAdornment: <SearchIcon style={{
                                paddingRight: 1,
                                fill: "#000000",
                                opacity: 0.2,
                                height: '2em',
                                marginRight: '10px' }} />,
                            disableUnderline: true,
                            endAdornment: <DeleteIcon style={{ color: "teal" }} onClick={handleClearSearchInput} />,
                        }}
                    />
                </Box>
                <Divider />
                <Box style={{maxHeight: '70vh', overflow: 'auto'}}>
                    {
                        list?.length === undefined ?
                            (
                                <Box style={{ display: "flex", padding: "100px", alignItems: "center", justifyContent: "center" }}>
                                    <CircularProgress />
                                </Box>
                            ) :
                            (
                                <>
                                    {
                                        list?.map((item, index) => (
                                            <>
                                                <div key={index} label={item?.name}
                                                    style={{
                                                        py: 1.5,
                                                        px: 3,
                                                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "space-between",
                                                        paddingLeft: "24px"
                                                    }}
                                                    value={item?.name} >
                                                    <div style={{ cursor: "pointer" }} onClick={() => editModalFun(item)}>
                                                        {item?.name}
                                                    </div>
                                                    {isDeleteExercise && (deleteIdList == item.id) ? <CircularProgress
                                                        size={30}
                                                    /> :
                                                        (<button onClick={() => deleteExcersidedata(item.id)} className="btn btn-default">
                                                            <DeleteIcon style={{ color: "teal" }} />
                                                        </button>)
                                                    }
                                                </div>
                                            </>
                                        ))
                                    }
                                </>)}
                </Box>
                <Box className="sidebar-bottom">
                    <Box>
                        <Box
                            style={{
                                textAlign: "center",
                                padding: "32px 0",
                                lineHeight: "normal",
                            }}
                        >
                            <Button className="text-primary" onClick={handleOpen}>+ CREATE</Button>
                        </Box>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        >
                            <Box
                                style={{
                                    backgroundColor: "white",
                                    border: "2px solid #000",
                                    boxShadow: 24,
                                    padding: '30px 30px 0px 30px',
                                    minWidth: '40vw',
                                    overflow: 'scroll'
                                }}
                            >
                                {
                                    editdata?.id ? <h3>Edit Exercise</h3> : <h3>Create Exercise</h3>
                                }
                                <form onSubmit={formik.handleSubmit}>
                                    <TextField
                                        fullWidth
                                        name="name"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        style={{ marginTop: "10px" }}
                                        type="text"
                                        label="Name"
                                        variant="filled"
                                        error={formik.errors.name && formik.touched.name}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                    <br />
                                    <TextField
                                        type="text"
                                        name="DemoLink"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        value={formik.values.DemoLink}
                                        style={{ marginTop: "10px" }}
                                        label="Demo Link"
                                        variant="filled"
                                        error={formik.errors.DemoLink && formik.touched.DemoLink}
                                        helperText={formik.touched.DemoLink && formik.errors.DemoLink}
                                    />
                                    <br />
                                    <TextField
                                        type="text"
                                        name="Description"
                                        fullWidth
                                        onChange={formik.handleChange}
                                        value={formik.values.Description}
                                        style={{ marginTop: "10px", marginBottom: "10px" }}
                                        multiline
                                        placeholder="Please type Description for the Exercise....."
                                        rows={4}
                                        label="Description"
                                        variant="filled"
                                        error={formik.errors.Description && formik.touched.Description}
                                        helperText={formik.touched.Description && formik.errors.Description}
                                    />
                                    <br />
                                    {editdata?.tags?.map((tag, index) => (
                                        <Chip label={tag} onDelete={() => deleteTag(index)}/>
                                      ))}
                                    <br />
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
                                    <Autocomplete
                                        options={exerciseInfo?.tagList}
                                        onInputChange={handleAutocompleteChange}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Tags"
                                                name="tags"
                                                type='text'
                                                variant='filled'
                                                value={tagValue}
                                            />}
                                        value={tagValue}
                                        style={{width: 600, marginRight: 20}}
                                      />

                                    <Button
                                        variant="text"
                                        variant="contained"
                                        color="primary"
                                        onClick={handleAddTag}
                                        style={{zoom: '1.1'}}
                                        disabled={!tagValue}
                                    >
                                        Add
                                    </Button>
                                    </div>
                                    <br />
                                    <Box style={{ textAlign: 'right', margin: '12px 0px 14px 0px' }} >
                                        <Button variant="text" style={{ color: '#0000008A', marginRight: "14px" }} onClick={handleClose}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" color="primary" type="submit">
                                            {
                                                isCreatedExercise || isExerciseUpdated ?
                                                    <CircularProgress size={20} color="white" />
                                                    : "Save"
                                            }
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Modal>
                    </Box>
                </Box >
            </div >
        </SidebarExerciseTab.Stylesheet>
    )
}

SidebarExerciseTab.Stylesheet = styled.div`
    .MuiInputBase-input{
        font-size: 30px
    }
`
export default SidebarExerciseTab
