import React from 'react'
import Layout from 'common/components/Layout'
import Analytics from '../components/Analytics'

function AnalyticsDashboard() {
    return (
        <Layout>
            <Analytics />
        </Layout>
    )
}

export default AnalyticsDashboard
