import React, { useEffect } from 'react'
import { Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { useFeatureFlags, UserProvider } from 'account'
import { Activity } from 'activity'
import { AuthProvider, SubscribedRoute } from 'auth'
import BackdropLoader from 'common/components/BackdropLoader'
import { API_URL } from 'common/env'
import useApi from 'common/hooks/useApi'
import { FAQs } from 'faqs'
import { ProgramBuilder } from 'programBuilder'
import { Reports } from 'reports'
import NotFound from 'static/pages/NotFound'
import { Pricing } from 'pricing'
import Privacy from 'static/pages/Privacy'
import { AthleteReport } from 'athleteReport'
import { Athletes } from 'athletes'
import { Trainers } from 'trainers'
import { Uploads } from 'uploads'
import { OneRepMaxTable } from 'oneRepMaxTable'
import { Analytics } from 'analytics'
import DataTableContainer from 'activity/components/DataTableContainer'

function AuthCallback() {
  return <BackdropLoader open />
}

function FitbitCallback() {
  const query = window.location.search

  const [{ data = {} }, callback] = useApi(`fitbit/callback/${query}`, {
    manual: true
  })

  useEffect(() => {
    callback()
  }, [])

  if (data?.redirectUri) {
    window.location.replace(data?.redirectUri)
  }

  return <BackdropLoader open />
}

function RedirectToV1() {
  const location = useLocation()
  const path = location.pathname.replace(/\/?$/, '/')
  window.location.href = `${API_URL}${path}`
  return null
}

function Router() {
  const featureFlags = useFeatureFlags()
  const history = useHistory()

  useEffect(() => {
    history.listen(() => {
      if (window.ga) {
        window.ga('set', 'page', window.location.pathname)
        window.ga('send', 'pageview')
      }
    })
  }, [history])

  return (
    <AuthProvider>
      <UserProvider>
        <Switch>
          <SubscribedRoute exact path="/" component={Activity} />
          {featureFlags.activity && (
            <SubscribedRoute
              exact
              path="/program/activity"
              component={Activity}
            />
          )}
          <SubscribedRoute exact path="/reports" component={Reports} />
          <SubscribedRoute exact path="/uploads" component={Uploads} />
          <Route exact path="/programbuilder" component={ProgramBuilder} />
          <Route exact path="/callback" component={AuthCallback} />
          <Route path="/connect" component={RedirectToV1} />
          <Route path="/faqs" component={FAQs} />
          <Route path="/oauth/fitbit/callback" component={FitbitCallback} />
          <SubscribedRoute exact path="/pricing/:id" component={Pricing} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/athleteReport/:id" component={AthleteReport} />
          <SubscribedRoute exact path="/myReport" component={Athletes} />
          <SubscribedRoute exact path="/activityLogs" component={DataTableContainer} />
          <SubscribedRoute exact path="/trainerDashboard" component={Trainers} />
          <Route exact path="/onerepmaxtable" component={OneRepMaxTable} />
          <Route exact path="/analytics" component={Analytics} />
          <Route path="*" component={NotFound} />
        </Switch>
      </UserProvider>
    </AuthProvider>
  )
}

export default Router
