import { useEffect } from 'react'

export default function useComponentLifeCycle() {
  let isComponentMounted = true

  useEffect(() => {
    return () => {
      isComponentMounted = false
    }
  }, [])

  return { isComponentMounted }
}
