import { createTheme } from '@material-ui/core/styles'

export default createTheme({
  palette: {
    primary: {
      contrastText: '#FFF',
      main: '#0B545E'
    },
    secondary: {
      main: '#0B545E',
      g800: '#09555D'
    },
    warning: {
      main: '#FF7262'
    },
  },
  zIndex: {
    backdrop: 9998
  }
})
