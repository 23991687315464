import React from 'react'
import Layout from 'common/components/Layout'
import FAQs from '../components/FAQs'

function FAQsPage() {
  return (
    <Layout>
      <FAQs />
    </Layout>
  )
}

export default FAQsPage
