import { ActivityLogContext, programCalendarAction } from 'activity/ducks'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

export default function useProgramCalendar({ loading, data }) {
  const DATE_WIDTH = 50

  const [dateStartPoint, setDateStartPoint] = useState(0)
  const [dateLimit, setDateLimit] = useState(
    Math.floor(window.innerWidth / DATE_WIDTH)
  )
  const [dateSelected, setDateSelected] = useState(undefined)
  const [dateSynced, setDateSynced] = useState(false)

  const { dispatch } = useContext(ActivityLogContext)

  const today = new Date()
  const currentMonth = today.toLocaleString('default', { month: 'long' })
  const currentYear = today.getFullYear()

  const adjustDateCount = useCallback(() => {
    const updateDateLimit = () => {
      setDateLimit(Math.floor(window.innerWidth / DATE_WIDTH))
    }

    window.addEventListener('resize', updateDateLimit)

    return () => window.removeEventListener('resize', updateDateLimit)
  }, [])

  useEffect(adjustDateCount, [])

  const currentDate = {
    month: currentMonth,
    year: currentYear
  }

  const dayMap = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat'
  }

  const monthMap = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
  }

  const checkDateMatchesSelected = (date) => {
    return (
      dateSelected.date === date.getDate() &&
      dateSelected.month === date.getMonth()
    )
  }

  const checkDateIsToday = (date) => {
    return (
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    )
  }

  const programDateSet = useMemo(() => {
    const set = new Set()
    if (data) {
      Object.values(data.data).forEach(program => {
        Object.values(program).forEach(weeks => {
          Object.values(weeks).forEach(days => {
            Object.values(days).forEach(day => {
              const startDate = new Date(day.start_date).toDateString()
              if (!set.has(startDate)) {
                set.add(startDate)
              }
            })
          })
        })
      })
    }
    return set
  }, [data])

  const parseDate = (day, idx) => {
    const date = new Date(day)
    let isDateSelected = false
    if (!dateSelected) {
      if (checkDateIsToday(date)) {
        isDateSelected = true
      }
    } else if (checkDateMatchesSelected(date)) {
      isDateSelected = true
    }

    const hasPrograms = programDateSet.has(date.toDateString())

    // Good to know - month is zero based (i.e. January = 0, February = 1, etc.)
    return {
      day: dayMap[date.getDay()],
      month: date.getMonth(),
      date: date.getDate(),
      year: date.getFullYear(),
      isSelected: isDateSelected,
      idx,
      hasPrograms
    }
  }

  const dateEndpoints = useMemo(() => {
    if (!loading && data?.data) {
      const programList = Object.values(data.data)
      let startDate
      let endDate
      programList.forEach((program) => {
        const startWeek = program[1]
        const exerciseStartDate = new Date(startWeek[1]?.[0]?.start_date)
        if (!startDate && exerciseStartDate) {
          startDate = exerciseStartDate
        } else if (startDate > exerciseStartDate) {
          startDate = exerciseStartDate
        }

        delete program.program_description
        delete program.program
        const weeks = Object.values(program)
        const lastWeekObj = weeks[weeks.length - 1]
        const lastWeekExercises = Object.values(lastWeekObj)
        let lastWeekExercise = lastWeekExercises.pop()
        while (lastWeekExercise?.length === 0) {
          lastWeekExercise = lastWeekExercises.pop()
        }
        const exerciseEndDate = new Date(lastWeekExercise?.[0]?.start_date)
        if (!endDate) {
          endDate = exerciseEndDate
        } else if (exerciseEndDate > endDate) {
          endDate = exerciseEndDate
        }
      })

      return { startDate, endDate }
    }

    return {}
  }, [loading, data])

  const getDateRange = (startDate, endDate) => {
    const dateRange = []
    if (startDate > endDate) {
      return dateRange
    }

    const newDate = new Date(startDate)
    while (newDate <= endDate) {
      dateRange.push(new Date(newDate))
      newDate.setDate(newDate.getDate() + 1)
    }

    return dateRange
  }

  const dateRange = useMemo(
    () =>
      getDateRange(dateEndpoints.startDate, dateEndpoints.endDate).map(
        parseDate
      ),
    [dateEndpoints, dateSelected]
  )

  const datesData = useMemo(() => {
    return dateRange.slice(dateStartPoint, dateStartPoint + dateLimit)
  }, [dateEndpoints, dateRange, dateStartPoint, dateLimit])

  const showNextWeek = () => {
    if (dateRange.length - dateStartPoint > dateLimit) {
      setDateStartPoint(dateStartPoint + dateLimit)
    }
  }

  const showPreviousWeek = () => {
    if (dateStartPoint > 0) {
      setDateStartPoint(dateStartPoint - dateLimit)
    }
  }

  const selectDate = (date) => {
    date.weekCount = Math.floor(date.idx / 7) + 1
    date.dayCount = (date.idx % 7) + 1
    date.stringMonth = monthMap[date.month]
    setDateSelected(date)
    const dateObj = new Date(date.year, date.month, date.date)
    dispatch(programCalendarAction({ dateSelected: { ...date }, dateObj }))
  }

  useEffect(() => {
    if (!dateSelected && dateRange.length > 0) {
      const dateToday = dateRange.filter((date) => date.isSelected)[0]
      if (dateToday) {
        dateToday.weekCount = Math.floor(dateToday.idx / 7) + 1
        dateToday.dayCount = (dateToday.idx % 7) + 1
        setDateSelected(dateToday)
        const dateObj = new Date(
          dateToday.year,
          dateToday.month,
          dateToday.date
        )
        dispatch(
          programCalendarAction({ dateSelected: { ...dateToday }, dateObj })
        )
      }
    }
  }, [dateRange])

  useEffect(() => {
    if (dateRange.length > 0 && dateSelected && !dateSynced) {
      setDateSynced(true)
      setDateStartPoint(Math.floor(dateSelected.idx / dateLimit) * dateLimit)
    }
  }, [dateRange, dateSynced, dateSelected])

  return {
    currentDate,
    datesData,
    showNextWeek,
    showPreviousWeek,
    selectDate,
    dateSelected
  }
}
