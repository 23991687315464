/* eslint-disable */
import React from "react";
import DeleteIcon from '@material-ui/icons/Close'
import { useDispatch } from "react-redux";
import { DeleteProgramExerciseAction, UpdateBlocksAction } from "../../Redux/Action/programBuilderAct"

function BtnCellRenderer ({ api, data }) {
  const dispatch = useDispatch()

  const buttonClicked = () => {
    dispatch(DeleteProgramExerciseAction(data.id))
    if (api) {
        api.applyTransaction({ remove: [data]})
        let exercises_pk = []
        api.forEachNode((rowNode) => {
            exercises_pk.push(rowNode.data.id)
        })
        let payload = {
            name: data.block_name,
            secondary_name: data.block_secondary_name,
            exercises_pk: exercises_pk,
            description: data.block_description,
            source: data?.source,
            archived: data.block_archived,
        }
        dispatch(UpdateBlocksAction({ updateData: payload, id: data.block_id }))
    }
  }

  return (
    <span>
      <DeleteIcon style={{ color: "teal" }} onClick={() => buttonClicked()} />
    </span>
  );
};

export default BtnCellRenderer
