import React, {useContext, useEffect} from 'react';
import { Box, Modal, Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add"
import { useDispatch, useSelector } from 'react-redux';
import useApi from 'common/hooks/useApi'
import { LayoutContext} from 'common/ducks'
import {  CreateBlankProgramWeeksAction, GetListProgramWeeksAction, GetListProgramDaysAction } from 'Redux/Action/programBuilderAct';

function AddWeek(props) {
    const dispatch = useDispatch();
    const { programs ,programID, programWeekInfo} = useSelector(state => state.rootReducer.programBuilder)
    const { addCurrentWeek } = props
    const [open, setOpen] = React.useState(false);
    const { setAppLoading } = useContext(LayoutContext)

    const handleOpen = () => {
        setOpen(true);
    }

    const [{ loading: copyLoading }, copyWeek] = useApi(
            {
              method: 'POST',
              url: 'builder/program_weeks/copy_week/',
            },
            { manual: true }
          )

    useEffect(() => {
      setAppLoading(copyLoading)
    }, [setAppLoading, copyLoading])

    const Id= programWeekInfo.data.length>0 ? programWeekInfo?.data.slice(-1)[0].program : null;

    function createWeekInCurrent() {
        if (programID) {
        const body = {
            id: addCurrentWeek.currentWeek,
            program: programs.data.length > 0 ? (programID!==null ? programID : Id ) : null,
            }
        copyWeek({
            data: body,
        }).then((response) => {
            if(response.status === 200){
                dispatch(GetListProgramWeeksAction())
                dispatch(GetListProgramDaysAction())
                setOpen(false)
            }
        })
        } else {
            alert('Select a Program First')
        }
    }

    const createtWeekWithBlank = () => {
        if (programID){
        const payload = {
                program: programs.data.length > 0 ? (programID!==null ? programID : Id ) : null
            }
        dispatch(CreateBlankProgramWeeksAction(payload))
        setTimeout(() => {
            dispatch(GetListProgramWeeksAction())
        }, 500);
        setOpen(false);
        } else {
            alert('Select a Program First')
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <Box>
            <Box
                style={{
                    textAlign: "center",
                    padding: "32px 0",
                    lineHeight: "normal",
                }}
            >
                <Button className="save_button" onClick={handleOpen}><Add /></Button>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                disableEnforceFocus
            >
                <Box
                    style={{
                        backgroundColor: "white",
                        border: "2px solid #000",
                        boxShadow: 24,
                        padding: '30px 30px 5px 30px',
                        minWidth: '40vw',
                        overflow: 'scroll'
                    }}
                >
                    <h2 >Add a Week</h2>
                    <Box style={{ textAlign: 'center', margin: '8vh 0 10px 0' }}>
                        <Button variant="outlined" color="primary" onClick={() => createtWeekWithBlank()} style={{ marginLeft: "2vw", width: "10vw", border: "2px solid #0B545E" }}>
                            Start<br />Blank
                        </Button>
                        <Button variant="contained" color="primary" onClick={()=> createWeekInCurrent()} style={{ marginLeft: "2vw", width: "10vw" }}>
                            Use<br />Current
                        </Button>
                    </Box>
                    <Box style={{ textAlign: 'right', marginTop: "8vh" }}>
                        <Button variant="text" style={{ color: '#0000008A' }} onClick={handleClose}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Modal >
        </Box >
    )
}


export default AddWeek
