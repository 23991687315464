import React from 'react'
import MUIDialog from '@material-ui/core/Dialog'
import MUIDialogContent from '@material-ui/core/DialogContent'
import styled from 'styled-components'
import theme from 'styles/theme'
import useProgramDoneDialog from 'activity/hooks/useProgramDoneDialog'

export default function ProgramDoneDialog() {
  const {
    programDoneModal,
    programCalendar,
    data,
    rating,
    setRating,
    closeProgramDoneModal,
    saveRatingAsRpe
  } = useProgramDoneDialog()

  const ratingButtonClassName = (index) => {
    if (rating) {
      if (index > rating) {
        return 'notHighlighted'
      }
      return 'highlighted'
    }

    return `circle${5 - index}`
  }

  return (
    <MUIDialog.Stylesheet
      fullScreen
      open={programDoneModal.open}
      onClose={closeProgramDoneModal}
      disableEnforceFocus
    >
      <ProgramDoneDialog.Content>
        <div className="header">
          <h2>Great Job!</h2>
          <p>
            Successfully completed Week {programCalendar.dateSelected.weekCount}
            {', '}
            Day {programCalendar.dateSelected.dayCount} training
          </p>
          <div className="info">
            {data.totalWorkoutTime > 0 && (
              <div className="duration">
                <p>Workout Duration</p>
                <h3>
                  {data.totalWorkoutTime / 60 > 1
                    ? `${Math.floor(data.totalWorkoutTime / 60)} minutes`
                    : `${data.totalWorkoutTime} seconds`}{' '}
                </h3>
              </div>
            )}
          </div>
        </div>

        <div className="rating">
          <h3>How would you rate the intensity of today&apos;s workout?</h3>
          <div className="ratingCircles">
            {[5, 4, 3, 2, 1].map((score, idx) => (
              <button
                key={`${score}-${String(idx)}`}
                type="button"
                className={ratingButtonClassName(5 - idx)}
                onClick={() => setRating(score)}
              >
                {score}
              </button>
            ))}
          </div>
          <div className="labels">
            <span>very easy</span>
            <span>very hard</span>
          </div>
        </div>

        <button
          type="button"
          className={rating ? 'doneBtn' : 'doneBtnDisabled'}
          onClick={saveRatingAsRpe}
          disabled={!rating}
        >
          Done
        </button>
      </ProgramDoneDialog.Content>
    </MUIDialog.Stylesheet>
  )
}

MUIDialog.Stylesheet = styled(MUIDialog)``

ProgramDoneDialog.Content = styled(MUIDialogContent)`
  padding: 3rem !important;
  max-width: 30rem;
  margin: 0 auto;
  .header {
    margin-top: 5rem;
    h2 {
      font-weight: 500;
      font-size: 2rem;
    }
    p {
      font-weight: 600;
      color: #595959;
    }
    .info {
    margin-top: 2rem;
      display: flex;
      justify-content: center;
      p {
        font-weight: 700;
        font-size: 0.75rem;
        color: #595959;
        margin: 0;
      }
      h3 {
        font-weight: 500;
        font-size: 1.25rem;
        color: #000;
      }
    }
  }
  .rating {
    margin-top: 7rem;
    h3 {
      text-align: center;
      color: ${theme.palette.secondary.g800};
      font-size: 1.25rem;
      font-weight: 500;
    }
    .ratingCircles {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-evenly;
      margin-top: 2rem;
      button {
        border-radius: 18.868125rem;
        width: 2rem;
        height: 1.9625rem;
        text-align center;
        cursor: pointer;
        font-weight: 600;
        font-size: 0.75rem;
        background-color: #fff;
      }
      button:hover {
        background-color: ${theme.palette.secondary.g800};
        opacity: 0.4;
        color: #fff;
        border: 0;
        box-shadow: 0 0 1rem 0.2rem ${theme.palette.secondary.g800};
      }
      button:hover ~ button {
        background-color: ${theme.palette.secondary.g800};
        color: #fff;
        border: 0;
      }
      .circle0 {
        border: 2px solid #a4161a;
        color: #a4161a;
      }
      .circle1 {
        border: 2px solid #F4861E;
        color: #F4861E;
      }
      .circle2 {
        border: 2px solid #D1A603;
        color: #D1A603;
      }
      .circle3 {
        border: 2px solid #198F96;
        color: #198F96;
      }
      .circle4 {
        border: 2px solid #09555D;
        color: #09555D;
      }
      .highlighted {
          background-color: ${theme.palette.secondary.g800};
          color: #fff;
          border: 2px solid ${theme.palette.secondary.g800};
      }
      .notHighlighted {
         border: 2px solid ${theme.palette.secondary.g800};
         color: ${theme.palette.secondary.g800};
      }
    }
    .labels {
        color: #09555D;
        padding: 1rem 1.5rem 1rem 1.5rem;
        display: flex;
        justify-content: space-between;
        span {
          font-weight: 500;
        }
    }
  }
  .doneBtn {
    margin-top: 5rem;
    border: 0;
    width: 100%;
    padding: 1rem;
    background-color: ${theme.palette.secondary.g800};
    color: #fff;
    border-radius: 0.625rem;
    font-weight: 700;
  }
  .doneBtnDisabled {
    margin-top: 5rem;
    border: 0;
    width: 100%;
    padding: 1rem;
    background-color: #F8F8F8;
    color: gray;
    border-radius: 0.625rem;
    font-weight: 700;
  }
`

ProgramDoneDialog.Stylesheet = styled.div`
  .MuiToolbar-root {
    justify-content: flex-start;
  }
`
