import React from 'react'
import { createSlice } from '@reduxjs/toolkit'

export const app = createSlice({
  name: 'common',
  initialState: {
    viewDataAs: 'self',
  },
  reducers: {
    changeViewDataAs: (state, action) => {
      state.viewDataAs = action.payload
    },
  },
})

export const LayoutContext = React.createContext()
