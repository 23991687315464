import React, { useContext } from 'react'
import Dialog from 'activity/components/Dialog'
import { ActivityLogContext } from 'activity/ducks'
import Layout from 'common/components/Layout'
import ProgramDialog from 'activity/components/ProgramDialog'
import ProgramCalendar from 'activity/components/ProgramCalendar'
import useActivity from 'activity/hooks/useActivity'
import ProgramCards from 'activity/components/ProgramCards'
import { UserContext } from 'account'
import { useLocation } from 'react-router-dom'

function Activity() {
  const user = useContext(UserContext)
  const location = useLocation()
  const { loading, data, state, dispatch, openProgramDialog, programList } =
    useActivity()

    if (location.pathname === '/' && user.isTrainer) {
        window.location.href = '/trainerDashboard'
    }
  return (
    <ActivityLogContext.Provider value={{ state, dispatch }}>
      <Layout>
        <ProgramCalendar loading={loading} data={data} />
        <ProgramCards programList={programList} onClick={openProgramDialog} />

        <Dialog />
        <ProgramDialog />
      </Layout>
    </ActivityLogContext.Provider>
  )
}

export default Activity
