import React from 'react'
import Layout from 'common/components/Layout'
import OneRepMax from '../components/OneRepMax'

function OneRepMaxTable() {
    return (
        <Layout>
            <OneRepMax />
        </Layout>
    )
}

export default OneRepMaxTable
