/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react'
import {
    Box,
    Button,
    Tab,
    Tabs,
    TextField,
    Chip,
} from '@material-ui/core'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    LineChart,
    Line,
} from 'recharts'
import TabPanel from './TabPanel'
import { useFormik } from 'formik'
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux'
import { GetListProgramAction, GetListProgramWeeksAction, SelectedProgramId, UpdateProgramAction, DeleteProgramAction } from 'Redux/Action/programBuilderAct'
import { set, update } from 'lodash'
import Dialog from '@material-ui/core/Dialog'
import { LayoutContext } from 'common/ducks'
import useApi from 'common/hooks/useApi'

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}
function RightSideBar(props) {
    const dispatch = useDispatch();
    const { setAppLoading } = useContext(LayoutContext)
    const { programs, programID } = useSelector(state => state.rootReducer.programBuilder);
    const { toggleDrawer, setRightSidebar } = props;
    const [rightTabValue, setRightTabValue] = React.useState(0)
    const [programDetail, setProgramDetail] = useState(null)
    const [details, setDetails] = useState()
    const [open, setOpen] = React.useState(false)
    const [deleteOpen, setDeleteOpen] = React.useState(false)
    const [img, setImg] = useState();
    const rightSidebarTabs = ['info', 'Insights']

    const [{ loading: uploadLoading }, upload] = useApi(
        {
          method: 'POST',
          url: 'metrics/program/upload_pic/',
        },
        { manual: true }
    )

    useEffect(() => {
        setAppLoading(uploadLoading)
    }, [uploadLoading, setAppLoading])

    const data = [
        {
            day: '1',
            amt: 850,
            date: "1 may"
        },
        {
            day: '2',
            amt: 900,
        },
        {
            day: '3',
            amt: 1000,
        },
        {
            day: '4',
            amt: 1100,
        },
        {
            day: '5',
            amt: 1200,
        },
        {
            day: '6',
            amt: 1300,
        },
        {
            day: '7',
            amt: 1330,
        },
        {
            day: '8',
            amt: 1330,
        },
        {
            day: '9',
            amt: 1350,
        },
        {
            day: '10',
            amt: 1200,
        },
        {
            day: '11',
            amt: 1250,
        },
        {
            day: '12',
            amt: 1400,
        },
        {
            day: '13',
            amt: 1405,
        },
        {
            day: '14',
            amt: 1415,
        },
        {
            day: '15',
            amt: 1425,
        },
        {
            day: '16',
            amt: 1435,
        },
        {
            day: '17',
            amt: 1470,
        },
        {
            day: '18',
            amt: 1500,
        },
        {
            day: '19',
            amt: 1550,
        },
        {
            day: '20',
            amt: 1580,
        },
        {
            day: '21',
            amt: 1650,
        },
        {
            day: '22',
            amt: 1580,
        },
        {
            day: '23',
            amt: 1800,
        },
        {
            day: '24',
            amt: 1900,
        },
        {
            day: '25',
            amt: 1950,
        },
        {
            day: '26',
            amt: 2000,
        },
        {
            day: '27',
            amt: 1900,
        },
        {
            day: '28',
            amt: 1900,
        }
    ]

    const handleChange = (event) => {
        setProgramDetail(event.target.value);
        programs?.data?.forEach((name) => name.id === event.target.value && setDetails(name))
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
        dispatch(GetListProgramAction())
    };

    const handleProgramOpen = () => {
        dispatch(SelectedProgramId(details?.id))
        setRightSidebar(false)
        dispatch(GetListProgramWeeksAction())
    };

    const handleDelete = () => {
        dispatch(DeleteProgramAction(programID))
        setOpen(false);
        dispatch(GetListProgramAction())
        setDeleteOpen(false)
    }

    useEffect(() => {
        if (programs.data.length > 0) {
            const selected = programs.data.filter((value) => value.id === programID);
            formik.setFieldValue('ProgramName', selected[0]?.name)
            formik.setFieldValue('ProgramDescription', selected[0]?.description)
            setProgramDetail(selected[0])
            setImg(selected[0]?.pictureUrl)
        }
    }, [programID])

    const validationSchema = Yup.object().shape({
        ProgramName: Yup.string().required('Required'),
    })
    const formik = useFormik({
        initialValues: {
            ProgramName: details?.name || '',
            ProgramDescription: details?.description || '',
            Tags: details?.tags || '',
        },
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {
            if(values?.Tags){
                programDetail?.tags.push(values?.Tags)
            }
            let payload = {
                name: values?.ProgramName || '',
                description: values?.ProgramDescription || '',
                tags: programDetail?.tags,
            };
            dispatch(UpdateProgramAction({ updateData: payload, id: programID }))
            alert("Program Details Updated Succesfully!")
            formik.setFieldValue('Tags', '')
        },
        validationSchema: validationSchema
    })

    const handleRightChange = (e, newValue) => {
        setRightTabValue(newValue)
    }

    const deleteTag = (id) => {
        programDetail?.tags?.splice(id,1)
        const payload = {
                name: formik.values.ProgramName,
                description: formik.values.ProgramDescription,
                tags: programDetail?.tags,
            }
        dispatch(UpdateProgramAction({ updateData: payload, id: programID }))
    }

    function onFileChange(event) {
        if(event.target.files[0].size > 25000000) {
           alert("File is too big! Max size is 25 MB");
        }
        const file = event.target.files[0]
        const formData = new FormData()
        formData.append('id', programDetail?.id)
        formData.append('name', programDetail?.name)
        formData.append('file', file)
        upload({
          headers: { 'Content-Type': 'multipart/form-data' },
          data: formData,
        })
      }

    return (
        <Box style={{ width: '400px' }}>
            <Box area-label="thisIsBox" style={{  borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 1, borderColor: '#E5E5E5', borderStyle: "solid", px: 10 }}>
                <button type="button" className="close_icon" onClick={toggleDrawer(false)} style={{ position: 'absolute' , zIndex: 1}}>
                    <ion-icon
                        name="close"
                        role="img"
                        className="md hydrated"
                        aria-label="close"
                        style={{ width: '1.5em' , height: '1.5em', marginTop: '0.5em'}}
                    />
                </button>
                <Tabs
                    value={rightTabValue}
                    onChange={handleRightChange}
                    className="main-tabs"
                    aria-label="sidebar tabs"
                >
                    {rightSidebarTabs.map((item, index) => (
                        <Tab className='right_tabs' key={item} label={item}  style={{ height: '10vh'}} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box><TabPanel value={rightTabValue} index={1}>
                <Box className='chart_box'>
                    <h3>Volume</h3>
                    <LineChart
                        width={352}
                        height={231}
                        data={data}
                        margin={{ top: 5, right: 50, bottom: 5, left: 0 }}
                        title="Volume"
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <Line type="monotone" dataKey="amt" stroke="#0B545E" dot={false} />
                        <XAxis dataKey="day" tickCount={0} />
                        <YAxis dataKey="amt" tickCount={0} />
                    </LineChart>
                </Box>
                <Box className='chart_box'>
                    <h3>Activity</h3>
                    <BarChart
                        width={352}
                        height={231}
                        data={data}
                        margin={{
                            top: 5,
                            right: 20,
                            left: 20,
                            bottom: 5,
                        }}
                        title="Activity"
                    >
                        <XAxis dataKey="day" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="amt" fill="#0B545E " barSize={5} />
                    </BarChart>
                </Box>
            </TabPanel>

            <TabPanel value={rightTabValue} index={0}>
                <Box className='info_tab'>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            name='ProgramName'
                            value={formik.values.ProgramName}
                            onChange={formik.handleChange}
                            className='info_programName'
                            placeholder='Program Name'
                            id="ProgramName"
                            variant="outlined"
                            error={formik.touched.ProgramName && formik.errors.ProgramName}
                            helperText={formik.touched.ProgramName && formik.errors.ProgramName}
                        />
                        <TextField
                            type="text"
                            fullWidth
                            name='ProgramDescription'
                            onChange={formik.handleChange}
                            multiline
                            value={formik.values.ProgramDescription}
                            rows={4}
                            label="Program Description"
                            variant="filled"
                            style={{marginBottom: 30}}
                        />
                        <br />
                            {programDetail?.tags?.map((tag, index) => (
                                <Chip label={tag} onDelete={() => deleteTag(index)}/>
                              ))}
                        <br />
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '15px'}}>
                            <TextField
                                type="text"
                                name="Tags"
                                onChange={formik.handleChange}
                                value={formik.values.Tags}
                                style={{width: '70%', marginBottom: 10}}
                                placeholder="Add tags"
                                label="Tags"
                                variant="filled"
                                error={formik.errors.Tags && formik.touched.Tags}
                                helperText={formik.touched.Tags && formik.errors.Tags}
                            />
                            <Button variant="text" variant="contained" color="primary" type="submit"  style={{zoom: '1.1'}}>
                                Add
                            </Button>
                            </div>
                            Program Image Preview
                            <img src={img} style={{width: 300, margin: "10px 0px"}}/>
                            <br />
                            <label htmlFor="pic-upload" style={{ width: '50%' }}>
                              <input
                                accept=".jpg,.png"
                                type="file"
                                id="pic-upload"
                                style={{ display: 'none' }}
                                onChange={onFileChange}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                component="span"
                                style={{ marginTop: '5%' }}
                              >
                                Upload
                              </Button>
                            </label>
                        <br />
                        <Button variant="contained" style={{ marginTop: "28px", marginRight: '20px' }} color="primary" type="submit" >
                            Update
                        </Button>
                        <Button variant="contained" style={{ marginTop: "28px", backgroundColor: '#FF7262', color: 'white' }} onClick={() => setDeleteOpen(true)} >
                            Delete
                        </Button>
                    </form>
                    <Dialog
                        open={deleteOpen}
                        onClose={() => setDeleteOpen(false)}
                        PaperProps={{
                            style: {
                              width: '40vw'
                            },
                        }}
                    >
                        <Box
                            style={{
                            backgroundColor: "white",
                            boxShadow: 24,
                            padding: '30px',
                            }}
                        >
                            <h2>Delete Program:         {formik.values.ProgramName} ?</h2>
                            <Box style={{ textAlign: 'right', margin: '40px 0 10px 0 ' }}>
                                <Button variant="contained" color="primary" onClick={() => handleDelete()} style={{ marginLeft: "2vw", width: "10vw", border: "2px solid #0B545E" }}>
                                    Yes
                                </Button>
                                <Button variant="text" style={{ color: '#0000008A' }} onClick={() => setDeleteOpen(false)}>
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Dialog>
                </Box>
            </TabPanel>
        </Box>
    )
}

export default RightSideBar
