export default function parseVideoUrl(url) {
  url = url.replace('youtu.be', 'youtube.com')
  if (url.includes('youtube')) {
    url = url
      .replace('watch?v=', 'embed/')
      .replace('shorts', 'embed')

    if (!url.includes('embed')) {
      url = url.replace('youtube.com', 'youtube.com/embed')
    }

    if (!url.includes('autoplay=1')) {
      return `${url}?autoplay=1`
    }
  } else if (url.includes('google')) {
    if (!url.includes('preview')) {
      return `${url}/preview`
    }
    return url
  }

  return url
}
