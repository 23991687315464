import React from 'react'
import Layout from 'common/components/Layout'
import Trainers from '../components/Trainers'

function TrainerDashboard() {
    return (
        <Layout>
            <Trainers />
        </Layout>
    )
}

export default TrainerDashboard
