import React from 'react'
import useApi from 'common/hooks/useApi'

export const UserContext = React.createContext()

export function UserProvider({ children }) {
  const [{ data = {} }] = useApi('users/')

  // register the current session with FullStory
  if (window.FS?.identify && data.id && data.email) {
    window.FS.identify(data.id, {
      email: data.email,
    })
  }

  // trigger sync with fitbit on login
//  useApi({ method: 'POST', url: 'users/sync_fitbit/' })

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>
}
