import { configureStore } from '@reduxjs/toolkit'
import { app } from 'common/ducks'
import createSagaMiddleware from "redux-saga";
import rootReducer from 'Redux/Reducer';
import rootSagas from '../Redux/Sagas/rootSaga'

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    app: app.reducer,
    rootReducer
  },
  middleware: [sagaMiddleware],
  devTools: window.devToolsExtension && window.devToolsExtension(),
}
)
sagaMiddleware.run(rootSagas);
export default store;