import moment from 'moment'

class LocalStorage {
  get(name) {
    try {
      return JSON.parse(window.localStorage.getItem(name)) || {}
    } catch {
      return {}
    }
  }

  set(name, cache) {
    window.localStorage.setItem(name, JSON.stringify(cache))
  }
}

class DailyCache {
  constructor(name) {
    this.name = `sidekick.cache.${name}`
    this.storage = new LocalStorage()

    this.cache = this.storage.get(this.name)

    if (!this.cache.expiresAt || Date.now() > this.cache.expiresAt) {
      this.setExpiry()
    }
  }

  list() {
    return this.cache
  }

  get(key) {
    return this.cache[key]
  }

  set(key, value) {
    this.cache[key] = value

    this.sync()
  }

  setExpiry() {
    const tomorrow = moment().add(1, 'days').startOf('day').valueOf()

    this.cache = {
      expiresAt: tomorrow,
    }

    this.sync()
  }

  sync() {
    this.storage.set(this.name, this.cache)
  }
}

export default DailyCache
