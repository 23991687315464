import { useContext, useEffect } from 'react'
import { LayoutContext } from 'common/ducks'

function useAppTitle(title = '') {
  const { setAppTitle } = useContext(LayoutContext)

  useEffect(() => {
    setAppTitle(title)
  })
}

export default useAppTitle
