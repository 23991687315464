import React, { useState } from "react";
import styled from 'styled-components'
import { Box, Tab, Tabs } from "@material-ui/core";
import TabPanel from "./TabPanel";
import SidebarBlockTab from "./SidebarBlockTab";
import SidebarGroupTab from "./SidebarGroupTab";
import SidebarExerciseTab from "./SidebarExerciseTab";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

const sideMenuLabels = ["Exercises", "blocks", "groups"];

function LeftSideBar({ collapse = true, onCollapse = () => { } }) {
    const [value, setValue] = useState(0);

    const handleChange = (e, newValue) => {
        setValue(newValue);
    };
    function handleCollapse(event) {
        event.preventDefault()
        onCollapse()
    }

    return (
        <LeftSideBar.Styles collapse={collapse}>
            <div
                id="Sidebar"
                className={`modal fade panelbox panelbox-left ${collapse ? '' : 'show'
                    }`}
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="profileBox">
                                {/* <div className="image-wrapper">
                  <img
                    src={user?.avatar}
                    alt="Avatar"
                    className="imaged rounded"s
                  />
                </div> */}
                                {/* <div className="in">
                  <strong>{user?.displayName}</strong>
                </div> */}
                                <button
                                    type="button"
                                    className="btn btn-icon close-sidebar-cross-button"
                                    onClick={handleCollapse}
                                >
                                    <ion-icon
                                        name="close"
                                        role="img"
                                        className="md hydrated"
                                        aria-label="close"
                                    />
                                </button>
                            </div>
                            <Box style={{ width: "100%" }}>
                                <Box style={{ borderTop: 0, borderLeft: 0, borderRight: 0, borderBottom: 1, borderColor: "#E5E5E5", borderStyle: "solid" }}>
                                    <Tabs
                                        value={value}
                                        className="main-tabs"
                                        onChange={handleChange}
                                        style={{
                                            height: "10vh",
                                        }}
                                        aria-label="sidebar tabs"
                                    >
                                        {sideMenuLabels.map((item, index) => (
                                            <Tab style={{
                                                height: "10vh",
                                                padding: "12px 24px",
                                            }} key={item} label={item} {...a11yProps(index)} />
                                        ))}
                                    </Tabs>
                                </Box>
                                <TabPanel value={value} index={0}>
                                    <SidebarExerciseTab />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <SidebarBlockTab />
                                </TabPanel>
                                <TabPanel value={value} index={2}>
                                    <SidebarGroupTab />
                                </TabPanel>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade ${collapse ? '' : 'show'}`} />
        </LeftSideBar.Styles>
    );
};
LeftSideBar.Styles = styled.div`
  #Sidebar {
    display: block;
    width: ${({ collapse }) => (collapse ? 0 : '100%')};
  }

  .listview {
    position: relative;
    top: 74px;
  }

  .modal-backdrop {
    display: ${({ collapse }) => (collapse ? 'none' : 'block')};
  }

  .profileBox > .in > strong {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }

  #Sidebar .modal-body{
    padding-bottom: 0px !important
  }
`
export default LeftSideBar;
