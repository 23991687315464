import { Box, styled } from "@material-ui/core";


export const DashboardCardsWrapper = styled(Box)(({ theme }) => ({
  margin: '20px 40px',
  paddingBottom: '15px',
  display: 'flex',
  width: 'auto',
  minHeight: '50vh',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    width: 0,
    height: '2px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '10px',
  },
  '& > div': {
    margin: 0,
    marginRight: '25px',
    width: '300px',
  },

  '& > div:nth-last-child(1)': {
    margin: 0,
    background: '#eee',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '.3s',
    '&:hover': {
      background: '#ccc',
      cursor: 'pointer',
      transition: '.3s',
    },
  },
}));
