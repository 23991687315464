/* eslint-disable */
import {
    ADD_ACTIVTY_GROUP_ACTIVITTIES,
    ADD_BLOCK_DATA,
    ADD_GROUP_DATA,
    ADD_WEEK_DATA_SUCCESS,
    CLEAR_EDIT_BLOCK_EXCERSIZE_DATA,
    CLEAR_PROGRAM_EXERCISE_REQUEST,
    CLEAR_WEEK_REQUEST,
    COMMON_ACTIVITY_DETAIL,
    CREATE_BLANK_WEEKS_REQUEST,
    CREATE_BLOCKS_REQUEST,
    CREATE_EXERCISE_REQUEST,
    CREATE_GROUPS_REQUEST,
    CREATE_PROGRAM_DAYS_REQUEST,
    CREATE_PROGRAM_EXERCISE_REQUEST,
    CREATE_PROGRAM_REQUEST,
    CREATE_PROGRAM_WEEKS_REQUEST,
    DELETE_ACTIVITY_DETAILS,
    DELETE_ACTIVITY_GROUP,
    DELETE_BLOCKS_REQUEST,
    DELETE_BLOCK_DATA,
    DELETE_COMMON_DETAILS,
    DELETE_EDITED_COMMON_DETAILS,
    DELETE_EDIT_WEEK_DATA,
    DELETE_EXCERSIZE_DATA,
    DELETE_EXCERSIZE_DATA_REQUEST,
    DELETE_GROUPS_REQUEST,
    DELETE_GROUP_DATA,
    DELETE_PROGRAM_DAYS_REQUEST,
    DELETE_PROGRAM_EXERCISE_REQUEST,
    DELETE_PROGRAM_REQUEST,
    DELETE_PROGRAM_WEEKS_REQUEST,
    EDIT_BLOCK_DATA,
    EDIT_BLOCK_EXCERSIZE_DATA,
    EDIT_EXCERSIZE_DATA,
    EDIT_GROUP_DATA,
    EDIT_UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS,
    EDIT_WEEK_DATA, FETCH_BLOCK_DATA,
    FETCH_EDIT_BLOCK_DATA,
    FETCH_EXCERSIZE_DATA,
    FETCH_GROUP_DATA,
    FETCH_WEEK_GROUP_DATA, GET_BLOCK_REQUEST, GET_EXERCISE_REQUEST, GET_GROUPS_REQUEST, GET_LIST_BLOCKS_REQUEST, GET_LIST_EXERCISE_REQUEST, GET_LIST_GROUPS_REQUEST, GET_LIST_PROGRAM_DAYS_REQUEST, GET_LIST_PROGRAM_EXERCISE_REQUEST, GET_LIST_PROGRAM_REQUEST, GET_LIST_PROGRAM_WEEKS_REQUEST, GET_PROGRAM_DAYS_REQUEST, GET_PROGRAM_EXERCISE_REQUEST, GET_PROGRAM_WEEKS_REQUEST, POST_ACTIVITY_DETAIL,
    POST_ACTIVITY_GROUP_DETAIL,
    RESET_ACTVITY_DETAILS_AND_ACTIVITY_GROUPS,
    SELECTED_PROGRAM_ID,
    SELECTED_WEEK_LIST,
    UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS,
    UPDATE_ACTIVTITY_DETAILS,
    UPDATE_BLOCKS_REQUEST,
    UPDATE_EXCERSIZE_DATA_REQUEST,
    UPDATE_GROUPS_REQUEST,
    UPDATE_PROGRAM_DAYS_REQUEST,
    UPDATE_PROGRAM_EXERCISE_REQUEST,
    UPDATE_PROGRAM_REQUEST,
    UPDATE_PROGRAM_WEEKS_REQUEST,
    WEEK_BLOCK_MODAL_DATA,
    WEEK_EDIT_BLOCK_DATA,
    WEEK_EDIT_EXCERCIZE,
    WEEK_EDIT_UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS
} from "./constant"

export function activityDetailData(data) {
    return { type: POST_ACTIVITY_DETAIL, payload: data }
}
export function activityGroupDetailData(data) {
    return { type: POST_ACTIVITY_GROUP_DETAIL, payload: data }
}
export function activityUpdateDetailData(data) {
    return { type: UPDATE_ACTIVTITY_DETAILS, payload: data }
}
export function deleteActivity(data) {
    return { type: DELETE_ACTIVITY_DETAILS, payload: data }
}
export function deleteActivityGroup(index) {
    return { type: DELETE_ACTIVITY_GROUP, payload: index }
}
export function resetActivityAndGroup() {
    return { type: RESET_ACTVITY_DETAILS_AND_ACTIVITY_GROUPS }
}
export function activityGroupUpdateDetailActivityData(data) {
    return { type: UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS, payload: data }
}
export function editActivityGroupUpdateDetailActivityData(data) {
    return { type: EDIT_UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS, payload: data }
}
export function weekEditActivityGroupUpdateDetailActivityData(data) {
    return { type: WEEK_EDIT_UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS, payload: data }
}
export function activityGroupAddActivity(data) {
    return { type: ADD_ACTIVTY_GROUP_ACTIVITTIES, payload: data }
}
export function commonActivityData(data) {
    return { type: COMMON_ACTIVITY_DETAIL, payload: data }
}
export function deleteCommonData(data) {
    return { type: DELETE_COMMON_DETAILS, payload: data }
}
export function deleteEditedCommonData(data) {
    return { type: DELETE_EDITED_COMMON_DETAILS, payload: data }
}
export function FetchExcersizedata(data) {
    return { type: FETCH_EXCERSIZE_DATA, payload: data }
}
export function EditExcersizedata(data) {
    return { type: EDIT_EXCERSIZE_DATA, payload: data }
}
export function deleteExcersize(data) {
    return { type: DELETE_EXCERSIZE_DATA, payload: data }
}
export function FetchBlockData() {
    return { type: FETCH_BLOCK_DATA }
}
export function FetchEditBlockData() {
    return { type: FETCH_EDIT_BLOCK_DATA }
}
export function AddBlockData(data) {
    return { type: ADD_BLOCK_DATA, payload: data }
}
export function EditBlockData(data) {
    return { type: EDIT_BLOCK_DATA, payload: data }
}
export function weekEditBlockData(data) {
    return { type: WEEK_EDIT_BLOCK_DATA, payload: data }
}
export function deleteBlockData(data) {
    return { type: DELETE_BLOCK_DATA, payload: data }
}
export function FetchGroupData() {
    return { type: FETCH_GROUP_DATA }
}
export function AddGroupData(data) {
    return { type: ADD_GROUP_DATA, payload: data }
}
export function EditGroupData(data, arr) {
    return { type: EDIT_GROUP_DATA, payload: data, payloadArr: arr }
}
export function deleteGroupData(data) {
    return { type: DELETE_GROUP_DATA, payload: data }
}
export function FetchWeekGroupData(data) {
    return { type: FETCH_WEEK_GROUP_DATA, payload: data }
}

export function removeDayFromWeek(data) {
    return { type: FETCH_WEEK_GROUP_DATA, payload: data }
}

export function handleEditData(data) {
    return { type: EDIT_WEEK_DATA, payload: data }
}

export function editBlockExerciseDetails(data) {
    return { type: EDIT_BLOCK_EXCERSIZE_DATA, payload: data }
}

export function clearEditBlockExerciseDetails() {
    return { type: CLEAR_EDIT_BLOCK_EXCERSIZE_DATA }
}

export function weekBlockModalData(data) {
    return { type: WEEK_BLOCK_MODAL_DATA, payload: data }
}

export function CreateExerciseAction(data) {
    return { type: CREATE_EXERCISE_REQUEST, payload: data }
}

export function weekEditExcercize(data) {
    return { type: WEEK_EDIT_EXCERCIZE, payload: data }
}

export function weekDeleteEditedCommonData(data) {
    return { type: DELETE_EDIT_WEEK_DATA, payload: data }
}

export function addWeekData(data) {
    return { type: ADD_WEEK_DATA_SUCCESS, payload: data }
}

// <-------------------------------EXERCISE--------------------------------------->

export function GetListExerciseAction() {
    return { type: GET_LIST_EXERCISE_REQUEST }
}

export function GetExerciseAction(data) {
    return { type: GET_EXERCISE_REQUEST, payload: data }
}

export function UpdateExerciseAction(data) {
    return { type: UPDATE_EXCERSIZE_DATA_REQUEST, payload: data }
}

export function DeleteExerciseAction(data) {
    return { type: DELETE_EXCERSIZE_DATA_REQUEST, payload: data }
}

// <----------------------------PROGRAM EXERCISE---------------------------------->

export function GetListProgramExerciseAction() {
    return { type: GET_LIST_PROGRAM_EXERCISE_REQUEST }
}

export function GetProgramExerciseAction(data) {
    return { type: GET_PROGRAM_EXERCISE_REQUEST, payload: data }
}

export function CreateProgramExerciseAction(data) {
    return { type: CREATE_PROGRAM_EXERCISE_REQUEST, payload: data }
}

export function UpdateProgramExerciseAction(data) {
    return { type: UPDATE_PROGRAM_EXERCISE_REQUEST, payload: data }
}

export function DeleteProgramExerciseAction(data) {
    return { type: DELETE_PROGRAM_EXERCISE_REQUEST, payload: data }
}

// <----------------------------BLOCKS---------------------------------->

export function GetListBlocksAction() {
    return { type: GET_LIST_BLOCKS_REQUEST }
}

export function GetBlocksAction(data) {
    return { type: GET_BLOCK_REQUEST, payload: data }
}

export function CreateBlocksAction(data) {
    return { type: CREATE_BLOCKS_REQUEST, payload: data }
}

export function UpdateBlocksAction(data) {
    return { type: UPDATE_BLOCKS_REQUEST, payload: data }
}

export function DeleteBlocksAction(data) {
    return { type: DELETE_BLOCKS_REQUEST, payload: data }
}

export function ClearSingleBlockProgramExercise() {
    return { type: CLEAR_PROGRAM_EXERCISE_REQUEST }
}

// <----------------------------GROUPS---------------------------------->

export function GetListGroupsAction() {
    return { type: GET_LIST_GROUPS_REQUEST }
}

export function GetGroupsAction(data) {
    return { type: GET_GROUPS_REQUEST, payload: data }
}

export function CreateGroupsAction(data) {
    return { type: CREATE_GROUPS_REQUEST, payload: data }
}

export function UpdateGroupsAction(data) {
    return { type: UPDATE_GROUPS_REQUEST, payload: data }
}

export function DeleteGroupsAction(data) {
    return { type: DELETE_GROUPS_REQUEST, payload: data }
}

// <---------------------------PROGRAMS----------------------------------->

export function GetListProgramAction() {
    return { type: GET_LIST_PROGRAM_REQUEST }
}

export function CreateProgramAction(data) {
    return { type: CREATE_PROGRAM_REQUEST, payload: data }
}

export function UpdateProgramAction(data) {
    return { type: UPDATE_PROGRAM_REQUEST, payload: data }
}

export function DeleteProgramAction(data) {
    return { type: DELETE_PROGRAM_REQUEST, payload: data }
}

// <-----------------------PROGRAM DAYS----------------------------------->

export function GetListProgramDaysAction() {
    return { type: GET_LIST_PROGRAM_DAYS_REQUEST }
}

export function GetProgramDaysAction(data) {
    return { type: GET_PROGRAM_DAYS_REQUEST, payload: data }
}

export function CreateProgramDaysAction(data) {
    return { type: CREATE_PROGRAM_DAYS_REQUEST, payload: data }
}

export function UpdateProgramDaysAction(data) {
    return { type: UPDATE_PROGRAM_DAYS_REQUEST, payload: data }
}

export function DeleteProgramDaysAction(data) {
    return { type: DELETE_PROGRAM_DAYS_REQUEST, payload: data }
}

// <-----------------------PROGRAM WEEKS----------------------------------->

export function GetListProgramWeeksAction() {
    return { type: GET_LIST_PROGRAM_WEEKS_REQUEST }
}

export function GetProgramWeeksAction(data) {
    return { type: GET_PROGRAM_WEEKS_REQUEST, payload: data }
}

export function CreateProgramWeeksAction(data) {
    return { type: CREATE_PROGRAM_WEEKS_REQUEST, payload: data }
}

export function CreateBlankProgramWeeksAction(data) {
    return { type: CREATE_BLANK_WEEKS_REQUEST, payload: data }
}

export function UpdateProgramWeeksAction(data) {
    return { type: UPDATE_PROGRAM_WEEKS_REQUEST, payload: data }
}

export function DeleteProgramWeeksAction(data) {
    return { type: DELETE_PROGRAM_WEEKS_REQUEST, payload: data }
}

export function SelectedProgramId(data) {
    return { type: SELECTED_PROGRAM_ID, payload: data }
}

export function ClearWeeks() {
    return { type: CLEAR_WEEK_REQUEST }
}

export function SelectedWeeksList(data) {
    return { type: SELECTED_WEEK_LIST, payload: data  }
}
