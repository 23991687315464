/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import SearchIcon from '@material-ui/icons/Search';
import { useFormik } from "formik";
import { Box, Divider, Modal, TextField, Button, FormHelperText, CircularProgress } from "@material-ui/core";
import TablePagination from '@material-ui/core/TablePagination';
import CustomCheckbox from "./CustomCheckbox";
import CustomMultiSelect from "./CustomMultiSelect";
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Close'
import { AddGroupData, CreateGroupsAction, DeleteGroupsAction, EditGroupData, GetListGroupsAction, UpdateGroupsAction, GetListBlocksAction } from "Redux/Action/programBuilderAct";
import { useSelector, useDispatch } from "react-redux";
import { ContactSupportOutlined } from "@material-ui/icons";
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useApi from 'common/hooks/useApi'
import { LayoutContext, app } from 'common/ducks'
import styled from 'styled-components'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import Switch from '@mui/material/Switch'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

function SidePanelCheckBox({ label }) {
    return <CustomCheckbox
        textStyle={{
            py: 1.5,
            px: 3,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
        label={label}
    />
};

const validationGroupSchema = Yup.object().shape({
    Name: Yup.string()
        .required('Required'),
    Block: Yup.array().notRequired(),
    Description: Yup.string()
        .notRequired(),
});

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function SidebarGroupTab({ openModal }) {
    const dispatch = useDispatch();
    const { groupInfo, blockInfo } = useSelector(state => state.rootReducer.programBuilder);
    const [open, setOpen] = React.useState(false);
    const [editdata, setEditdata] = React.useState({});
    const [deleteIdList, setDeleteIdList] = useState(null)
    const [deleteBlockIdList, setDeleteBlockIdList] = useState(null)
    const [editGroupDataList, setEditGroupDataList] = useState([])
    const [searchInput, setSearchInput] = useState("")
    const { setAppLoading, setDrawerOptions } = useContext(LayoutContext)
    const [addedBlock, setAddedBlock] = useState([])
    const [groupCreated, setGroupCreated] = useState(false)
    const [expanded, setExpanded] = React.useState(false)

    let list = []

    if (searchInput.length > 0) {
        list = groupInfo.data?.filter((group) => {
            RegExp.quote = function(str) {
                 return str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
            }
            if (group?.name.match(new RegExp(RegExp.quote(searchInput), "i"))){
                return group?.name.match(new RegExp(RegExp.quote(searchInput), "i"))
            }
            if (group?.secondaryName?.match(new RegExp(RegExp.quote(searchInput), "i"))){
                return group?.secondaryName?.match(new RegExp(RegExp.quote(searchInput), "i"))
            }
        })
    } else {
        list = groupInfo.data
    }

    const [{ loading: copyLoading }, copyGroup] = useApi(
            {
              method: 'POST',
              url: 'builder/groups/copy_group/',
            },
            { manual: true }
          )

    useEffect(() => {
      setAppLoading(copyLoading)
    }, [setAppLoading, copyLoading])


    const handleOpen = () => {
        setEditdata({})
        setOpen(true);
    };

    const handleClose = () => {
        formik.resetForm();
        setOpen(false);
        dispatch(GetListGroupsAction())
        setEditGroupDataList([])
        setAddedBlock({})
    };

    const handleSearch = (e) => {
      e.preventDefault();
      setSearchInput(e.target.value);
    }
    const handleClearSearchInput = () => {
      setSearchInput('');
    }

    const handleChange = () => {
        setExpanded(!expanded)
    }

    useEffect(() => {
        dispatch(GetListGroupsAction())
        if (blockInfo.data.length == 0) {
            dispatch(GetListBlocksAction())
        }
    }, [])

    const handleDeleteGroupdata = (id) => {
        setDeleteIdList(id)
        dispatch(DeleteGroupsAction(id))
    }

    const formik = useFormik({
        initialValues: {
            Name: "",
            SecondaryName: "",
            Block: [],
            Description: "",
            Archived: false,

        },
        onSubmit: values => {
            let tempGroup = [...groupInfo.data];
            let tempGroupEdit;
            let exercise_id = values.Block.map((item, index) => ({
            'block': Number(item.id),
            'block_pk': Number(item.id),
            'position': Number(index+1),
            'archived': item.archived,
            }));
            let payload = {
                blocks: exercise_id,
                description: values.Description,
                name: values.Name,
                secondary_name: values.SecondaryName,
                archived: values.Archived,
            }
            if (Object.keys(editdata)?.length > 0) {
                dispatch(UpdateGroupsAction({ updateData: payload, id: editdata.id }))
                setTimeout(() => {
                    dispatch(GetListGroupsAction())
                }, 300)
            } else {
                dispatch(CreateGroupsAction(payload))
                setTimeout(() => {
                    dispatch(GetListGroupsAction())
                }, 300)
            }
            setOpen(false)
            formik.resetForm()
            setEditGroupDataList([])
        },
        validationSchema: validationGroupSchema
    })

    const editModalFun = (data) => {

        setEditdata(data);
        setOpen(true);
        formik.setFieldValue("Name", data?.name)
        formik.setFieldValue("SecondaryName", data?.secondaryName)
        let tempBlock = []
        data.blocks.sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
        })
        data.blocks.map((id)=>id.block.id && tempBlock.push(id.block))
        setEditGroupDataList( tempBlock)
        formik.setFieldValue("Block", tempBlock)
        formik.setFieldValue("Description", data?.description)
        formik.setFieldValue("Archived", data?.archived)
    }

    async function handleCopy() {
        formik.handleSubmit()
        const body = {id: editdata.id, source: 'sidebar'}
        await copyGroup({
            data: body,
        })
        formik.resetForm()
        setOpen(false)
        setEditdata([])
        dispatch(GetListGroupsAction())
    }

    const handleAdd = () => {
        formik.setFieldValue("Block", [...formik.values.Block, addedBlock])
        setEditGroupDataList([...editGroupDataList, addedBlock])
        let tempGroup = [...editGroupDataList, addedBlock]
        let blockList = tempGroup.map((item, index) => ({
            'block': Number(item.id),
            'block_pk': Number(item.id),
            'position': Number(index+1)
        }))
        let payload = {
            blocks: blockList,
            description: formik.values.Description,
            SecondaryName: formik.values.SecondaryName,
            name: formik.values.Name,
            archived: formik.values.Archived,
        }
        if (editdata?.id) {
            dispatch(UpdateGroupsAction({ updateData: payload, id: editdata.id }))
//                dispatch(GetListGroupsAction())
        } else {
            dispatch(CreateGroupsAction(payload))
            setGroupCreated(true)
        }
        setTimeout(() => {
                dispatch(GetListGroupsAction())
        }, 300)
    }

    const handleDeleteBlock= (id) => {
        setDeleteBlockIdList(id)
        const deletedBlockId = editGroupDataList?.findIndex((s) => s.id === id)
        const editedGroupDataList = editGroupDataList.splice(deletedBlockId, 1);
        let blockList = editGroupDataList.map((item, index) => ({
            'block': Number(item.id),
            'block_pk': Number(item.id),
            'position': Number(index+1),
            'archived': item.archived,
        }))
        let payload = {
            blocks: blockList,
            description: formik.values.Description,
            name: formik.values.Name,
            secondary_name: formik.values.SecondaryName,
            archived: formik.values.Archived,
        }
        dispatch(UpdateGroupsAction({ updateData: payload, id: editdata.id }))
        setDeleteBlockIdList()
    }

    const handleDragEnd = (result) => {
        const tempValue = editGroupDataList[result.source.index]
        editGroupDataList.splice(result.source.index, 1)
        editGroupDataList.splice(result.destination.index, 0, tempValue)
        let blockList = editGroupDataList.map((item, index) => ({
            'block': Number(item.id),
            'block_pk': Number(item.id),
            'position': Number(index+1)
        }))
        let payload = {
            blocks: blockList,
            description: formik.values.Description,
            name: formik.values.Name,
            secondary_name: formik.values.SecondaryName,
            archived: formik.values.Archived,
        }
        dispatch(UpdateGroupsAction({ updateData: payload, id: editdata.id }))
    }

    useEffect(() => {
        if (groupCreated) {
            if(groupInfo.data.length > 0) {
                setGroupCreated(false)
                setTimeout(() => {
                const list = groupInfo?.data.map((item) => Number(item?.id))
                const newId = Math.max(...list)
                let findObj =  groupInfo?.data.find(item => item?.id === newId)
                setEditdata(findObj)
                setEditGroupDataList([{id: findObj.blocks[0]?.block?.id, label: findObj?.blocks[0]?.block?.name, name: findObj?.blocks[0]?.block?.name}])
                }, 500)
            }
        }
    }, [groupInfo?.data])

    return (
    <SidebarGroupTab.Stylesheet>
        <div>
            <Box className="sidebar-search" style={{padding: '0px'}}>
                    <TextField
                        fullWidth
                        onChange={handleSearch}
                        value={searchInput}
                        style={{
                            backgroundColor: "#0000000A",
                            color: "#0000003D",
                        }}
                        placeholder="search"
                        InputProps={{
                            startAdornment: <SearchIcon style={{
                                paddingRight: 1,
                                fill: "#000000",
                                opacity: 0.2,
                                height: '2em',
                                marginRight: '10px' }} />,
                            disableUnderline: true,
                            endAdornment: <DeleteIcon style={{ color: "teal" }} onClick={handleClearSearchInput} />,
                        }}
                    />
            </Box>
            <Divider />
            <Box style={{maxHeight: '65vh', overflow: 'auto'}}>
                {groupInfo?.loading ?
                    (
                        <Box style={{ display: "flex", padding: "100px", alignItems: "center", justifyContent: "center" }}>
                            <CircularProgress />
                        </Box>
                    ) :
                    (
                        <>
                            {
                                list?.filter(option => !option?.archived).map((item) => (
                                    <>
                                        <div
                                            key={item?.id}
                                            label={item?.name}
                                            value={item?.name}
                                            style={{
                                                py: 1.5,
                                                px: 3,
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "space-between",
                                                paddingLeft: "24px"
                                            }}
                                        >
                                            <div style={{ cursor: "pointer" }} onClick={() => editModalFun(item)}>
                                                {item?.name}
                                            </div>
                                            {(deleteIdList == item?.id) ? <CircularProgress
                                                size={30}
                                            /> :
                                                (<button onClick={() => handleDeleteGroupdata(item?.id)} className="btn btn-default">
                                                    <DeleteIcon style={{ color: "teal" }} />
                                                </button>)
                                            }
                                        </div>
                                    </>
                                ))
                            }
                        </>
                    )}
            </Box>
            <Box className="sidebar-bottom">
                <Accordion expanded={expanded} onChange={handleChange}>
                    <AccordionSummary
                      expandIcon={<ExpandLessIcon style={{color: 'white'}} />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{justifyContent: 'center', backgroundColor: 'teal'}}
                    >
                      <h3 style={{color: 'white'}}> ARCHIVE </h3>
                    </AccordionSummary>
                    <AccordionDetails style={{maxHeight: '75vh', padding: '0px', overflow: 'scroll'}}>
                        {
                            list?.filter(option => option?.archived).map((item) => (
                                <>
                                    <div
                                        key={item?.id}
                                        label={item?.name}
                                        value={item?.name}
                                        style={{
                                            py: 1.5,
                                            px: 3,
                                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            paddingLeft: "24px",
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        <div style={{ cursor: "pointer" }} onClick={() => editModalFun(item)}>
                                            {item?.name}
                                        </div>
                                        {(deleteIdList == item?.id) ? <CircularProgress
                                            size={30}
                                        /> :
                                            (<button onClick={() => handleDeleteGroupdata(item?.id)} className="btn btn-default">
                                                <DeleteIcon style={{ color: "teal" }} />
                                            </button>)
                                        }
                                    </div>
                                </>
                            ))
                        }
                    </AccordionDetails>
                </Accordion>

                <Box>
                    <Box
                        style={{
                            textAlign: "center",
                            padding: "14px 0",
                            lineHeight: "normal",
                        }}
                    >
                        <Button className="text-primary" onClick={handleOpen}>+ CREATE</Button>
                    </Box>
                    <Modal
                        open={openModal ? openModal : open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        disableEnforceFocus
                    >
                        <Box
                            style={{
                                backgroundColor: "white",
                                border: "2px solid #000",
                                boxShadow: 24,
                                padding: '30px',
                                minWidth: '60vw',
                                maxHeight: '90%',

                            }}
                        >
                            {
                                Object.keys(editdata)?.length > 0 ? <h3>Edit Group</h3> : <h3>Create Group</h3>
                            }
                            <form onSubmit={formik.handleSubmit}>
                                <TextField
                                    fullWidth
                                    name="Name"
                                    onChange={formik.handleChange}
                                    value={formik.values.Name}
                                    style={{ marginTop: "10px" }}
                                    type="text"
                                    label="Name"
                                    variant="filled"
                                    error={formik.touched.Name && formik.errors.Name}
                                    helperText={formik.touched.Name && formik.errors.Name}
                                />
                                <TextField
                                    fullWidth
                                    name="SecondaryName"
                                    onChange={formik.handleChange}
                                    value={formik.values.SecondaryName}
                                    style={{ marginTop: "10px" }}
                                    type="text"
                                    label="Secondary Name"
                                    variant="filled"
                                    error={formik.touched.SecondaryName && formik.errors.SecondaryName}
                                    helperText={formik.touched.SecondaryName && formik.errors.SecondaryName}
                                />
                                <br />

                                {/*BLOCK LIST*/}
                                <Box style={{padding: '20px 0px', minHeight: '200px', maxHeight: '500px', overflow: 'scroll'}}>
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                        <Droppable droppableId='blockList'>
                                            {(provided) => (
                                                <Box className='blockList' {...provided.droppableProps} ref={provided.innerRef}>
                                                    {editGroupDataList?.map((row, index) => (
                                                        <Draggable key={`${row?.id}-${index}`} draggableId={`${row?.id}`} index={index}>
                                                            {(provided) => (
                                                                <Box {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} >
                                                                    <Box className="card" style={{
                                                                    minHeight: '50px',
                                                                    margin: '5px 0px',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    paddingLeft: '30px',
                                                                    flexDirection: 'row'
                                                                    }}>
                                                                        <h3 style={{margin: '0px', color: '#43818b'}}>{row?.name}</h3>
                                                                        <Button onClick={() => handleDeleteBlock(row?.id)}>
                                                                            <DeleteIcon style={{ color: "#43818b" }} />
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                                )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                </Box>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </Box>
                                <div style={{padding: '10px 0px'}}>
                                    <Autocomplete
                                      disablePortal
                                      options={blockInfo.data.filter(option => !option.archived).map((option) => ({label: option?.name, id: option?.id, name: option?.name}))}
                                      getOptionLabel={(option) => option?.label || ''}
                                      style={{ width: '85%', marginRight: '30px', display: 'inline-block',  }}
                                      renderInput={(params) => <TextField {...params}  label="Select block to add" variant='filled' />}
                                      onChange={(e, value) => setAddedBlock(value)}
                                    />
                                    <Button variant="contained" color="primary" style={{height: '55px', marginTop: '3%'}} onClick={handleAdd}>
                                            Add
                                    </Button>
                                </div>
                                <TextField
                                    type="text"
                                    name="Description"
                                    fullWidth
                                    onChange={formik.handleChange}
                                    value={formik.values.Description}
                                    multiline
                                    rows={1}
                                    label="Description"
                                    variant="filled"
                                    error={formik.errors.Description && formik.touched.Description}
                                    helperText={formik.touched.Description && formik.errors.Description}
                                />
                                <br />
                                <Box style={{ textAlign: 'right', margin: '10px 0', display: 'flex', justifyContent: 'flex-end' }}>
                                    <FormGroup  style={{margin: '0px 20px'}}>
                                        <FormControlLabel control={<Switch checked={formik.values.Archived} name='Archived' value={formik.values.Archived} onChange={formik.handleChange} style={{color: 'teal'}}/>} label="Archive" labelPlacement="start" />
                                    </FormGroup>
                                    <Button variant="outlined" style={{margin: '0px 20px'}} onClick={handleCopy}>
                                        Copy
                                    </Button>
                                    <Button variant="contained" style={{margin: '0px 20px'}} color="primary" type="submit" onSubmit={formik.handleSubmit}>
                                        Save
                                    </Button>
                                    <Button variant="outlined" style={{ marginLeft: '20px',  color: '#0000008A' }} onClick={handleClose}>
                                        Cancel
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Modal>
                </Box>
            </Box>
        </div>
    </SidebarGroupTab.Stylesheet>
    )
}

SidebarGroupTab.Stylesheet = styled.div`
    .MuiInputBase-input{
        font-size: 30px
    }
`

export default SidebarGroupTab
