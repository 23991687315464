import { useContext, useState } from 'react'
import { useStopwatch } from 'react-timer-hook'
import {
  ActivityLogContext,
  closeStartWorkoutDialogAction,
  openEndWorkoutDialogAction,
  openLogDrawerAction,
  openProgramDoneModalAction,
  openStartWorkoutDialogAction,
  updateTotalWorkoutTimeAction
} from 'activity/ducks'

export default function useStartWorkoutDialog() {
  const { state, dispatch } = useContext(ActivityLogContext)

  const [timerStarted, setTimerStarted] = useState(false)
  const [timerPaused, setTimerPaused] = useState(false)
  const [exerciseNumber, setExerciseNumber] = useState(0)

  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    pause,
    reset
  } = useStopwatch({ autoStart: false })

  const closeStartWorkoutDialog = () => {
    dispatch(closeStartWorkoutDialogAction())
  }

  const openStartWorkoutDialog = () => {
    dispatch(openStartWorkoutDialogAction())
  }

  const startTimer = () => {
    setTimerStarted(true)
    reset(null, false)
    start()
  }

  const stopAndResetTimer = () => {
    setTimerStarted(false)
    reset(null, false)
  }

  const closeDialogOrGoToPreviousExercise = () => {
    if (exerciseNumber > 0) {
      setExerciseNumber(exerciseNumber - 1)
    } else {
      dispatch(openEndWorkoutDialogAction())
    }

    stopAndResetTimer()
  }

  const skipCurrentExercise = () => {
    setExerciseNumber(exerciseNumber + 1)
    stopAndResetTimer()
  }

  const { startWorkoutDialog, data } = state.programDialog

  const currentExercise = data?.exercises?.[exerciseNumber]

  const markCurrentExerciseAsDone = () => {
    pause()
    dispatch(
      openLogDrawerAction({
        ...currentExercise,
        measuredWorkTime: totalSeconds,
        isLastExercise: exerciseNumber === data.exercises.length - 1
      })
    )

    const totalWorkoutTime =
      (state.programDialog.data.totalWorkoutTime ?? 0) + totalSeconds
    dispatch(updateTotalWorkoutTimeAction(totalWorkoutTime))
  }

  return {
    state,
    dispatch,
    closeStartWorkoutDialog,
    openStartWorkoutDialog,
    startWorkoutDialog,
    timerStarted,
    setTimerStarted,
    data,
    timerPaused,
    setTimerPaused,
    totalSeconds,
    seconds,
    minutes,
    hours,
    isRunning,
    start,
    pause,
    reset,
    startTimer,
    exerciseNumber,
    setExerciseNumber,
    closeDialogOrGoToPreviousExercise,
    skipCurrentExercise,
    currentExercise,
    markCurrentExerciseAsDone
  }
}
