import { ActivityLogContext, closeLogDrawerAction } from 'activity/ducks'
import { useContext, useEffect, useState } from 'react'

export default function useLogDrawer() {
  const [width, setWidth] = useState(
    window.innerWidth < 765 ? window.innerWidth : 390
  )
  const { state, dispatch } = useContext(ActivityLogContext)

  const updateWidth = () => {
    if (window.innerWidth < 765) {
      setWidth(window.innerWidth)
    } else {
      setWidth(390)
    }
  }

  const { logDrawer } = state.programDialog

  const closeLogDrawer = () => {
    dispatch(closeLogDrawerAction())
  }

  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return {
    logDrawer,
    width,
    closeLogDrawer,
    state
  }
}
