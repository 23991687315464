import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from 'common/env'

function AuthProvider(props) {
  const { children } = props
  const history = useHistory()

  function handleRedirectCallback(appState) {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      audience={AUTH0_AUDIENCE}
      clientId={AUTH0_CLIENT_ID}
      domain={AUTH0_DOMAIN}
      onRedirectCallback={handleRedirectCallback}
      redirectUri={`${window.location.origin}/callback`}
    >
      {children}
    </Auth0Provider>
  )
}

export default AuthProvider
