import React from 'react'
import Layout from 'common/components/Layout'
import Athletes from '../components/Athletes'

function AthletesDashboard() {
    return (
        <Layout>
            <Athletes />
        </Layout>
    )
}

export default AthletesDashboard