import React from 'react'
import Layout from 'common/components/Layout'
import ReportsChart from 'reports/components/ReportsChart'

function Reports() {
  return (
    <Layout>
      <ReportsChart />
    </Layout>
  )
}

export default Reports
