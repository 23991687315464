import React from 'react'

function NoData({ title = 'No Data', description = '' }) {
  return (
    <div className="error-page">
      <div className="icon-box">
        <ion-icon
          name="cloud-offline-outline"
          role="img"
          className="md hydrated"
        />
      </div>

      <h1 className="title">{title}</h1>
      <div className="text mb-5">{description}</div>
    </div>
  )
}

export default NoData
