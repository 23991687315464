import exerciseSvg from '../assets/icons/exercise.svg'
import setsSvg from '../assets/icons/sets.svg'
import repsSvg from '../assets/icons/reps.svg'
import startWorkoutSvg from '../assets/icons/start_workout.svg'
import saveEntriesSvg from '../assets/icons/save_entries.svg'
import durationSvg from '../assets/icons/duration.svg'
import videoSvg from '../assets/icons/video.svg'
import startNowSvg from '../assets/icons/start_now.svg'
import markAsDoneSvg from '../assets/icons/mark_as_done.svg'
import infoSvg from '../assets/icons/info.svg'
import cogSvg from '../assets/icons/cog.svg'
import startTimerSvg from '../assets/icons/start_timer.svg'
import stopTimerSvg from '../assets/icons/stop_timer.svg'
import restartTimerSvg from '../assets/icons/restart_timer.svg'
import resumeTimerSvg from '../assets/icons/resume_timer.svg'
import runnerSvg from '../assets/icons/runner.svg'
import infoDarkSvg from '../assets/icons/info_dark.svg'
import groupInfoLightSvg from '../assets/icons/group_info_light.svg'
import groupInfoDarkSvg from '../assets/icons/group_info_dark.svg'
import runningSvg from '../assets/icons/running.svg'
import weightSvg from '../assets/icons/weight.svg'


import momentumPng from '../assets/logos/momentum.png'

export const exerciseIcon = exerciseSvg
export const setsIcon = setsSvg
export const repsIcon = repsSvg
export const startWorkoutIcon = startWorkoutSvg
export const saveEntriesIcon = saveEntriesSvg
export const durationIcon = durationSvg
export const videoIcon = videoSvg
export const startNowIcon = startNowSvg
export const markAsDoneIcon = markAsDoneSvg
export const infoIcon = infoSvg
export const cogIcon = cogSvg
export const startTimerIcon = startTimerSvg
export const stopTimerIcon = stopTimerSvg
export const restartTimerIcon = restartTimerSvg
export const resumeTimerIcon = resumeTimerSvg
export const runnerIcon = runnerSvg
export const infoDarkIcon = infoDarkSvg
export const groupInfoLightIcon = groupInfoLightSvg
export const groupInfoDarkIcon = groupInfoDarkSvg
export const runningIcon = runningSvg
export const weightIcon = weightSvg

export const momentumLogo = momentumPng
