import { Box, Button, Paper } from '@material-ui/core'
import React from 'react'
import DeleteIcon from '@material-ui/icons/Close'

const Panel = React.forwardRef((props, ref) => {
  const { children, header, bodyPadding, index, dayIndex, dayLength, removeDayWeek, ...rest } = props
  return (
    <Paper
      style={{
        overflowY: 'auto',
        '&::WebkitScrollbar': {
          width: '0',
        },
        margin: 3,
      }}
      {...rest}
      ref={ref}
    >
      {header && (
        <Box
          className="panel_header"
          style={{ padding: '15px', borderBottom: '1px solid #0000001f', display: 'flex', alignItems: 'center', justifyContent: 'space-between', minWidth: '15vw'}}
        >
          {header}
          {dayLength - 1 === dayIndex && (<Button style={{ padding: '0', minWidth: 'auto' }} onClick={removeDayWeek}>
            <DeleteIcon style={{ color: 'teal' }} />
          </Button>)}
        </Box>
      )}
      <Box sx={{ p: bodyPadding && bodyPadding, padding: '8px!important' }}>
        {children}
      </Box>
    </Paper>
  )
})

export default Panel
