import React from 'react'

function PageTitle({ title = '', subtitle = '' }) {
  return (
    <div className="header-large-title">
      <h1 className="title">{title}</h1>
      <h6 className="subtitle">{subtitle}</h6> 
    </div>
  )
}

export default PageTitle
