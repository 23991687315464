import React from 'react'
import Layout from 'common/components/Layout'
import UploadsChart from 'uploads/components/UploadsChart'

function Uploads() {
  return (
    <Layout>
      <UploadsChart />
    </Layout>
  )
}

export default Uploads
