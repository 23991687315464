import { useContext, useMemo } from 'react'
import { ActivityLogContext } from 'activity/ducks'

export default function useProgramCards(programList) {
  const { state } = useContext(ActivityLogContext)

  const { programCalendar } = state

  const checkDatesMatch = (date1, date2) => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    )
  }

  const programListForDateSelected = useMemo(() => {
    if (!programCalendar?.dateObj) {
      return []
    }

    const tempList = []
    programList.forEach((program) => {
      const tempObj = {}
      tempObj.name = program.name
      tempObj.description = program.description
      tempObj.exercises = []
      tempObj.date = programCalendar.dateSelected
      program.exercises.forEach((exercises) => {
        const exercisesForTheDay = exercises.filter((exercise) =>
          checkDatesMatch(
            new Date(exercise.start_date),
            programCalendar.dateObj
          )
        )
        if (exercisesForTheDay.length > 0) {
          tempObj.exercises.push(exercisesForTheDay)
        }
      })
      tempObj.exercises = tempObj.exercises.flat()
      if (tempObj.exercises.length > 0) {
        tempList.push(tempObj)
      }
    })

    return tempList
  }, [programList, programCalendar])

  return {
    state,
    programListForDateSelected
  }
}
