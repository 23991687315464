/* eslint-disable */
import React from "react";
import useApi from 'common/hooks/useApi'
import Chip from '@material-ui/core/Chip';

function ProgramChip ({ api, data }) {

    const [, removeProgram] = useApi(
            {
              method: 'POST',
              url: 'trainers/program/remove_athlete/',
            },
            { manual: true }
          )

    function deleteProgram(program, id) {

        const body = {
            'id': program.id,
            'athlete': id,
        }
        removeProgram({
            data: body,
        })
        const rowNode = api.getRowNode(data.id);
        let position = data.programs.indexOf(program);
        data.programs.splice(position, 1)
    }

  return (
    <span>
        {data?.programs?.map((program) => (<Chip label={program.name} onDelete={() => deleteProgram(program, data?.id)}/>))}
    </span>
  );
};

export default ProgramChip
