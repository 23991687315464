import React from 'react'

export const VIEWS = {
  LOG: 'log',
  PLAN: 'plan',
}

function Tabs({
  onLogClick = () => {},
  onPlanClick = () => {},
  view = VIEWS.PLAN,
}) {
  return (
    <div className="section mb-2 mt-2">
      <ul className="nav nav-tabs style1" role="tablist">
        <li className="nav-item">
          <button
            className={`nav-link ${view === VIEWS.PLAN ? 'active' : ''}`}
            onClick={onPlanClick}
            type="button"
          >
            Plan
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${view === VIEWS.LOG ? 'active' : ''}`}
            onClick={onLogClick}
            type="button"
          >
            Log
          </button>
        </li>
      </ul>
    </div>
  )
}

export default Tabs
