import axios from 'axios'
import { camelCase, map, mapKeys, snakeCase } from 'lodash/fp'
import { API_URL } from 'common/env'

// const token ='eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlFqWTJSa0pHTVRKRVJUSTVOelkyTWtJd09VTXdRelExUmtRd05qazBNRVk0TmtVeE9VUkJRUSJ9.eyJpc3MiOiJodHRwczovL21vbWVudHVtc3BvcnRzLXN0YWdlLmF1dGgwLmNvbS8iLCJzdWIiOiJhdXRoMHw1ZTE2ODkwZDRlNzIxODBlOTMzYzE2NTUiLCJhdWQiOlsiaHR0cHM6Ly9tb21lbnR1bXNwb3J0cy1zdGFnZS5hdXRoMC5jb20vYXBpL3YyLyIsImh0dHBzOi8vbW9tZW50dW1zcG9ydHMtc3RhZ2UuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTY2NTk0NTY5MywiZXhwIjoxNjk3NTY4MDkzLCJhenAiOiI4WERkczM2TzRpOHI3QXJOOTltbVMwSFgzQ2RsTTlXQSIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwifQ.zhQw7fYyzucCCz29e_MHc42yZZK1R68wED3pdN04pJWTM_soy7GB2Kn66vb4cKlrGCU-76Rh3-WSN1TvKojACnFXBety48FPGowtYNDpATE2JuM2w20DvsmdA68-6T3C5NDxsqTNkIRndNgnv68bLcb9nuC6V5K73DkFKW8xq84XPzl1LGIp-_SinEy6qXTFCoBQKT2bgyNiU4SEohv8RKZsdh9XTK_-PcXILZExFgrucCTIjAoe-6-A2txCuJMeq3jiJk1yVgLiSF4PNhVy1Prwno8JdRh3AKkuwzNhxJEhfvuxO5VyAjgcaPlidFFZApFdf2lU6sx4b--kOs9eYw'
const token=localStorage.getItem('token')
function transformRequest(data) {
  if (!data || data instanceof FormData) {
    return data
  }
  return mapKeys((key) => snakeCase(key))(data)
}

function transformResponse(data) {
  const camelCaseKeys = mapKeys((key) => camelCase(key))
  if (Array.isArray(data)) {
    return map((item) => camelCaseKeys(item))(data)
  }
  return camelCaseKeys(data)
}

const withAuthInstance = axios.create({
  baseURL: `${API_URL}/`,
  headers: { 'Content-Type': 'application/json' },
  redirect: 'follow',
  transformRequest: [transformRequest, ...axios.defaults.transformRequest],
  transformResponse: [...axios.defaults.transformResponse, transformResponse],
  withCredentials: true,
})

withAuthInstance.interceptors.request.use(
  (config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      delete withAuthInstance.defaults.headers.common.Authorization
    }
    return config
  },
  (error) => console.error(error)
)

export default withAuthInstance
