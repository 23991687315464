import useProgramCards from 'activity/hooks/useProgramCards'
import { durationIcon, momentumLogo, videoIcon } from 'constants/assets'
import React from 'react'
import styled from 'styled-components'

export function ProgramCard({ data, onClick }) {
  if (data) {
    data.videoCount =
      data?.exercises?.filter((exercise) => exercise.video).length ?? 0

    return (
      <ProgramCard.Stylesheet>
        <button type="button" className="programCard" onClick={onClick}>
          <div className="thumbnail">
            <img
              src={data.exercises?.[0]?.thumbnail || momentumLogo}
              alt="Program Thumbnail"
            />
          </div>
          <div className="content">
            <h2>{data.name}</h2>
            <p>{data.description}</p>
          </div>
          <div className="info">
            {!!data.duration && (
              <div>
                <img src={durationIcon} alt="Duration Icon" />
                <p>{data.duration}</p>
              </div>
            )}
            {!!data.videoCount && (
              <div>
                <img src={videoIcon} alt="Video Icon" />
                <p>{data.videoCount} videos</p>
              </div>
            )}
          </div>
        </button>
      </ProgramCard.Stylesheet>
    )
  }
}

export default function ProgramCards({
  programList,
  onClick: openProgramDialog
}) {
  const { programListForDateSelected } = useProgramCards(programList)

  if (programListForDateSelected.length === 0) {
    return (
      <ProgramCards.Stylesheet>
        <div className="noProgram">There are no programs today</div>
      </ProgramCards.Stylesheet>
    )
  }

  return (
    <ProgramCards.Stylesheet>
      <div className="programCardsContainer">
        {programListForDateSelected.map((program, idx) => (
          <ProgramCard
            key={`${program.name}-${String(idx)}`}
            data={program}
            onClick={() =>
              openProgramDialog({
                programName: program.name,
                programDescription: program.description,
                exercises: [...program.exercises],
                duration: program.duration,
                exerciseCount: program.exerciseCount,
                programImage: program.thumbnail,
                date: program.date
              })
            }
          />
        ))}
      </div>
    </ProgramCards.Stylesheet>
  )
}

ProgramCards.Stylesheet = styled.div`
  .noProgram {
    padding: 1rem;
    text-align: center;
  }

  .programCardsContainer {
    padding: 0 1rem 1rem 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    }

    @media screen and (max-width: 543px) {
      grid-template-columns: 1fr;
      gap: 0.5rem;
    }
  }
`

ProgramCard.Stylesheet = styled.div`
  .programCard {
    border: 0.0625rem solid #e5f0fa;
    border-radius: 0.625rem;
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 0;
    background-color: #fff;

    .thumbnail {
      width: 100%;
      height: 18rem;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      img {
        width: 100%;
        height: 100%;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
      }
    }

    .content {
      padding: 1rem;
      padding-bottom: 0;
      height: 6rem;
      overflow-y: auto;

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0.1rem;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }

      h2 {
        font-size: 1rem;
      }

      p {
        font-size: 0.875rem;
        color: #4a6375;
        margin-bottom: 0;
      }
    }

    .info {
      padding: 0 1rem 0 1rem;
      font-weight: 500;
      font-size: 0.75rem;
      color: #111111;
      display: flex;
      height: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      p {
        padding-right: 1rem;
      }

      div {
        display: flex;
        align-items: center;

        p {
          margin: 0;
          margin-left: 0.5rem;
        }

        img {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }
  }
`
