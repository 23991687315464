import React, { useReducer } from 'react'
import { ActivityLogContext, INITIAL_STATE, reducer } from 'activity/ducks'
import Layout from 'common/components/Layout'
import logger from 'use-reducer-logger'
import DataTable from './DataTable'

export default function DataTableContainer() {
  const [state, dispatch] = useReducer(logger(reducer), INITIAL_STATE)
  return (
    <Layout>
      <ActivityLogContext.Provider value={{ state, dispatch }}>
        <DataTable />
      </ActivityLogContext.Provider>
    </Layout>
  )
}
