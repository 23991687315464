import { ActivityLogContext, closeProgramDoneModalAction } from 'activity/ducks'
import useApi from 'common/hooks/useApi'
import moment from 'moment'
import { useContext, useRef, useState } from 'react'

export default function useProgramDoneDialog() {
  const { state, dispatch } = useContext(ActivityLogContext)

  const {
    programCalendar,
    programDialog: { programDoneModal, data }
  } = state

  const btnRef = useRef(null)

  const ENDPOINT = 'metrics/activities/'
  const [, create] = useApi(
    {
      method: 'POST',
      url: ENDPOINT
    },
    { manual: true }
  )

  const [rating, setRating] = useState(undefined)

  const closeProgramDoneModal = () => {
    dispatch(closeProgramDoneModalAction())
  }

  const saveRatingAsRpe = async () => {
    try {
      const body = {}
      body.name = 'RPE'
      body.notes = rating
      const program = state.programDialog.data
      body.program_info = `${program.programName} Week ${program.date.weekCount} Day ${program.date.dayCount}`
      body.day = moment(new Date()).toISOString()
      body.tags = []

      await create({
        data: body
      })

      closeProgramDoneModal()
      setRating(undefined)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    programDoneModal,
    programCalendar,
    data,
    btnRef,
    rating,
    setRating,
    closeProgramDoneModal,
    saveRatingAsRpe
  }
}
