import React from 'react'
import { Route } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

function ProtectedRoute(props) {
  const { component, ...rest } = props

  return <Route component={withAuthenticationRequired(component)} {...rest} />
}

export default ProtectedRoute
