/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete, { createFilterOptions }  from '@material-ui/lab/Autocomplete'
import { Box, Modal, TextField, Button, Chip } from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { GetListExerciseAction, CreateExerciseAction, UpdateExerciseAction } from "Redux/Action/programBuilderAct";

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    DemoLink: Yup.string()
        .min(2, 'Too Short!')
        .url("Demo Link must be a valid URL")
        .notRequired()
        .nullable(),
    Description: Yup.string()
        .max(250, 'Too Long!')
        .notRequired(),
});

const filter = createFilterOptions()

function CreateExercise () {

    const dispatch = useDispatch();
    const { exerciseInfo } = useSelector(state => state.rootReducer.programBuilder)

    const [open, setOpen] = useState(false);
    const [editdata, setEditdata] = useState({});
    const [postCreation, setPostCreation] = useState(false)
    const [tagValue, setTagValue] = React.useState(null)

    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
        setTagValue()
        formik.resetForm()
        setEditdata({})
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            DemoLink: "",
            Description: "",
            Tags: "",
        },
        onSubmit: values => {
            const payload = {
                name: values.name,
                video: values.DemoLink,
                description: values.Description,
                tags: editdata?.tags || [],
            }
            if (editdata?.id) {
                dispatch(UpdateExerciseAction({ updateData: payload, id: editdata.id }))
            } else {
                dispatch(CreateExerciseAction(payload))
                handleClose()
            }
            handleClose()
        },
        validationSchema
    })

    const handleAddTag = () => {
        if(tagValue){
            if (editdata?.id) {
                if (formik.values.name) {
                    editdata?.tags?.push(tagValue)
                    const payload = {
                        name: formik.values.name,
                        video: formik.values.DemoLink,
                        description: formik.values.Description,
                        tags: editdata?.tags,
                    }
                    dispatch(UpdateExerciseAction({ updateData: payload, id: editdata.id }))
                    formik.setFieldValue("Tags", '')
                    setTagValue('')
                } else {
                    alert('Add a exercise name before adding tags')
                }
            } else {
                if (formik.values.name) {
                    const payload = {
                        name: formik.values.name,
                        video: formik.values.DemoLink,
                        description: formik.values.Description,
                        tags: [tagValue],
                    }
                    dispatch(CreateExerciseAction(payload))
                    formik.setFieldValue("Tags", '')
                    setPostCreation(true)
                    setTagValue('')
                } else {
                    alert('Add a exercise name before adding tags')
                }
            }
            dispatch(GetListExerciseAction())
        }
    }

    const deleteTag = (id) => {
        editdata?.tags?.splice(id,1)
        const payload = {
                name: formik.values.name,
                video: formik.values.DemoLink,
                description: formik.values.Description,
                tags: editdata?.tags,
            }
        dispatch(UpdateExerciseAction({ updateData: payload, id: editdata?.id }))
    }

    useEffect(() => {
        if (postCreation) {
            const list = exerciseInfo?.data?.map((item) => Number(item?.id))
            const newId = Math.max(...list)
            const findObj =  exerciseInfo?.data?.find(item => item?.id === newId)
            setEditdata(findObj)
            setPostCreation(false)
        }
    }, [exerciseInfo?.data?.length])

    function handleAutocompleteChange(event, value) {
        setTagValue(value)
      }

    return(
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>+ NEW</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Box
                    style={{
                        backgroundColor: "white",
                        border: "2px solid #000",
                        boxShadow: 24,
                        padding: '30px 30px 0px 30px',
                        minWidth: '40vw',
                        overflow: 'scroll'
                    }}
                >
                    {
                        editdata?.id ? <h3>Edit Exercise</h3> : <h3>Create Exercise</h3>
                    }
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            style={{ marginTop: "10px" }}
                            type="text"
                            label="Name"
                            variant="filled"
                            error={formik.errors.name && formik.touched.name}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <br />
                        <TextField
                            type="text"
                            name="DemoLink"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.DemoLink}
                            style={{ marginTop: "10px" }}
                            label="Demo Link"
                            variant="filled"
                            error={formik.errors.DemoLink && formik.touched.DemoLink}
                            helperText={formik.touched.DemoLink && formik.errors.DemoLink}
                        />
                        <br />
                        <TextField
                            type="text"
                            name="Description"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.Description}
                            style={{ marginTop: "10px", marginBottom: "10px" }}
                            multiline
                            placeholder="Please type Description for the Exercise....."
                            rows={4}
                            label="Description"
                            variant="filled"
                            error={formik.errors.Description && formik.touched.Description}
                            helperText={formik.touched.Description && formik.errors.Description}
                        />
                        <br />
                        {editdata?.tags?.map((tag, index) => (
                            <Chip label={tag} onDelete={() => deleteTag(index)}/>
                          ))}
                        <br />
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px'}}>
                        <Autocomplete
                            options={exerciseInfo?.tagList}
                            onInputChange={handleAutocompleteChange}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Tags"
                                    name="tags"
                                    type='text'
                                    variant='filled'
                                    value={tagValue}
                                />}
                            value={tagValue}
                            style={{width: 600, marginRight: 20}}
                          />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddTag}
                            style={{zoom: '1.1'}}
                            disabled={!tagValue}
                        >
                                Add
                        </Button>
                        </div>
                        <br />
                        <Box style={{ textAlign: 'right', margin: '12px 0px 14px 0px' }} >
                            <Button variant="text" style={{ color: '#0000008A', marginRight: "14px" }} onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary" type="submit">
                                SAVE
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </>
    )
}

export default CreateExercise
