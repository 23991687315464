/* eslint max-len: 0, react/jsx-one-expression-per-line: 0, react/no-unescaped-entities: 0 */

import React from 'react'

function Privacy() {
  return (
    <div className="section">
      <h1 id="momentum-sports-analytics-ltd-">
        MOMENTUM SPORTS ANALYTICS LTD.
      </h1>
      <h2 id="privacy-policy">PRIVACY AGREEMENT</h2>
      <p>
        In accordance with the provisions of Personal Information Protection and
        Electronic Documents Act (PIPEDA), Momentum Sports Analytics Ltd. ("
        <strong>Momentum Sports</strong>") understands how important the privacy
        of personal information is to our users.
      </p>
      <p>
        Here are some of the security procedures that Momentum Sports uses to
        protect your privacy:
        <ul>
          <li>
            Requires both a personal username (log-in name) and a password in
            order for users to access their personally identifiable information
            or personal health information;
          </li>
          <li>Uses firewalls to protect information held in our servers;</li>
          <li>
            Utilizes encryption in transmitting personally identifiable
            information to our servers. In order to take advantage of encryption
            technology, you must have an Internet browser which supports
            appropriate encryption;
          </li>
          <li>
            Closely monitors the limited number of Momentum Sports employees who
            have potential access to your personally identifiable information;
          </li>
          <li>
            Requires all Momentum Sports employees to abide by our Privacy
            Policy and to be subject to disciplinary action if they violate it;
          </li>
          <li>
            Backs-up our systems to protect the integrity of your personally
            identifiable and personal health information;
          </li>
          <li>
            Requires third parties contracting with Momentum Sports to abide by
            privacy laws, as appropriate.
          </li>
        </ul>
      </p>
      <p>
        This Privacy Policy applies to all products and services provided and
        all websites owned and operated by Momentum Sports. Momentum Sports may
        share information among its subsidiaries or sites that it owns or
        controls, but is always protected under the terms of this Privacy
        Policy.
      </p>
      <p>
        While you may use some of the functionality of Momentum Sports without
        registration, many of the specific tools and services on our website and
        through other means require registration. If you use our website or
        other tools without registering, the only information we collect will be
        non-personal information through the use of cookies or web beacons.
      </p>
      <p>
        Third parties under contract with Momentum Sports may use cookies or web
        beacons to collect non- personal information about your usage of the
        Momentum Sports website. These third parties may use this information,
        on our behalf, to assist Momentum Sports.
      </p>
      <p>
        We collect personally identifiable information, like your name, e-mail
        address, date of birth, gender, and postal code that you provide to us
        when you register as a subscriber of Momentum Sports and/or when you
        update your member profile. We use the personally identifiable
        information that you provide when we respond to your questions, when
        Momentum Sports provides you the specific services you select.
      </p>
      <p>
        Momentum Sports may combine, in a non-identifiable format, the personal
        health information you provide with information from other users to
        create aggregate data that may be disclosed. Aggregate data does not
        contain any information that could be used to contact or identify you.
        For example, Momentum Sports may use information gathered to create a
        composite profile of all the users. Momentum Sports may use this
        information to understand needs and to design appropriate programs and
        activities on the Momentum Sports site/platform. Momentum Sports will
        not disclose your personal health information to any third party without
        your prior permission, except as otherwise permitted by this Privacy
        Policy.
      </p>
    </div>
  )
}

export default Privacy
