import React from 'react'
import useProgramCalendar from 'activity/hooks/useProgramCalendar'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import styled from 'styled-components'

export default function ProgramCalendar({ loading, data }) {
  const {
    datesData,
    showPreviousWeek,
    showNextWeek,
    selectDate,
    dateSelected
  } = useProgramCalendar({ loading, data })

  return (
    <ProgramCalendar.Stylesheet>
      <div className="programCalendar">
        <nav>
          <ChevronLeft onClick={showPreviousWeek} />
          <div className="date">
            <p className="month">{dateSelected?.stringMonth}</p>
            <p className="year">{dateSelected?.year}</p>
          </div>
          <ChevronRight onClick={showNextWeek} />
        </nav>
        <div className="view">
          {datesData.map((date) => {
            if (!date.hasPrograms) {
              return (
                <button
                  type="button"
                  disabled
                  key={date.idx}
                  className="disabledElement"
                >
                  <p className="day">{date.day}</p>
                  <p className="number">{date.date}</p>
                </button>
              )
            }
            if (date.isSelected) {
              return (
                <div key={date.idx} className="elementSelected">
                  <p className="day">{date.day}</p>
                  <p className="number">{date.date}</p>
                </div>
              )
            }
            return (
              <div key={date.idx}>
                <button
                  type="button"
                  className="element"
                  onClick={() => selectDate(date)}
                >
                  <p className="day">{date.day}</p>
                  <p className="number">{date.date}</p>
                </button>
              </div>
            )
          })}
        </div>
        <div className="dateCount">
          <span className="week">Week {dateSelected?.weekCount}</span>
          <span className="day">Day {dateSelected?.dayCount}</span>
        </div>
      </div>
    </ProgramCalendar.Stylesheet>
  )
}

ProgramCalendar.Stylesheet = styled.div`
  .programCalendar {
    text-align: center;
    padding: 0.5rem;

    nav {
      display: flex;
      justify-content: space-between;

      svg {
        cursor: pointer;
      }

      .date {
        p {
          margin: 0;
        }
        .month {
          font-weight: 600;
          font-size: 0.875rem;
          color: #191919;
        }
        .year {
          font-weight: 600;
          font-size: 0.75rem;
          color: #3a4750;
          margin-top: -0.3rem;
        }
      }
    }
    .view {
      margin: 0.5rem;
      display: flex;
      gap: 0.5rem;

      .element {
        border: 0;
        margin-top: 0.5rem;
        background-color: #f7f7f7;
        border-radius: 0.625rem;
        width: 2.8125rem;
        font-size: 0.875rem;
        cursor: pointer;

        .day {
          color: #3a4750;
          font-weight: 600;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
        .number {
          font-weight: 700;
          color: #000000;
          border-radius: 3.125rem;
          background-color: #fff;
          width: 1.9rem;
          height: 1.875rem;
          margin: 0 auto;
          margin-bottom: 0.5rem;
        }
      }

      .disabledElement {
        border: 0;
        margin-top: 0.5rem;
        background-color: #F7F7F7;
        border-radius: 0.625rem;
        width: 2.8125rem;
        font-size: 0.875rem;

        .day {
          color: #C2C2C2;
          font-weight: 600;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
        .number {
          font-weight: 700;
          color: #C2C2C2;
          border-radius: 3.125rem;
          background-color: #fff;
          width: 1.9rem;
          height: 1.875rem;
          margin: 0 auto;
          margin-bottom: 0.5rem;
        }

      }

      .elementSelected {
        margin-top: 0.5rem;
        background-color: #09555d;
        border-radius: 0.625rem;
        min-width: 2.8125rem;
        font-size: 0.875rem;

        .day {
          color: #fff;
          font-weight: 600;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
        .number {
          font-weight: 700;
          color: #000000;
          border-radius: 3.125rem;
          background-color: #fff;
          width: 1.9rem;
          height: 1.875rem;
          margin: 0 auto;
          margin-bottom: 0.5rem;
        }
      }
    }

    .dateCount {
      text-align: left;
      padding 1rem;
      color: #191919;

      .week {
        font-weight: 700;
      }

      .day {
        margin-left: 1rem;
      }
    }
  }
`
