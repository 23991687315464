import { useContext, useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {
  ActivityLogContext,
  closeConfirmationModalAction,
  closeLogDrawerAction,
  markExerciseAsDoneAction,
  openConfirmationModalAction,
  openProgramDoneModalAction,
  saveDataAction
} from 'activity/ducks'
import { LayoutContext } from 'common/ducks'
import useApi from 'common/hooks/useApi'
import LRUCache from 'common/utils/LRUCache'
import DailyCache from 'common/utils/DailyCache'
import moment from 'moment'
import { UNITS } from 'constants/log'

const ENDPOINT = 'metrics/activities/'

export default function useLogForm() {
  const { state, dispatch } = useContext(ActivityLogContext)
  const { setAppLoading } = useContext(LayoutContext)
  const [conversionFactor, setConversionFactor] = useState(UNITS.m)
  const data = state.programDialog?.logDrawer?.data
  const [checked, setChecked] = useState(data?.kpi)
  const [formSubmtited, setFormSubmtited] = useState(false)

  const [{ loading: createLoading }, create] = useApi(
    {
      method: 'POST',
      url: ENDPOINT
    },
    { manual: true }
  )

  const [{ loading: updateLoading }] = useApi(
    {
      method: 'PUT'
    },
    { manual: true }
  )

  const cachedActivityNames = new LRUCache('activityNames', 50)
  const cachedSessionActivities = new DailyCache('sessionActivities')

  async function postForm(values) {
    const { day, distance, index, restTime, weight, workTime, tags, ...rest } =
      values

    const body = {
      ...rest,
      day: moment(day).toISOString(),
      distanceMeters: distance
        ? (distance * conversionFactor).toFixed(2)
        : null,
      restTimeSeconds: restTime,
      user: data?.user,
      weightLbs: weight,
      workTimeSeconds: workTime,
      kpi: checked,
      tags: tags || []
    }

    try {
      const response = await create({
        data: body
      })

      dispatch(
        saveDataAction({
          index,
          ...response.data
        })
      )

      if (values.name) {
        cachedSessionActivities.set(values.name, values)
      }

      setFormSubmtited(true)
      if (data?.id) {
        dispatch(markExerciseAsDoneAction(data.id))
      }
    } catch (e) {
      console.error(e)
    } finally {
      if (data?.isLastExercise && !state.programDialog.programDoneModal.open) {
        dispatch(openProgramDoneModalAction())
      }

      if (values.name) {
        cachedActivityNames.put(values.name)
      }

      dispatch(closeLogDrawerAction())
    }
  }

  const {
    reps,
    setNumber = 0,
    weight
  } = cachedSessionActivities.get(data?.name) || {}

  const initialValues = {
    ...data,
    ...(data?.set ? { setNumber: data.set } : { setNumber: setNumber + 1 }),
    ...(data?.reps ? { reps: data.reps } : { reps }),
    ...(data?.weight ? { weight: data.weight } : { weight }),
    ...(data?.measuredWorkTime
      ? { workTime: data.measuredWorkTime }
      : { workTime: 0 }),
    setHidden: !data?.set,
    repsHidden: !data?.reps,
    weightHidden: !data?.weight,
    distanceHidden: !data?.distance,
    rirHidden: !data?.rir,
    tempoHidden: !data?.tempo,
    workTimeHidden: !data?.workTime,
    restTimeHidden: !data?.restTime,
    kpiHidden: !!data?.kpi
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: postForm
  })

  const defaultTextFieldOptions = {
    fullWidth: true,
    margin: 'normal',
    onChange: formik.handleChange,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true
    },
    InputProps: {
      inputProps: {
        min: 0
      }
    },
    disabled: formSubmtited
  }

  const distanceFieldOptions = {
    fullWidth: false,
    margin: 'normal',
    onChange: formik.handleChange,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true
    },
    InputProps: {
      inputProps: {
        min: 0
      }
    },
    disabled: formSubmtited
  }

  function updateConversionFactor(event) {
    setConversionFactor(event.target.value)
  }

  useEffect(() => {
    setAppLoading(createLoading || updateLoading)
  }, [createLoading, setAppLoading, updateLoading])

  const updateKpi = (event) => {
    setChecked(event.target.checked)
    formik.values.kpi = event.target.checked
  }

  const openConfirmationModal = (e) => {
    e.preventDefault()
    dispatch(openConfirmationModalAction('LOG_DRAWER'))
  }

  const closeConfirmationModal = () => {
    dispatch(closeConfirmationModalAction('LOG_DRAWER'))
  }

  const dataWithAdditionalFields = JSON.parse(JSON.stringify(data))
  if (dataWithAdditionalFields) {
    dataWithAdditionalFields.source = state.dialog?.data?.source
  }

  return {
    updateKpi,
    checked,
    formik,
    data: dataWithAdditionalFields,
    defaultTextFieldOptions,
    distanceFieldOptions,
    conversionFactor,
    updateConversionFactor,
    openConfirmationModal,
    closeConfirmationModal,
    state,
    postForm,
    formSubmtited
  }
}
