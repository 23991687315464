import React from 'react'
import { Box } from '@material-ui/core';
import WeekTabs from 'programBuilder/components/WeekTabs';

function TabWeekPanel(props) {
    const { ExerciseData, WeekData, BlockData, GroupData, DayTitleData, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box> <WeekTabs value={value} WeekData={WeekData} ExerciseData={ExerciseData} BlockData={BlockData} GroupData={GroupData} DayTitleData={DayTitleData} /></Box>}
        </Box>
    );
};

export default TabWeekPanel;