import React from 'react'
import Layout from 'common/components/Layout'
import AthleteReport from '../components/AthleteReport'

function TrainerAthleteView() {
    return (
        <Layout>
            <AthleteReport />
        </Layout>
    )
}

export default TrainerAthleteView
