/* eslint-disable */
import { all, takeEvery } from "redux-saga/effects";
import { CREATE_BLANK_WEEKS_REQUEST, CREATE_BLOCKS_REQUEST, CREATE_EXERCISE_REQUEST, CREATE_GROUPS_REQUEST, CREATE_PROGRAM_DAYS_REQUEST, CREATE_PROGRAM_EXERCISE_REQUEST, CREATE_PROGRAM_REQUEST, CREATE_PROGRAM_WEEKS_REQUEST, DELETE_BLOCKS_REQUEST, DELETE_EXCERSIZE_DATA_REQUEST, DELETE_GROUPS_REQUEST, DELETE_PROGRAM_DAYS_REQUEST, DELETE_PROGRAM_EXERCISE_REQUEST, DELETE_PROGRAM_REQUEST, DELETE_PROGRAM_WEEKS_REQUEST, GET_BLOCK_REQUEST, GET_EXERCISE_REQUEST, GET_GROUPS_REQUEST, GET_LIST_BLOCKS_REQUEST, GET_LIST_EXERCISE_REQUEST, GET_LIST_GROUPS_REQUEST, GET_LIST_PROGRAM_DAYS_REQUEST, GET_LIST_PROGRAM_EXERCISE_REQUEST, GET_LIST_PROGRAM_REQUEST, GET_LIST_PROGRAM_WEEKS_REQUEST, GET_PROGRAM_DAYS_REQUEST, GET_PROGRAM_EXERCISE_REQUEST, GET_PROGRAM_WEEKS_REQUEST, UPDATE_BLOCKS_REQUEST, UPDATE_EXCERSIZE_DATA_REQUEST, UPDATE_GROUPS_REQUEST, UPDATE_PROGRAM_DAYS_REQUEST, UPDATE_PROGRAM_EXERCISE_REQUEST, UPDATE_PROGRAM_REQUEST, UPDATE_PROGRAM_WEEKS_REQUEST } from "Redux/Action/constant";
import { CreateBlankProgramWeeksSaga, CreateBlockSaga, CreateExerciseSaga, CreateGroupsSaga, CreateProgramDaysSaga, CreateProgramExerciseSaga, CreateProgramSaga, CreateProgramWeeksSaga, DeleteBlockSaga, DeleteExerciseSaga, DeleteGroupsSaga, DeleteProgramDaysSaga, DeleteProgramExerciseSaga, DeleteProgramSaga, DeleteProgramWeeksSaga, GetBlockSaga, GetExerciseSaga, GetGroupsSaga, GetListBlocksSaga, GetListExerciseSaga, GetListGroupsSaga, GetListProgramDaysSaga, GetListProgramExerciseSaga, GetListProgramSaga, GetListProgramWeeksSaga, GetProgramDaysSaga, GetProgramExerciseSaga, GetProgramWeeksSaga, UpdateBlockSaga, UpdateExerciseSaga, UpdateGroupsSaga, UpdateProgramDaysSaga, UpdateProgramExerciseSaga, UpdateProgramSaga, UpdateProgramWeeksSaga } from "./ProgramBuilderSagas";

function* rootSagas() {
    yield all([
        // <-----------------------EXERCISES----------------------------->
        takeEvery(CREATE_EXERCISE_REQUEST, CreateExerciseSaga),
        takeEvery(GET_LIST_EXERCISE_REQUEST, GetListExerciseSaga),
        takeEvery(GET_EXERCISE_REQUEST, GetExerciseSaga),
        takeEvery(UPDATE_EXCERSIZE_DATA_REQUEST, UpdateExerciseSaga),
        takeEvery(DELETE_EXCERSIZE_DATA_REQUEST, DeleteExerciseSaga),

        // <-----------------------PROGRAM EXERCISES----------------------------->
        takeEvery(GET_LIST_PROGRAM_EXERCISE_REQUEST, GetListProgramExerciseSaga),
        takeEvery(GET_PROGRAM_EXERCISE_REQUEST, GetProgramExerciseSaga),
        takeEvery(CREATE_PROGRAM_EXERCISE_REQUEST, CreateProgramExerciseSaga),
        takeEvery(UPDATE_PROGRAM_EXERCISE_REQUEST, UpdateProgramExerciseSaga),
        takeEvery(DELETE_PROGRAM_EXERCISE_REQUEST, DeleteProgramExerciseSaga),

        // <-----------------------BLOCKS----------------------------->
        takeEvery(GET_LIST_BLOCKS_REQUEST, GetListBlocksSaga),
        takeEvery(GET_BLOCK_REQUEST, GetBlockSaga),
        takeEvery(CREATE_BLOCKS_REQUEST, CreateBlockSaga),
        takeEvery(UPDATE_BLOCKS_REQUEST, UpdateBlockSaga),
        takeEvery(DELETE_BLOCKS_REQUEST, DeleteBlockSaga),

        // <-----------------------GROUPS----------------------------->
        takeEvery(GET_LIST_GROUPS_REQUEST, GetListGroupsSaga),
        takeEvery(GET_GROUPS_REQUEST, GetGroupsSaga),
        takeEvery(CREATE_GROUPS_REQUEST, CreateGroupsSaga),
        takeEvery(UPDATE_GROUPS_REQUEST, UpdateGroupsSaga),
        takeEvery(DELETE_GROUPS_REQUEST, DeleteGroupsSaga),

        // <-----------------------PROGRAM----------------------------->
        takeEvery(GET_LIST_PROGRAM_REQUEST, GetListProgramSaga),
        takeEvery(CREATE_PROGRAM_REQUEST, CreateProgramSaga),
        takeEvery(UPDATE_PROGRAM_REQUEST, UpdateProgramSaga),
        takeEvery(DELETE_PROGRAM_REQUEST, DeleteProgramSaga),

        // <-----------------------PROGRAM DAYS----------------------------->
        takeEvery(GET_LIST_PROGRAM_DAYS_REQUEST, GetListProgramDaysSaga),
        takeEvery(GET_PROGRAM_DAYS_REQUEST, GetProgramDaysSaga),
        takeEvery(CREATE_PROGRAM_DAYS_REQUEST, CreateProgramDaysSaga),
        takeEvery(UPDATE_PROGRAM_DAYS_REQUEST, UpdateProgramDaysSaga),
        takeEvery(DELETE_PROGRAM_DAYS_REQUEST, DeleteProgramDaysSaga),

        // <-----------------------PROGRAM WEEKS----------------------------->
        takeEvery(GET_LIST_PROGRAM_WEEKS_REQUEST, GetListProgramWeeksSaga),
        takeEvery(GET_PROGRAM_WEEKS_REQUEST, GetProgramWeeksSaga),
        takeEvery(CREATE_PROGRAM_WEEKS_REQUEST, CreateProgramWeeksSaga),
        takeEvery(CREATE_BLANK_WEEKS_REQUEST, CreateBlankProgramWeeksSaga),
        takeEvery(UPDATE_PROGRAM_WEEKS_REQUEST, UpdateProgramWeeksSaga),
        takeEvery(DELETE_PROGRAM_WEEKS_REQUEST, DeleteProgramWeeksSaga)
    ]);
}

export default rootSagas;
