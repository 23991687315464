import React from 'react'
import PageTitle from 'common/components/PageTitle'
import useApi from 'common/hooks/useApi'

function FAQs() {
  const [{ data = [] }] = useApi('faqs/questions/')

  return (
    <>
      <PageTitle title="FAQs" />

      <div className="accordion" id="faqs">
        {data?.map((datum) => (
          <div className="item" key={datum.id}>
            <div className="accordion-header">
              <button
                className="btn"
                type="button"
                data-toggle="collapse"
                data-target={`#faqs${datum.id}`}
              >
                <strong>{datum.question}</strong>
              </button>
            </div>
            <div
              id={`faqs${datum.id}`}
              className="accordion-body collapse"
              data-parent="#faqs"
            >
              <div className="accordion-content">{datum.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default FAQs
