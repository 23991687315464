import React, { useContext } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { UserContext } from 'account'
import { ADMIN_URL } from 'common/env'
import useApi from 'common/hooks/useApi'

function Sidebar({ collapse = true, onCollapse = () => { } }) {
  const { logout } = useAuth0()
  const [{ loading: loadingPortal }, getCustomerPortalUrl] = useApi(
    { method: 'GET' },
    { manual: true }
  )
  const user = useContext(UserContext)

  function handleCollapse(event) {
    event.preventDefault()

    onCollapse()
  }

  function handleLogout() {
    logout({ returnTo: window.location.origin })
  }

  async function goToSubscriptionPage() {
    if (!loadingPortal){
      const portalUrl = await getCustomerPortalUrl({
      url:'stripe/customer_portal_url/',
    })
      if (portalUrl.data.customerPortalUrl) {
        window.location.href = portalUrl.data.customerPortalUrl
      }
    }
  }

  return (
    <Sidebar.Styles collapse={collapse}>
      <div
        id="Sidebar"
        className={`modal fade panelbox panelbox-left ${collapse ? '' : 'show'
          }`}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="profileBox">
                <div className="image-wrapper">
                  <img
                    src={user?.avatar}
                    alt="Avatar"
                    className="imaged rounded"
                  />
                </div>
                <div className="in">
                  <strong>{user?.displayName}</strong>
                </div>
                <button
                  type="button"
                  className="btn btn-icon close-sidebar-button"
                  onClick={handleCollapse}
                >
                  <ion-icon
                    name="close"
                    role="img"
                    className="md hydrated"
                    aria-label="close"
                  />
                </button>
              </div>

              <ul className="listview flush transparent no-line image-listview mt-2">
                {user?.isTrainer && (
                  <>
                    <div className="listview-title mt-2 mb-1">
                      <span>Trainer</span>
                    </div>
                    <li>
                      <a href="/trainerDashboard" className="item">
                        <div className="icon-box bg-primary">
                          <ion-icon
                            name="people-outline"
                            role="img"
                            className="md hydrated"
                            aria-label="FAQs"
                          />
                        </div>
                        <div className="in">Trainer Dashboard</div>
                      </a>
                    </li>
                    <li>
                      <NavLink exact to="/programbuilder" className="item">
                        <div className="icon-box bg-primary">
                          <ion-icon
                            name="calendar-outline"
                            role="img"
                            className="md hydrated"
                            aria-label="Program"
                          />
                        </div>
                        <div className="in">Program Builder</div>
                      </NavLink>
                    </li>
                    <li>
                      <a href="/athleteReport/None" className="item">
                        <div className="icon-box bg-primary">
                          <ion-icon
                            name="analytics-outline"
                            role="img"
                            className="md hydrated"
                            aria-label="Athlete Report"
                          />
                        </div>
                        <div className="in">Athlete Report</div>
                      </a>
                    </li>
                    <li>
                      <NavLink exact to="/onerepmaxtable" className="item">
                        <div className="icon-box bg-primary">
                          <ion-icon
                            name="barbell-outline"
                            role="img"
                            className="md hydrated"
                            aria-label="One Rep Max"
                          />
                        </div>
                        <div className="in">One Rep Max Table</div>
                      </NavLink>
                    </li>
                  </>
                )}
                <div className="listview-title mt-2 mb-1">
                      <span>Athlete</span>
                </div>
                <li>
                  <NavLink exact to="/program/activity/" className="item">
                    <div className="icon-box bg-primary">
                      <ion-icon
                        name="calendar-outline"
                        role="img"
                        className="md hydrated"
                        aria-label="Program"
                      />
                    </div>
                    <div className="in">Program</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/analytics" className="item">
                    <div className="icon-box bg-primary">
                      <ion-icon
                        name="analytics-outline"
                        role="img"
                        className="md hydrated"
                        aria-label="My Report"
                      />
                    </div>
                    <div className="in">Analytics</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/myReport" className="item">
                    <div className="icon-box bg-primary">
                      <ion-icon
                        name="reader-outline"
                        role="img"
                        className="md hydrated"
                        aria-label="My Report"
                      />
                    </div>
                    <div className="in">Report</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/uploads" className="item">
                    <div className="icon-box bg-primary">
                      <ion-icon
                        name="sync-outline"
                        role="img"
                        className="md hydrated"
                        aria-label="Sync"
                      />
                    </div>
                    <div className="in">Sync</div>
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/faqs" className="item">
                    <div className="icon-box bg-primary">
                      <ion-icon
                        name="help-outline"
                        role="img"
                        className="md hydrated"
                        aria-label="FAQs"
                      />
                    </div>
                    <div className="in">FAQs</div>
                  </NavLink>
                </li>

                {user?.isStaff && (
                <>
                    <div className="listview-title mt-2 mb-1">
                          <span>Staff</span>
                    </div>
                    <li>
                      <a href={ADMIN_URL} className="item">
                        <div className="icon-box bg-primary">
                          <ion-icon
                            name="build-outline"
                            role="img"
                            className="md hydrated"
                            aria-label="FAQs"
                          />
                        </div>
                        <div className="in">Admin</div>
                      </a>
                    </li>
                </>
                )}
              </ul>
            </div>

            <div className="appBottomMenu">
            <button type="button" className="btn item" onClick={() => goToSubscriptionPage()}>
                <div className="col">
                  <ion-icon
                    name="card-outline"
                    role="img"
                    className="md hydrated"
                    aria-label="Subscription"
                  />
                  <strong>Subscription</strong>
                </div>
              </button>
              <button type="button" className="btn item" onClick={handleLogout}>
                <div className="col">
                  <ion-icon
                    name="log-out-outline"
                    role="img"
                    className="md hydrated"
                    aria-label="Sign out"
                  />
                  <strong>Sign Out</strong>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop fade ${collapse ? '' : 'show'}`} />
    </Sidebar.Styles>
  )
}

Sidebar.Styles = styled.div`
  #Sidebar {
    display: block;
    width: ${({ collapse }) => (collapse ? 0 : '100%')};
  }

  .listview {
    position: relative;
    top: 74px;
  }

  .modal-backdrop {
    display: ${({ collapse }) => (collapse ? 'none' : 'block')};
  }

  .profileBox > .in > strong {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }
`

export default Sidebar
