import React from 'react'
import { isEmpty } from 'lodash/fp'
import { UserContext } from 'account'
import { API_URL } from 'common/env'
import ProtectedRoute from './ProtectedRoute'

function withSubscription(WrappedComponent) {
  return class Component extends React.Component {
    static contextType = UserContext

    render() {
      const user = this.context

      if (!isEmpty(user) && !user.hasActiveStripeSubscription) {
        window.location.href = `${API_URL}/pricing/general`

        return null
      }

      return <WrappedComponent {...this.props} />
    }
  }
}

function SubscribedRoute(props) {
  const { component, ...rest } = props

  return <ProtectedRoute component={withSubscription(component)} {...rest} />
}

export default SubscribedRoute
