import { React, useState, useContext, useEffect, useCallback } from 'react'
import useApi from 'common/hooks/useApi'
import { LayoutContext } from 'common/ducks'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PageTitle from 'common/components/PageTitle'
import TextField from '@material-ui/core/TextField'
import Select, { SelectChangeEvent } from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { UserContext } from 'account'





function OneRepMax() {
    const user = useContext(UserContext)

    const { setAppLoading } = useContext(LayoutContext)

    const [data, setData] = useState([{name: '', exercise: '', weight_lbs: '',}]);
    const [data2, setData2] = useState([{name: '', exercise: '', weight_lbs: '',}]);
    const [exerciseList, setExerciseList] = useState([{}]);
    const [exercise, setExercise] = useState();
    const [weight, setWeight] = useState();
    const [athleteList, setAthleteList] = useState([])
    const [athlete, setAthlete] = useState()


    const AthleteIconDiv = styled.div`
        padding-top: 25px;
        padding-left: 15px;
        padding-bottom: 25px;
        display: flex;
        flex-direction: row;
    `;

    const [{ loading: infoLoading }, info ] = useApi({url: 'builder/onerepmax/'}, {manual: true})
   const [{ loading: listLoading }, list ] = useApi({url: 'metrics/athlete_info/'}, {manual: true})

    const [{ loading: sendLoading }, postData] = useApi(
        {
          method: 'POST',
          url: 'builder/onerepmax/',
        },
        { manual: true }
      )

    const [{ loading: putLoading }, putData] = useApi(
        {
          method: 'PUT',
          url: `builder/onerepmax/${exercise?.table_id}/`,
        },
        { manual: true }
      )

   const fetchData = useCallback(
    async () => {
      const response = await info({
        params: {
          user: athlete,
        },
      })
      setData(response?.data.data)
      setData2(response?.data.data2)
      setExerciseList(response?.data.exerciseList)
    },
    [ info, athlete]
    )

  useEffect(() => {
      fetchData()
    }, [fetchData, info, athlete])

  const fetchList = useCallback(
    async () => {
      const response = await list()
      setAthleteList(response?.data.clientInfo)
    },
    [ list]
  )
  useEffect(() => {
      fetchList()
    }, [fetchList, list])

  useEffect(() => {
    setAppLoading(listLoading || infoLoading)
  }, [listLoading, sendLoading, putLoading, setAppLoading, infoLoading])

  const handleExerciseChange = (event: SelectChangeEvent) => {
    setExercise(event.target.value);
  };
  const handleWeightChange = (event: SelectChangeEvent) => {
    setWeight(event.target.value);
  };

  const handleAthleteChange = (event: SelectChangeEvent) => {
        setAthlete(event.target.value);
    };

  async function saveData() {

      const body = {
        user: athlete ?? user.email,
        weight_lbs: weight,
        exercise: exercise.exercise_id,
      }

      if (exercise.table_id) {
        await putData({
        data: body,
      })
      fetchData()
      setWeight('')
      }
      else{
      await postData({
        data: body,
      })
      fetchData()
      setWeight('')
      }
    };


    return (
        <OneRepMax.Stylesheet>
        <PageTitle title="One Rep Max Table" />
        <AthleteIconDiv>
                <FormControl variant='outlined' style={{paddingRight: '20px', minWidth: '150px', width: '25%'}} >
                <InputLabel >Athlete</InputLabel>
                    <Select
                      value={athlete}
                      onChange={handleAthleteChange}
                    >
                      {athleteList?.map((row) => (
                              <MenuItem value={row?.email}>{row?.name}</MenuItem>
                          ))}
                    </Select>
                </FormControl>
            </AthleteIconDiv>
            <Box style={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
            <FormControl variant='outlined' style={{paddingRight: '20px', minWidth: '150px', width: '25%'}} >
            <InputLabel >Exercise</InputLabel>
                <Select
                  value={exercise}
                  onChange={handleExerciseChange}
                >
                  {exerciseList?.map((row) => (
                          <MenuItem value={row?.id}>{row?.exercise}</MenuItem>
                      ))}
                </Select>
            </FormControl>
              <TextField
                name="weight"
                label='Weight (Lbs)'
                type="number"
                variant='outlined'
                onChange={handleWeightChange}
                value={weight}
                style={{paddingRight: '20px',}}
              />
              <Button
                variant="contained"
                color="primary"
                style={{height: '55px', width: '100px'}}
                onClick={saveData}
              >
              Save
              </Button>
            </Box>

            <h2>Active</h2>
            <TableContainer component={Paper} style={{width: '90%', margin: 'auto'}}>
                <Table aria-label="collapsible table">
                    <TableHead style={{backgroundColor: '#0B545E'}}>
                        <TableRow>
                            <TableCell align="center">Exercise</TableCell>
                            <TableCell align="center">Weight (Lbs)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {data2?.map((row) => (
                        <TableRow>
                          <TableCell align="center">{row?.exercise}</TableCell>
                          <TableCell align="center">{row?.weight_lbs}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <h2>Historic</h2>
            <TableContainer component={Paper} style={{width: '90%', margin: 'auto'}}>
                <Table aria-label="collapsible table">
                    <TableHead style={{backgroundColor: '#0B545E'}}>
                        <TableRow>
                            <TableCell align="center">Exercise</TableCell>
                            <TableCell align="center">Weight (Lbs)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.map((row) => (
                        <TableRow>
                          <TableCell align="center">{row?.exercise}</TableCell>
                          <TableCell align="center">{row?.weight_lbs}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </OneRepMax.Stylesheet>
    )
}

OneRepMax.Stylesheet = styled.div`
    th {
    color: white;
    }

    TableContainer = {
        width: 90%;
        margin: auto;
    }

    h2 {
        padding: 30px;
    }
`

export default OneRepMax
