import React, { useEffect } from 'react'

function AddToHome() {
  useEffect(() => {
    const isStandalone =
      navigator.standalone || matchMedia('(display-mode: standalone)').matches

    if (!isStandalone) {
      if (window.androidDetection) {
        window.$('#android-add-to-home-screen').modal()
      }

      if (window.iosDetection) {
        window.$('#ios-add-to-home-screen').modal()
      }
    }
  }, [])

  return (
    <>
      <div
        className="modal inset fade action-sheet ios-add-to-home"
        id="ios-add-to-home-screen"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add to Home Screen</h5>
              <button
                type="button"
                className="btn btn-icon close-button"
                data-dismiss="modal"
              >
                <ion-icon name="close" />
              </button>
            </div>
            <div className="modal-body">
              <div className="action-sheet-content text-center">
                <div>{`Install Sidekick on your iPhone's home screen.`}</div>
                <div>
                  Tap
                  <ion-icon name="share-outline" />
                  and Add to Home Screen.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal inset fade action-sheet android-add-to-home"
        id="android-add-to-home-screen"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add to Home Screen</h5>
              <button
                type="button"
                className="btn btn-icon close-button"
                data-dismiss="modal"
              >
                <ion-icon name="close" />
              </button>
            </div>
            <div className="modal-body">
              <div className="action-sheet-content text-center">
                <div>{`Install Sidekick on your Android's home screen.`}</div>
                <div>
                  Tap
                  <ion-icon name="ellipsis-vertical" />
                  and Add to Home screen.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddToHome
