import { useContext, useState } from 'react'
import {
  ActivityLogContext,
  closeConfirmationModalAction,
  closeProgramDialogAction,
  openConfirmationModalAction,
  openProgramDialogAction,
  openProgramDoneModalAction,
  updateTotalWorkoutTimeAction
} from 'activity/ducks'
import useApi from 'common/hooks/useApi'

export default function useProgramDialog() {
  const { state, dispatch } = useContext(ActivityLogContext)
  const [checked, setChecked] = useState(false)

  const exercises = state.programDialog?.data?.exercises

  const openProgramDialog = () => dispatch(openProgramDialogAction())
  const closeProgramDialog = () => dispatch(closeProgramDialogAction())

  const [, addDay] = useApi(
    {
      method: 'POST',
      url: 'metrics/program_plan/add_day/'
    },
    { manual: true }
  )

  const openConfirmationModal = () => {
    dispatch(updateTotalWorkoutTimeAction(0))
    dispatch(openConfirmationModalAction('PROGRAM_DIALOG'))
  }
  const closeConfirmationModal = () => {
    dispatch(closeConfirmationModalAction('PROGRAM_DIALOG'))
  }
  const openProgramDoneModal = () => {
    dispatch(openProgramDoneModalAction())
  }

  const logAllWorkoutsForDateSelected = async () => {
    if (exercises) {
      try {
        await addDay({
          data: {
            id: exercises[0].dayId,
            program_info: exercises[0]?.programInfo
          }
        })
        openProgramDoneModal()
      } catch (e) {
        console.error('Error adding day', e)
      } finally {
        setChecked(true)
        closeConfirmationModal()
      }
    }
  }

  return {
    closeProgramDialog,
    openProgramDialog,
    state,
    dispatch,
    checked,
    setChecked,
    logAllWorkoutsForDateSelected,
    openConfirmationModal,
    closeConfirmationModal
  }
}
