import React from 'react'
import ReactDOM from 'react-dom'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { Buffer } from 'buffer';
import { RELEASE_ENV, SENTRY_DSN } from 'common/env'
import store from 'common/store'
import GlobalStyle from 'styles/GlobalStyle'
import theme from 'styles/theme'
import Router from './Router'

if (RELEASE_ENV !== 'development') {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: RELEASE_ENV,
      integrations: [new Integrations.BrowserTracing()],
    })
  }
}

global.Buffer = Buffer;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <GlobalStyle />
      <Provider store={store}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </Provider>
    </MuiPickersUtilsProvider>
  </ThemeProvider>,
  document.getElementById('root')
)
