class LocalStorage {
  get(name) {
    try {
      return JSON.parse(window.localStorage.getItem(name)) || []
    } catch {
      return []
    }
  }

  set(name, cache) {
    window.localStorage.setItem(name, JSON.stringify(cache))
  }
}

class LRUCache {
  constructor(name, size = 5) {
    this.name = `sidekick.cache.${name}`
    this.size = size
    this.storage = new LocalStorage()

    this.cache = this.storage.get(this.name)
  }

  list() {
    return this.cache
  }

  peak() {
    return this.cache?.[0]
  }

  put(value) {
    this.cache = this.cache.filter((v) => v !== value)
    this.cache.unshift(value)
    this.cache = this.cache.slice(0, this.size)

    this.sync()
  }

  sync() {
    this.storage.set(this.name, this.cache)
  }
}

export default LRUCache
