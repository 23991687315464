import React from 'react'
import logo from 'static/img/logo.png'


function Header({
  onSidebarTap = () => { },
  title = '',
}) {
  function handleSidebarTap(event) {
    event.preventDefault(event)

    onSidebarTap()
  }

  return (
    <nav className="appHeader bg-primary scrolled">

      <div className="left">

        <button
          type="button"
          className="btn headerButton"
          onClick={handleSidebarTap}
        >
          <ion-icon
            name="menu-outline"
            role="img"
            className="md hydrated"
            aria-label="Side Menu"
          />
          <div className="pageTitle">{title}</div>
        </button>
      </div>
      <div className="middle">
        <img src={logo} alt="Logo" />
      </div>
    </nav>
  )
}

export default Header
