import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox } from "@material-ui/core";
import { UpdateProgramExerciseAction } from "../../Redux/Action/programBuilderAct"

function CheckboxRenderer ({ api, data }) {
  const dispatch = useDispatch()

  const buttonClicked = () => {

    const payload = {
        exercise_pk: data.exercise_pk,
        exercise: data.exercise,
        set_number: Number(data.set_number),
        weight_lbs: Number(data.weight_lbs),
        distance_meters: Number(data.distance_meters),
        reps: Number(data.reps),
        reps_in_reserve: Number(data.reps_in_reserve),
        one_rep_max_percent: Number(data.one_rep_max_percent),
        tempo: data.tempo,
        work_time_seconds: Number(data.work_time_seconds),
        rest_time_seconds: Number(data.rest_time_seconds),
        notes: data.notes,
        kpi: !data.kpi,
        position: data.rowIndex,
        block_id: data.block_id,
        source: data?.source
    }
    if (api) {
        api.applyTransaction({ update: [{
                id: data.id,
                exercise: data.exercise,
                exercise_pk: data.exercise_pk,
                set_number: Number(payload.set_number),
                weight_lbs: Number(payload.weight_lbs),
                distance_meters: Number(payload.distance_meters),
                reps: Number(payload.reps),
                reps_in_reserve: Number(payload.reps_in_reserve),
                one_rep_max_percent: Number(payload.one_rep_max_percent),
                tempo: payload.tempo,
                work_time_seconds: Number(payload.work_time_seconds),
                rest_time_seconds: Number(payload.rest_time_seconds),
                notes: payload.notes,
                kpi: !data.kpi,
                position: payload.rowIndex,
                block_id: payload.block_id,
                value: data.value,
            }] })
    }
    dispatch(UpdateProgramExerciseAction({ updateData: payload, id: data.id }))
  }

  return (
      <Checkbox checked={data.kpi} style={{ color: "teal" }} onClick={() => buttonClicked()} />
  );
};

export default CheckboxRenderer
