export const POST_ACTIVITY_GROUP_DETAIL = "POST_ACTIVITY_GROUP_DETAIL"
export const POST_ACTIVITY_DETAIL = "POST_ACTIVITY_DETAIL"
export const UPDATE_ACTIVTITY_DETAILS = "UPDATE_ACTIVTITY_DETAILS"
export const DELETE_ACTIVITY_DETAILS = "DELETE_ACTIVITY_DETAILS"
export const DELETE_ACTIVITY_GROUP = "DELETE_ACTIVITY_GROUP"
export const RESET_ACTVITY_DETAILS_AND_ACTIVITY_GROUPS = "RESET_ACTVITY_DETAILS_AND_ACTIVITY_GROUPS"
export const UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS = "UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS"
export const ADD_ACTIVTY_GROUP_ACTIVITTIES = "ADD_ACTIVTY_GROUP_ACTIVITTIES"
export const COMMON_ACTIVITY_DETAIL = "COMMON_ACTIVITY_DETAIL"
export const DELETE_COMMON_DETAILS = "DELETE_COMMON_DETAILS"
export const UPDATE_COMMON_DETAILS = "UPDATE_COMMON_DETAILS"
export const ADD_COMMON_DATA = "ADD_COMMON_DATA"

export const FETCH_EXCERSIZE_DATA = "FETCH_EXCERSIZE_DATA"

export const DELETE_EXCERSIZE_DATA = "DELETE_EXCERSIZE_DATA"
export const DELETE_EXCERSIZE_DATA_REQUEST = "DELETE_EXCERSIZE_DATA_REQUEST"
export const DELETE_EXCERSIZE_DATA_SUCCESS = "DELETE_EXCERSIZE_DATA_SUCCESS"
export const DELETE_EXCERSIZE_DATA_FAILED = "DELETE_EXCERSIZE_DATA_FAILED"


export const FETCH_BLOCK_DATA = "FETCH_BLOCK_DATA"
export const GET_LIST_BLOCKS_REQUEST = "GET_LIST_BLOCKS_REQUEST"
export const GET_LIST_BLOCKS_SUCCESS = "GET_LIST_BLOCKS_SUCCESS"
export const GET_LIST_BLOCKS_FAILED = "GET_LIST_BLOCKS_FAILED"


export const GET_BLOCK_REQUEST = "GET_BLOCK_REQUEST"
export const GET_BLOCK_SUCCESS = "GET_BLOCK_SUCCESS"
export const GET_BLOCK_FAILED = "GET_BLOCK_FAILED"

export const FETCH_EDIT_BLOCK_DATA = "FETCH_EDIT_BLOCK_DATA"
export const UPDATE_BLOCKS_REQUEST = "UPDATE_BLOCKS_REQUEST"
export const UPDATE_BLOCKS_SUCCESS = "UPDATE_BLOCKS_SUCCESS"
export const UPDATE_BLOCKS_FAILED = "UPDATE_BLOCKS_FAILED"


export const ADD_BLOCK_DATA = "ADD_BLOCK_DATA"
export const CREATE_BLOCKS_REQUEST = "CREATE_BLOCKS_REQUEST"
export const CREATE_BLOCKS_SUCCESS = "CREATE_BLOCKS_SUCCESS"
export const CREATE_BLOCKS_FAILED = "CREATE_BLOCKS_FAILED"

export const DELETE_BLOCK_DATA = "DELETE_BLOCK_DATA"
export const DELETE_BLOCKS_REQUEST = "DELETE_BLOCKS_REQUEST"
export const DELETE_BLOCKS_SUCCESS = "DELETE_BLOCKS_SUCCESS"
export const DELETE_BLOCKS_FAILED = "DELETE_BLOCKS_FAILED"

export const FETCH_GROUP_DATA = "FETCH_GROUP_DATA"
export const GET_LIST_GROUPS_REQUEST = "GET_LIST_GROUPS_REQUEST"
export const GET_LIST_GROUPS_SUCCESS = "GET_LIST_GROUPS_SUCCESS"
export const GET_LIST_GROUPS_FAILED = "GET_LIST_GROUPS_FAILED"

export const GET_GROUPS_REQUEST = "GET_GROUPS_REQUEST"
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS"
export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED"

export const ADD_GROUP_DATA = "ADD_GROUP_DATA"
export const CREATE_GROUPS_REQUEST = "CREATE_GROUPS_REQUEST"
export const CREATE_GROUPS_SUCCESS = "CREATE_GROUPS_SUCCESS"
export const CREATE_GROUPS_FAILED = "CREATE_GROUPS_FAILED"

export const UPDATE_GROUPS_REQUEST = "UPDATE_GROUPS_REQUEST"
export const UPDATE_GROUPS_SUCCESS = "UPDATE_GROUPS_SUCCESS"
export const UPDATE_GROUPS_FAILED = "UPDATE_GROUPS_FAILED"

export const DELETE_GROUP_DATA = "DELETE_GROUP_DATA"
export const DELETE_GROUPS_REQUEST = "DELETE_GROUPS_REQUEST"
export const DELETE_GROUPS_SUCCESS = "DELETE_GROUPS_SUCCESS"
export const DELETE_GROUPS_FAILED = "DELETE_GROUPS_FAILED"

export const FETCH_WEEK_GROUP_DATA = "FETCH_WEEK_GROUP_DATA"
export const EDIT_WEEK_DATA = "EDIT_WEEK_DATA"

export const EDIT_EXCERSIZE_DATA = "EDIT_EXCERSIZE_DATA"
export const UPDATE_EXCERSIZE_DATA_REQUEST = "UPDATE_EXCERSIZE_DATA_REQUEST"
export const UPDATE_EXCERSIZE_DATA_SUCCESS = "UPDATE_EXCERSIZE_DATA_SUCCESS"
export const UPDATE_EXCERSIZE_DATA_FAILED = "UPDATE_EXCERSIZE_DATA_FAILED"

export const EDIT_GROUP_DATA = "EDIT_GROUP_DATA"

export const EDIT_BLOCK_DATA = "EDIT_BLOCK_DATA";
export const WEEK_EDIT_BLOCK_DATA = "WEEK_EDIT_BLOCK_DATA";

export const EDIT_BLOCK_EXCERSIZE_DATA = "EDIT_BLOCK_EXCERSIZE_DATA";

export const EDIT_UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS = "EDIT_UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS";
export const WEEK_EDIT_UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS = "WEEK_EDIT_UPDATE_ACTIVITY_GROUP_DETAIL_ACTIVITY_DETAILS";

export const DELETE_EDITED_COMMON_DETAILS = "DELETE_EDITED_COMMON_DETAILS"

export const WEEK_BLOCK_MODAL_DATA = "WEEK_BLOCK_MODAL_DATA"

export const WEEK_EDIT_EXCERCIZE = "WEEK_EDIT_EXCERCIZE"
export const DELETE_EDIT_WEEK_DATA = "DELETE_EDIT_WEEK_DATA"

export const CLEAR_EDIT_BLOCK_EXCERSIZE_DATA = "CLEAR_EDIT_BLOCK_EXCERSIZE_DATA"

export const ADD_WEEK_DATA_SUCCESS = "ADD_WEEK_DATA_SUCCESS"

export const CREATE_EXERCISE_REQUEST = "CREATE_EXERCISE_REQUEST"
export const CREATE_EXERCISE_SUCCESS = "CREATE_EXERCISE_SUCCESS"
export const CREATE_EXERCISE_FAILED = "CREATE_EXERCISE_FAILED"

export const GET_LIST_EXERCISE_REQUEST = "GET_LIST_EXERCISE_REQUEST"
export const GET_LIST_EXERCISE_SUCCESS = "GET_LIST_EXERCISE_SUCCESS"
export const GET_LIST_EXERCISE_FAILED = "GET_LIST_EXERCISE_FAILED"

export const GET_EXERCISE_REQUEST = "GET_EXERCISE_REQUEST"
export const GET_EXERCISE_SUCCESS = "GET_EXERCISE_SUCCESS"
export const GET_EXERCISE_FAILED = "GET_EXERCISE_FAILED"

export const GET_LIST_PROGRAM_EXERCISE_REQUEST = "GET_LIST_PROGRAM_EXERCISE_REQUEST"
export const GET_LIST_PROGRAM_EXERCISE_SUCCESS = "GET_LIST_PROGRAM_EXERCISE_SUCCESS"
export const GET_LIST_PROGRAM_EXERCISE_FAILED = "GET_LIST_PROGRAM_EXERCISE_FAILED"

export const GET_PROGRAM_EXERCISE_REQUEST = "GET_PROGRAM_EXERCISE_REQUEST"
export const GET_PROGRAM_EXERCISE_SUCCESS = "GET_PROGRAM_EXERCISE_SUCCESS"
export const GET_PROGRAM_EXERCISE_FAILED = "GET_PROGRAM_EXERCISE_FAILED"

export const CREATE_PROGRAM_EXERCISE_REQUEST = "CREATE_PROGRAM_EXERCISE_REQUEST"
export const CREATE_PROGRAM_EXERCISE_SUCCESS = "CREATE_PROGRAM_EXERCISE_SUCCESS"
export const CREATE_PROGRAM_EXERCISE_FAILED = "CREATE_PROGRAM_EXERCISE_FAILED"

export const UPDATE_PROGRAM_EXERCISE_REQUEST = "UPDATE_PROGRAM_EXERCISE_REQUEST"
export const UPDATE_PROGRAM_EXERCISE_SUCCESS = "UPDATE_PROGRAM_EXERCISE_SUCCESS"
export const UPDATE_PROGRAM_EXERCISE_FAILED = "UPDATE_PROGRAM_EXERCISE_FAILED"

export const DELETE_PROGRAM_EXERCISE_REQUEST = "DELETE_PROGRAM_EXERCISE_REQUEST"
export const DELETE_PROGRAM_EXERCISE_SUCCESS = "DELETE_PROGRAM_EXERCISE_SUCCESS"
export const DELETE_PROGRAM_EXERCISE_FAILED = "DELETE_PROGRAM_EXERCISE_FAILED"

export const GET_LIST_PROGRAM_DAYS_REQUEST = "GET_LIST_PROGRAM_DAYS_REQUEST"
export const GET_LIST_PROGRAM_DAYS_SUCCESS = "GET_LIST_PROGRAM_DAYS_SUCCESS"
export const GET_LIST_PROGRAM_DAYS_FAILED = "GET_LIST_PROGRAM_DAYS_FAILED"

export const GET_PROGRAM_DAYS_REQUEST = "GET_PROGRAM_DAYS_REQUEST"
export const GET_PROGRAM_DAYS_SUCCESS = "GET_PROGRAM_DAYS_SUCCESS"
export const GET_PROGRAM_DAYS_FAILED = "GET_PROGRAM_DAYS_FAILED"

export const CREATE_PROGRAM_DAYS_REQUEST = "CREATE_PROGRAM_DAYS_REQUEST"
export const CREATE_PROGRAM_DAYS_SUCCESS = "CREATE_PROGRAM_DAYS_SUCCESS"
export const CREATE_PROGRAM_DAYS_FAILED = "CREATE_PROGRAM_DAYS_FAILED"

export const UPDATE_PROGRAM_DAYS_REQUEST = "UPDATE_PROGRAM_DAYS_REQUEST"
export const UPDATE_PROGRAM_DAYS_SUCCESS = "UPDATE_PROGRAM_DAYS_SUCCESS"
export const UPDATE_PROGRAM_DAYS_FAILED = "UPDATE_PROGRAM_DAYS_FAILED"

export const DELETE_PROGRAM_DAYS_REQUEST = "DELETE_PROGRAM_DAYS_REQUEST"
export const DELETE_PROGRAM_DAYS_SUCCESS = "DELETE_PROGRAM_DAYS_SUCCESS"
export const DELETE_PROGRAM_DAYS_FAILED = "DELETE_PROGRAM_DAYS_FAILED"


export const GET_LIST_PROGRAM_WEEKS_REQUEST = "GET_LIST_PROGRAM_WEEKS_REQUEST"
export const GET_LIST_PROGRAM_WEEKS_SUCCESS = "GET_LIST_PROGRAM_WEEKS_SUCCESS"
export const GET_LIST_PROGRAM_WEEKS_FAILED = "GET_LIST_PROGRAM_WEEKS_FAILED"

export const GET_PROGRAM_WEEKS_REQUEST = "GET_PROGRAM_WEEKS_REQUEST"
export const GET_PROGRAM_WEEKS_SUCCESS = "GET_PROGRAM_WEEKS_SUCCESS"
export const GET_PROGRAM_WEEKS_FAILED = "GET_PROGRAM_WEEKS_FAILED"

export const CREATE_PROGRAM_WEEKS_REQUEST = "CREATE_PROGRAM_WEEKS_REQUEST"
export const CREATE_PROGRAM_WEEKS_SUCCESS = "CREATE_PROGRAM_WEEKS_SUCCESS"
export const CREATE_PROGRAM_WEEKS_FAILED = "CREATE_PROGRAM_WEEKS_FAILED"

export const UPDATE_PROGRAM_WEEKS_REQUEST = "UPDATE_PROGRAM_WEEKS_REQUEST"
export const UPDATE_PROGRAM_WEEKS_SUCCESS = "UPDATE_PROGRAM_WEEKS_SUCCESS"
export const UPDATE_PROGRAM_WEEKS_FAILED = "UPDATE_PROGRAM_WEEKS_FAILED"

export const DELETE_PROGRAM_WEEKS_REQUEST = "DELETE_PROGRAM_WEEKS_REQUEST"
export const DELETE_PROGRAM_WEEKS_SUCCESS = "DELETE_PROGRAM_WEEKS_SUCCESS"
export const DELETE_PROGRAM_WEEKS_FAILED = "DELETE_PROGRAM_WEEKS_FAILED"

export const CLEAR_PROGRAM_EXERCISE_REQUEST = "CLEAR_PROGRAM_EXERCISE_REQUEST"
export const SELECTED_PROGRAM_ID = "SELECTED_PROGRAM_ID"

export const GET_LIST_PROGRAM_REQUEST = "GET_LIST_PROGRAM_REQUEST"
export const GET_LIST_PROGRAM_SUCCESS = "GET_LIST_PROGRAM_SUCCESS"
export const GET_LIST_PROGRAM_FAILED = "GET_LIST_PROGRAM_FAILED"

export const CREATE_PROGRAM_REQUEST = "CREATE_PROGRAM_REQUEST"
export const CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS"
export const CREATE_PROGRAM_FAILED = "UPDATE_PROGRAM_FAILED"

export const UPDATE_PROGRAM_REQUEST = "UPDATE_PROGRAM_REQUEST"
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS"
export const UPDATE_PROGRAM_FAILED = "UPDATE_PROGRAM_FAILED"

export const DELETE_PROGRAM_REQUEST = "DELETE_PROGRAM_REQUEST"
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS"
export const DELETE_PROGRAM_FAILED = "DELETE_PROGRAM_FAILED"

export const CLEAR_WEEK_REQUEST = "CLEAR_WEEK_REQUEST"

export const SELECTED_WEEK_LIST = "SELECTED_WEEK_LIST"

export const CREATE_BLANK_WEEKS_REQUEST = 'CREATE_BLANK_WEEKS_REQUEST'
export const CREATE_BLANK_WEEKS_SUCCESS = 'CREATE_BLANK_WEEKS_SUCCESS'
export const CREATE_BLANK_WEEKS_FAILED = 'CREATE_BLANK_WEEKS_FAILED'



