import { useMemo, useReducer, useState } from 'react'
import { VIEWS } from 'activity/components/Tabs'
import { INITIAL_STATE, openProgramDialogAction, reducer } from 'activity/ducks'
import logger from 'use-reducer-logger'
import useApi from 'common/hooks/useApi'

export default function useActivity() {
  const [state, dispatch] = useReducer(logger(reducer), INITIAL_STATE)
  const [view, setView] = useState(VIEWS.PLAN)

  const [{ loading, data }] = useApi('metrics/program_plan/')

  const openProgramDialog = (programParams) =>
    dispatch(openProgramDialogAction(programParams))

  const programList = useMemo(() => {
    if (!loading && data && data.data) {
      const list = Object.values(data.data)
      const tempList = []
      list.forEach((program) => {
        const tempObj = {}
        tempObj.exercises = []
        tempObj.name = program.program
        tempObj.description = program.program_description
        delete program.program
        delete program.program_description
        const weekList = Object.values(program)
        weekList.forEach((week) => {
          const dayList = Object.values(week)
          dayList.forEach((day) => {
            tempObj.exercises.push(day)
          })
        })

        tempList.push(tempObj)
      })
      return tempList
    }
    return []
  }, [loading, data])

  return {
    loading,
    data,
    state,
    view,
    dispatch,
    setView,
    openProgramDialog,
    programList
  }
}
