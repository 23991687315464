import { useContext } from 'react'
import { UserContext } from './contexts'

export function useFeatureFlags() {
  const user = useContext(UserContext)

  return (
    user?.featureFlags || {
      activity: true,
      connect: false,
      fullstory: false,
      google_analytics: false,
      link_fitbit_account: false,
      link_google_account: false,
      nutrition: false,
      program: true,
      trainers_group: false,
      upload_page: false,
      wellness: false,
    }
  )
}
