import React, { useContext } from 'react'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AppBar from '@material-ui/core/AppBar'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MUIDialog from '@material-ui/core/Dialog'
import MUIDialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Select from '@material-ui/core/Select'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'
import { UserContext } from 'account'
import { app } from 'common/ducks'
import styled from 'styled-components'
import theme from 'styles/theme'

function Settings({ onClose = () => {}, show = false }) {
  const currentUser = useContext(UserContext)
  const dispatch = useDispatch()
  const selectedUser = useSelector((state) => state.app.viewDataAs)

  const userAccessList = currentUser?.hasAccessTo || []

  function handleChange(event) {
    const { value } = event.target

    dispatch(app.actions.changeViewDataAs(value))
  }

  return (
    <Settings.MUIDialog fullScreen open={show} onClose={onClose}>
      <form>
        <Settings.AppBar color="default">
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Settings.Title>Settings</Settings.Title>
          </Toolbar>
        </Settings.AppBar>

        <Settings.Content>
          <Accordion expanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Preferences
            </AccordionSummary>
            <AccordionDetails>
              <FormControl fullWidth variant="outlined">
                <InputLabel>View data as...</InputLabel>
                <Select
                  label="View data as..."
                  native
                  onChange={handleChange}
                  value={selectedUser}
                >
                  <option value="self">myself</option>
                  {userAccessList?.map((access) => (
                    <option key={access.granter} value={access.granter}>
                      {access.granter}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </AccordionDetails>
          </Accordion>
        </Settings.Content>
      </form>
    </Settings.MUIDialog>
  )
}

Settings.AppBar = styled(AppBar)`
  padding-top: env(safe-area-inset-top);
  position: static;
`
Settings.Content = styled(MUIDialogContent)`
  margin-top: calc(env(safe-area-inset-top) + 56px);
  padding: 0 !important;
`
Settings.MUIDialog = styled(MUIDialog)`
  padding-bottom: env(safe-area-inset-bottom);
`
Settings.Title = styled.h3`
  flex: 1;
  margin-bottom: 0;
  margin-left: ${theme.spacing(2)}px;
`

export default Settings
