import React, { useContext, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { isEmpty } from 'lodash/fp'
import moment from 'moment'
import styled from 'styled-components'
import { UserContext } from 'account'
import DailyCache from 'common/utils/DailyCache'
import useApi from 'common/hooks/useApi'

const dailyCache = new DailyCache('addPaymentMethodPrompt')

function AddPaymentMethodPrompt() {
  const user = useContext(UserContext)
  const [open, setOpen] = useState(true)
  const [{ loading: loadingPortal }, getCustomerPortalUrl] = useApi(
    { method: 'GET' },
    { manual: true }
  )

  const hasShownPrompt = dailyCache.get('hasShownPrompt')
  const today = moment()
  const trialEnd = moment(user?.trialEnd)

  const daysLeftInTrial = trialEnd.diff(today, 'days')
  const showPrompt = !isEmpty(user) && !user.hasPaymentSource && user.hasInTrialSubscription && !hasShownPrompt

  const isTrialEndingSoon = daysLeftInTrial < 5

  async function handleAddPaymentMethod(){
    const portalUrl = await getCustomerPortalUrl({
      url:'stripe/customer_portal_url/',
    })
    if(portalUrl.data.customerPortalUrl){
      window.location.href = portalUrl.data.customerPortalUrl
    }
  }

  const handleClose = (event, reason) => {
    if (['backdropClick', 'escapeKeyDown'].includes(reason)) {
      return
    }

    if (isTrialEndingSoon) {
      return
    }

    setOpen(false)
  }

  useEffect(() => {
    if (!hasShownPrompt) {
      setTimeout(() => {
        dailyCache.set('hasShownPrompt', true)
      }, 10000)
    }
  }, [])

  return (
    showPrompt && (
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id="alert-dialog-title">
          You are on a free trial.
        </DialogTitle>
        <DialogContent>
          <AddPaymentMethodPrompt.Content>
            <DialogContentText id="alert-dialog-description">
              <p>
                Add a payment method now to avoid service disruptions when your
                trial ends.
              </p>
              <p>
                <span className="emphasis">
                  We will not charge you until your trial period has ended and
                  you can cancel at any time.
                </span>
              </p>
              {user?.trialEnd && daysLeftInTrial > 0 && (
                <p>
                  {`You have ${daysLeftInTrial} ${
                    daysLeftInTrial === 1 ? 'day' : 'days'
                  } left in your trial.`}
                </p>
              )}
            </DialogContentText>
          </AddPaymentMethodPrompt.Content>
        </DialogContent>
        <DialogActions>
          {!isTrialEndingSoon && <Button onClick={handleClose}>Not Now</Button>}
          <Button
            autoFocus
            color="primary"
            onClick={!loadingPortal && handleAddPaymentMethod}
            variant="contained"
          >
            Add Payment
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}

AddPaymentMethodPrompt.Content = styled.div`
  .emphasis {
    font-weight: 800;
  }
`

export default AddPaymentMethodPrompt
