import React from 'react'
import Button from '@material-ui/core/Button'
import Layout from 'common/components/Layout'

function NotFound() {
  return (
    <Layout>
      <div className="error-page">
        <div className="icon-box">
          <ion-icon name="alert-circle" role="img" className="md hydrated" />
        </div>

        <h1 className="title">Doh!</h1>
        <div className="text mb-5">
          We can&apos;t find the page you&apos;re looking for. Please double
          check the URL or reach out to us for help.
        </div>

        <Button
          onClick={() => {
            window.location.href = '/'
          }}
          variant="contained"
        >
          Go Home
        </Button>
      </div>
    </Layout>
  )
}

export default NotFound
