import { useContext } from 'react'
import {
  ActivityLogContext,
  closeEndWorkoutDialogAction,
  closeStartWorkoutDialogAction,
  openEndWorkoutDialogAction
} from 'activity/ducks'

export default function useEndWorkoutDialog() {
  const { state, dispatch } = useContext(ActivityLogContext)

  const closeEndWorkoutDialog = () => {
    dispatch(closeEndWorkoutDialogAction())
  }
  const openEndWorkoutDialog = () => {
    dispatch(openEndWorkoutDialogAction())
  }

  const endWorkout = () => {
    dispatch(closeStartWorkoutDialogAction())
    closeEndWorkoutDialog()
  }

  const { endWorkoutDialog } = state.programDialog

  return {
    closeEndWorkoutDialog,
    openEndWorkoutDialog,
    endWorkoutDialog,
    endWorkout
  }
}
