import React, { useState } from 'react'
import styled from 'styled-components'
import { LayoutContext } from 'common/ducks'
import AddPaymentMethodPrompt from './AddPaymentMethodPrompt'
import AddToHome from './AddToHome'
import BackdropLoader from './BackdropLoader'
import Drawer from './Drawer'
import Header from './Header'
import Settings from './Settings'
import Sidebar from './SidebarOld'

function Layout({ children }) {
  const [appLoading, setAppLoading] = useState(false)
  const [drawerOptions, setDrawerOptions] = useState({})
  const [showSettings, setShowSettings] = useState(false)
  const [showSidebar, setShowSidebar] = useState(false)
  const [appTitle, setAppTitle] = useState('')

  function handleSettingsHide() {
    return setShowSettings(false)
  }

  function handleSettingsShow() {
    return setShowSettings(true)
  }
  function handleSidebarCollapse() {
    return setShowSidebar(false)
  }

  function handleSidebarExpand() {
    return setShowSidebar(true)
  }

  return (
    <LayoutContext.Provider
      value={{ setDrawerOptions, setAppLoading, setAppTitle }}
    >
      <Sidebar collapse={!showSidebar} onCollapse={handleSidebarCollapse} />
      <Settings show={showSettings} onClose={handleSettingsHide} />

      <div id="appCapsule">
        <Header
          onMoreOptionsTap={handleSettingsShow}
          onSidebarTap={handleSidebarExpand}
          title={appTitle}
        />
        <Layout.Content>{children}</Layout.Content>
      </div>

      <Drawer {...drawerOptions} />

      <AddPaymentMethodPrompt />
      <AddToHome />
      <BackdropLoader open={appLoading} />
    </LayoutContext.Provider>
  )
}

Layout.Content = styled.div`
  min-height: 100vh;
  background-color: #fff;
`

export default Layout
