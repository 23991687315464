import React from 'react'
import useEndWorkoutDialog from 'activity/hooks/useEndWorkoutDialog'
import MUIDialog from '@material-ui/core/Dialog'
import MUIDialogContent from '@material-ui/core/DialogContent'
import styled from 'styled-components'
import theme from 'styles/theme'

export default function EndWorkoutDialog() {
  const { closeEndWorkoutDialog, endWorkoutDialog, endWorkout } =
    useEndWorkoutDialog()
  return (
    <MUIDialog.Stylesheet
      open={endWorkoutDialog.open}
      onClose={closeEndWorkoutDialog}
      disableEnforceFocus
    >
      <EndWorkoutDialog.Content>
        <h3>Are you sure you want to end the workout?</h3>
        <button type="button" className="end" onClick={endWorkout}>
          END
        </button>
        <button
          type="button"
          className="cancel"
          onClick={closeEndWorkoutDialog}
        >
          CANCEL
        </button>
      </EndWorkoutDialog.Content>
    </MUIDialog.Stylesheet>
  )
}

EndWorkoutDialog.Content = styled(MUIDialogContent)`
  width: 19.375rem;
  h3 {
    color: ${theme.palette.secondary.g800};
    font-weight: 700;
    font-size: 1.5rem;
    margin: 2rem 0 2rem 0;
  }

  button {
    border: 0;
    width: 100%;
    border-radius: 0.625rem;
    font-weight: 700;
    font-size: 0.875rem;
    padding: 1rem;
    margin: 0.5rem 0 0.5rem 0;
  }

  .end {
    background-color: #e52328;
    color: #fff;
  }

  .cancel {
    background-color: #dfebec;
    color: ${theme.palette.secondary.g800};
  }
`
