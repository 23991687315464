import React from 'react'
import Drawer from '@mui/material/Drawer'
import styled from 'styled-components'
import moment from 'moment'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import useLogDrawer from 'activity/hooks/useLogDrawer'
import { ChevronLeft } from '@mui/icons-material'
import theme from 'styles/theme'
import LaunchIcon from '@mui/icons-material/Launch'

import LogForm from './LogForm'

export default function LogDrawer() {
  const { logDrawer, width, closeLogDrawer, state } = useLogDrawer()

  const date = moment(logDrawer?.data?.start_date).format('YYYY-MM-DD')

  let link = null

  if (logDrawer.data?.video) {
    link =  <a href={logDrawer.data?.video}>{logDrawer.data?.name} <LaunchIcon href={logDrawer.data?.video} /></a>
  }
  else {
    link =  logDrawer.data?.name
  }

  return (
    <Drawer
      anchor="right"
      open={logDrawer.open}
      onClose={closeLogDrawer}
      sx={{
        // required for visibility
        zIndex: 1300,
        padding: '1rem'
      }}
      PaperProps={{
        sx: { width: `${width}px` }
      }}
      disableEnforceFocus
    >
      <Drawer.Stylesheet>
        <Toolbar className="toolbar">
          <IconButton edge="start" color="inherit" onClick={closeLogDrawer}>
            <ChevronLeft sx={{ color: '#fff' }} />
          </IconButton>
          <Drawer.Title>{state.programDialog.data.programName}</Drawer.Title>
        </Toolbar>

        <div className="content">
          <h4>{date}</h4>
          <h2>{link}</h2>

          <LogForm />
        </div>
      </Drawer.Stylesheet>
    </Drawer>
  )
}

Drawer.Stylesheet = styled.div`
  .toolbar {
    background-color: ${theme.palette.secondary.g800};
    color: #fff;
    text-align: center;
    font-weight: 700;
    padding-top: 40px;
    padding-bottom: 16px;
  }

  .content {
    padding: 2rem;

    .fields {
      margin-top: 2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }
`

Drawer.Title = styled.div`
  text-align: center;
  width: 100%;
  margin-right: 2rem;
`
