import React from 'react'
import Layout from 'common/components/Layout'
import ProgramBuilder from '../components/ProgramBuilder'

function ProgramBuilderPage() {
  return (
    <Layout>
      <ProgramBuilder />
    </Layout>
  )
}

export default ProgramBuilderPage
