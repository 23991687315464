import React, { useContext, useEffect, useState } from 'react'
import { map } from 'lodash/fp'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { KeyboardDatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import styled from 'styled-components'
import NoData from 'common/components/NoData'
import PageTitle from 'common/components/PageTitle'
import { LayoutContext } from 'common/ducks'
import useApi from 'common/hooks/useApi'
import useAppTitle from 'common/hooks/useAppTitle'
import theme from 'styles/theme'

const DATE_RANGES = {
  LAST_30_DAYS: '30_days',
  LAST_6_MONTHS: '6_months',
  LAST_1_YEAR: '1_year',
  CUSTOM: 'custom',
}

const METRICS = [
  { display: 'Calories', value: 'calories' },
  { display: 'Distance (meters)', value: 'distanceMeters' },
  { display: 'Reps', value: 'reps' },
  { display: 'Weight (lbs)', value: 'weightLbs' },
  { display: 'Work Time (seconds)', value: 'work_time_seconds' },
  { display: 'Rest Time (seconds)', value: 'rest_time_seconds' },
]

function Analytics() {
  const { CUSTOM, LAST_30_DAYS, LAST_6_MONTHS, LAST_1_YEAR } = DATE_RANGES
  const { setAppLoading } = useContext(LayoutContext)
  const [activity, setActivity] = useState(null)
  const [metric, setMetric] = useState(METRICS[0].value)
  const [endDate, setEndDate] = useState(moment().toDate())
  const [range, setRange] = useState(LAST_30_DAYS)
  const [showDatePickers, setShowDatePickers] = useState(false)
  const [startDate, setStartDate] = useState(moment().subtract(30, 'days').toDate())
  const viewDataAs = useSelector((state) => state.app.viewDataAs)

  const [{
    loading: namesLoading,
    data: namesData = {},
  }, listNames] = useApi({
    url: 'metrics/activities/names/',
    params: {
      user: viewDataAs === 'self' ? undefined : viewDataAs,
    },
  })
  const [{
    loading: analyticsLoading,
    data: analyticsData = {},
  }, listData] = useApi('/metrics/activities/?ordering=day', { manual: true })

  function handleClickRange(selected) {
    return () => {
      setRange(selected)
      setShowDatePickers(selected === CUSTOM)

      switch (selected) {
        case LAST_30_DAYS:
          setStartDate(moment().subtract(30, 'days').toDate())
          setEndDate(moment().toDate())
          break
        case LAST_6_MONTHS:
          setStartDate(moment().subtract(6, 'months').toDate())
          setEndDate(moment().toDate())
          break
        case LAST_1_YEAR:
          setStartDate(moment().subtract(1, 'years').toDate())
          setEndDate(moment().toDate())
          break
        default:
          break
      }
    }
  }

  useAppTitle('Analytics')

  // show app loading state when XHR starts
  useEffect(() => {
    setAppLoading(namesLoading || analyticsLoading)
  }, [namesLoading, analyticsLoading, setAppLoading])

  // refetch activity names when changing view as
  useEffect(() => {
    listNames({
      params: {
        user: viewDataAs === 'self' ? undefined : viewDataAs,
      },
    })
  }, [listNames, viewDataAs])

  // refetch activities when changing inputs
  useEffect(() => {
    if (activity && endDate && startDate) {
      listData({
        params: {
          day_after: startDate,
          day_before: endDate,
          name: activity,
          user: viewDataAs === 'self' ? undefined : viewDataAs,
        },
      })
    }
  }, [activity, endDate, startDate, listData, viewDataAs])

  // set default activity
  useEffect(() => {
    if (namesData?.names?.[0] && !activity) {
      setActivity(namesData?.names?.[0])
    }
  }, [activity, namesData])

  const data = map((record) => ({
    ...record,
    day: moment(record.day).unix(),
    weightLbs: Number(record.weight_lbs),
    distanceMeters: Number(record.distance_meters),
  }))(analyticsData.results)

  return (
    <>
      <PageTitle title="Analytics" />
      <div className="section mt-2">
        <ul className="nav nav-tabs style1" role="tablist">
          <li className="nav-item">
            <button
              className={`nav-link ${range === LAST_30_DAYS ? 'active' : ''}`}
              onClick={handleClickRange(LAST_30_DAYS)}
              type="button"
            >
              30 Days
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${range === LAST_6_MONTHS ? 'active' : ''}`}
              onClick={handleClickRange(LAST_6_MONTHS)}
              type="button"
            >
              6 Months
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${range === LAST_1_YEAR ? 'active' : ''}`}
              onClick={handleClickRange(LAST_1_YEAR)}
              type="button"
            >
              1 Year
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${range === CUSTOM ? 'active' : ''}`}
              onClick={handleClickRange(CUSTOM)}
              type="button"
            >
              Custom
            </button>
          </li>
        </ul>

        {showDatePickers && (
          <>
            <KeyboardDatePicker
              className="mt-2"
              format="L"
              fullWidth
              inputVariant="outlined"
              label="Start"
              margin="dense"
              name="start"
              onChange={(date) => setStartDate(date.toDate())}
              value={startDate}
            />
            <KeyboardDatePicker
              className="mt-2"
              format="L"
              fullWidth
              inputVariant="outlined"
              label="End"
              margin="dense"
              name="end"
              onChange={(date) => setEndDate(date.toDate())}
              value={endDate}
            />
          </>
        )}

        <Autocomplete
          className="mt-1"
          onInputChange={(event, value) => setActivity(value)}
          options={namesData?.names || []}
          renderInput={(params) => (
            <TextField
              fullWidth
              label="Activity"
              margin="dense"
              name="activity"
              size="small"
              type="text"
              variant="outlined"
              {...params}
            />
          )}
          value={activity}
        />

        <FormControl
          fullWidth
          margin="normal"
          size="small"
          variant="outlined"
        >
          <InputLabel id="metric-label">Metric</InputLabel>
          <Select
            label="Metric"
            labelId="metric-label"
            name="metric"
            onChange={(event) => setMetric(event.target.value)}
            value={metric}
          >
            {METRICS.map(({ display, value }) => (
              <MenuItem key={value} value={value}>{display}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="section mt-2">
        <Analytics.ChartWrapper>
          {!analyticsLoading && (!data || !data.length) && (
            <NoData
              title="No Data"
              description="Try selecting a different activity, metric, or date range."
            />
          )}
          {data && !!(data.length) && (
            <ResponsiveContainer>
              <LineChart
                data={data}
                margin={{
                  bottom: 0,
                  left: 0,
                  right: 30,
                  top: 10,
                }}
              >
                <Line
                  dataKey={metric}
                  dot
                  stroke={theme.palette.primary.main}
                  strokeWidth={2}
                  type="monotone"
                />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip labelFormatter={(value) => moment.unix(value).format('MM/DD/YYYY HH:mm')} />
                <XAxis dataKey="day" tickFormatter={(time) => moment.unix(time).format('MM/DD/YYYY')} />
                <YAxis />
              </LineChart>
            </ResponsiveContainer>
          )}
        </Analytics.ChartWrapper>
      </div>
    </>
  )
}

Analytics.ChartWrapper = styled.div`
  // app header - title - controls - margin-top - drawer
  height: calc(100vh - 56px - 44px - 162px - 48px - 57px);

  min-height: 300px;
  width: 100%;
`

export default Analytics
