/* eslint-disable */
import React from "react";
import useApi from 'common/hooks/useApi'
import Chip from '@material-ui/core/Chip';

function BtnCellRenderer ({ api, data }) {

    const [, removeTag] = useApi(
            {
              method: 'POST',
              url: 'trainers/athlete_tags/remove_tag/',
            },
            { manual: true }
          )

    function deleteTag(tag, id) {
        const body = {
            'tags': tag,
            'id': id,
        }
        removeTag({
            data: body,
        })
        const rowNode = api.getRowNode(data.id);
        let position = data.tags.indexOf(tag);
        data.tags.splice(position, 1)
    }

  return (
    <span>
        {data?.tags?.map((tag) => (<Chip label={tag} onDelete={() => deleteTag(tag, data?.id)}/>))}
    </span>
  );
};

export default BtnCellRenderer
