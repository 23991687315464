import React from 'react'
import produce from 'immer'
import { camelCase, isNil, mapKeys } from 'lodash/fp'
import moment from 'moment'
import { SOURCES } from 'activity/constants'

export const ActivityLogContext = React.createContext()

export const PERMISSIONS = {
  READ_ONLY: 'R',
  WRITE: 'W'
}

export const INITIAL_STATE = {
  datatable: {
    data: [],
    permission: PERMISSIONS.WRITE
  },
  dialog: {
    data: {
      calories: null,
      day: moment(),
      distance: null,
      name: '',
      notes: null,
      reps: null,
      restTime: null,
      setNumber: null,
      source: SOURCES.SIDEKICK_CORE,
      tempo: null,
      weight: null,
      workTime: null,
      kpi: null,
      tags: null
    },
    ui: {
      open: false
    }
  },
  programDialog: {
    data: {
      calories: null,
      day: moment(),
      distance: null,
      name: '',
      notes: null,
      reps: null,
      restTime: null,
      setNumber: null,
      source: SOURCES.SIDEKICK_CORE,
      tempo: null,
      weight: null,
      workTime: null,
      kpi: null,
      tags: null,
      programName: null,
      programDescription: null,
      programImage: null,
      programVideo: null,
      exercises: null,
      date: null,
      totalWorkoutTime: 0
    },
    ui: {
      open: false
    },
    confirmationModal: {
      open: false
    },
    logDrawer: {
      data: null,
      open: false,
      confirmationModal: {
        open: false
      }
    },
    exerciseStatuses: {},
    startWorkoutDialog: {
      open: false
    },
    endWorkoutDialog: {
      open: false
    },
    programDoneModal: {
      open: false
    }
  },
  programCalendar: {
    data: {
      dateSelected: null
    }
  }
}

/**
 * Action Types
 */
const DELETE_ROW = 'ACTIVITY/DELETE_ROW'
const FETCHED_DATATABLE = 'ACTIVITY/FETCHED_DATATABLE'
const HIDE_DIALOG = 'ACTIVITY/HIDE_DIALOG'
const SAVE_DATA = 'ACTIVITY/SAVE_DATA'
const SELECTED_USER = 'ACTIVITY/SELECTED_USER'
const SHOW_DIALOG = 'ACTIVITY/SHOW_DIALOG'
const OPEN_PROGRAM_DIALOG = 'ACTIVITY/OPEN_PROGRAM_DIALOG'
const CLOSE_PROGRAM_DIALOG = 'ACTIVITY/CLOSE_PROGRAM_DIALOG'
const PROGRAM_CALENDAR = 'ACTIVITY/PROGRAM_CALENDAR'
const OPEN_CONFIRMATION_MODAL = 'ACTIVITY/OPEN_CONFIRMATION_MODAL'
const CLOSE_CONFIRMATION_MODAL = 'ACTIVITY/CLOSE_CONFIRMATION_MODAL'
const OPEN_LOG_DRAWER = 'ACTIVITY/OPEN_LOG_DRAWER'
const CLOSE_LOG_DRAWER = 'ACTIVITY/CLOSE_LOG_DRAWER'
const MARK_EXERCISE_AS_DONE = 'ACTIVITY/MARK_EXERCISE_AS_DONE'
const OPEN_START_WORKOUT_DIALOG = 'ACTIVITY/OPEN_START_WORKOUT_DIALOG'
const CLOSE_START_WORKOUT_DIALOG = 'ACTIVITY/CLOSE_START_WORKOUT_DIALOG'
const OPEN_PROGRAM_DONE_MODAL = 'ACTIVITY/OPEN_PROGRAM_DONE_MODAL'
const CLOSE_PROGRAM_DONE_MODAL = 'ACTIVITY/CLOSE_PROGRAM_DONE_MODAL'
const UPDATE_TOTAL_WORKOUT_TIME = 'ACTIVITY/UPDATE_TOTAL_WORKOUT_TIME'
const OPEN_END_WORKOUT_DIALOG = 'ACTIVITY/OPEN_END_WORKOUT_DIALOG'
const CLOSE_END_WORKOUT_DIALOG = 'ACTIVITY/CLOSE_END_WORKOUT_DIALOG'

/**
 * Action Creators
 */
export const deleteRowAction = (index) => ({
  type: DELETE_ROW,
  payload: index
})
export const fetchedDatatableAction = (payload) => ({
  type: FETCHED_DATATABLE,
  payload
})
export const hideDialogAction = () => ({
  type: HIDE_DIALOG
})
export const saveDataAction = (payload) => ({
  type: SAVE_DATA,
  payload
})
export const selectedUserAction = ({ granter, level }) => ({
  type: SELECTED_USER,
  payload: { permission: level, user: granter }
})
export const showDialogAction = (payload) => ({
  type: SHOW_DIALOG,
  payload
})
export const openProgramDialogAction = (payload) => ({
  type: OPEN_PROGRAM_DIALOG,
  payload
})
export const closeProgramDialogAction = (payload) => ({
  type: CLOSE_PROGRAM_DIALOG,
  payload
})
export const programCalendarAction = (payload) => ({
  type: PROGRAM_CALENDAR,
  payload
})
export const openConfirmationModalAction = (payload) => ({
  type: OPEN_CONFIRMATION_MODAL,
  payload
})
export const closeConfirmationModalAction = (payload) => ({
  type: CLOSE_CONFIRMATION_MODAL,
  payload
})
export const openLogDrawerAction = (payload) => ({
  type: OPEN_LOG_DRAWER,
  payload
})
export const closeLogDrawerAction = (payload) => ({
  type: CLOSE_LOG_DRAWER,
  payload
})
export const markExerciseAsDoneAction = (payload) => ({
  type: MARK_EXERCISE_AS_DONE,
  payload
})
export const openStartWorkoutDialogAction = (payload) => ({
  type: OPEN_START_WORKOUT_DIALOG,
  payload
})
export const closeStartWorkoutDialogAction = (payload) => ({
  type: CLOSE_START_WORKOUT_DIALOG,
  payload
})
export const openProgramDoneModalAction = (payload) => ({
  type: OPEN_PROGRAM_DONE_MODAL,
  payload
})
export const closeProgramDoneModalAction = (payload) => ({
  type: CLOSE_PROGRAM_DONE_MODAL,
  payload
})
export const updateTotalWorkoutTimeAction = (payload) => ({
  type: UPDATE_TOTAL_WORKOUT_TIME,
  payload
})
export const openEndWorkoutDialogAction = (payload) => ({
  type: OPEN_END_WORKOUT_DIALOG,
  payload
})
export const closeEndWorkoutDialogAction = (payload) => ({
  type: CLOSE_END_WORKOUT_DIALOG,
  payload
})

/**
 * Reducer
 */
function fetchedDatatableReducer(draft, action) {
  const camelCaseKeys = mapKeys((key) => camelCase(key))
  const { payload = [] } = action
  const { count, next, previous, results } = payload

  draft.datatable.count = count
  draft.datatable.next = next
  draft.datatable.previous = previous

  const serializedResults = results?.map(camelCaseKeys)

  draft.datatable.data = serializedResults?.map((datum) => {
    const {
      day,
      distanceMeters,
      restTimeSeconds,
      weightLbs,
      workTimeSeconds,
      ...rest
    } = datum

    return {
      ...rest,
      day: day ? moment(day) : null,
      distance: distanceMeters,
      restTime: restTimeSeconds,
      weight: weightLbs,
      workTime: workTimeSeconds
    }
  })

  return draft
}

function saveDataReducer(draft, action) {
  const {
    day,
    distanceMeters,
    index,
    restTimeSeconds,
    weightLbs,
    workTimeSeconds,
    ...rest
  } = action.payload

  draft.datatable.data.splice(index || 0, isNil(index) ? 0 : 1, {
    ...rest,
    day: moment(day),
    distance: distanceMeters,
    restTime: restTimeSeconds,
    weight: weightLbs,
    workTime: workTimeSeconds
  })

  return draft
}

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case FETCHED_DATATABLE:
      return fetchedDatatableReducer(draft, action)

    case DELETE_ROW:
      draft.datatable.data.splice(action.payload, 1)

      return draft

    case SAVE_DATA:
      return saveDataReducer(draft, action)

    case SELECTED_USER:
      draft.datatable.permission = action.payload.permission
      draft.dialog.data.user = action.payload.user

      return draft

    case SHOW_DIALOG:
      draft.dialog.data = {
        ...(action.payload || INITIAL_STATE.dialog.data),
        user: draft.dialog.data.user
      }
      draft.dialog.ui.open = true

      return draft

    case HIDE_DIALOG:
      draft.dialog.data = {
        ...INITIAL_STATE.dialog.data,
        user: draft.dialog.data.user
      }
      draft.dialog.ui.open = false

      return draft

    case OPEN_PROGRAM_DIALOG:
      draft.programDialog.ui.open = true
      draft.programDialog.data = action.payload
      return draft

    case CLOSE_PROGRAM_DIALOG:
      draft.programDialog.ui.open = false
      return draft

    case PROGRAM_CALENDAR:
      draft.programCalendar = action.payload
      return draft

    case OPEN_CONFIRMATION_MODAL:
      if (action.payload === 'PROGRAM_DIALOG') {
        draft.programDialog.confirmationModal.open = true
      } else if (action.payload === 'LOG_DRAWER') {
        draft.programDialog.logDrawer.confirmationModal.open = true
      }
      return draft

    case CLOSE_CONFIRMATION_MODAL:
      if (action.payload === 'PROGRAM_DIALOG') {
        draft.programDialog.confirmationModal.open = false
      } else if (action.payload === 'LOG_DRAWER') {
        draft.programDialog.logDrawer.confirmationModal.open = false
      }
      return draft

    case OPEN_LOG_DRAWER:
      draft.programDialog.logDrawer.open = true
      draft.programDialog.logDrawer.data = action.payload
      return draft

    case CLOSE_LOG_DRAWER:
      draft.programDialog.logDrawer.open = false
      return draft

    case MARK_EXERCISE_AS_DONE:
      if (draft.programDialog.exerciseStatuses[action.payload]) {
        draft.programDialog.exerciseStatuses[action.payload] = {
          ...draft.programDialog.exerciseStatuses[action.payload],
          done: true
        }
      } else {
        draft.programDialog.exerciseStatuses[action.payload] = {
          done: true
        }
      }
      return draft

    case OPEN_START_WORKOUT_DIALOG:
      draft.programDialog.startWorkoutDialog.open = true
      return draft

    case CLOSE_START_WORKOUT_DIALOG:
      draft.programDialog.startWorkoutDialog.open = false
      return draft

    case OPEN_PROGRAM_DONE_MODAL:
      draft.programDialog.programDoneModal.open = true
      return draft

    case CLOSE_PROGRAM_DONE_MODAL:
      draft.programDialog.programDoneModal.open = false
      return draft

    case UPDATE_TOTAL_WORKOUT_TIME:
      draft.programDialog.data.totalWorkoutTime = action.payload
      return draft

    case OPEN_END_WORKOUT_DIALOG:
      draft.programDialog.endWorkoutDialog.open = true
      return draft

    case CLOSE_END_WORKOUT_DIALOG:
      draft.programDialog.endWorkoutDialog.open = false
      return draft

    default:
      return draft
  }
}, INITIAL_STATE)
