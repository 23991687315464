import React from 'react'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import theme from 'styles/theme'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20rem',
  borderRadius: '1.2rem',
  bgcolor: 'background.paper'
}

const containerStyle = {
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  img: {
    marginTop: '2rem'
  },
  button: {
    border: 0,
    padding: 2,
    width: '100%',
    borderRadius: '0.8rem',
    background: theme.palette.secondary.g800,
    color: '#fff',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontWeight: 700,
    marginTop: '1rem'
  }
}

export default function CustomModal({ title, actionComponents, icon, state }) {
  return (
    <CustomModal.Stylesheet>
      <Modal open={state.open} onClose={state.closeModal}>
        <Box sx={style}>
          <Box sx={containerStyle}>
            <img src={icon} alt="Modal Icon" />
            <Typography
              variant="h5"
              component="h2"
              sx={{
                marginTop: 2,
                color: theme.palette.secondary.g800,
                fontWeight: 700,
                marginBottom: 2
              }}
            >
              {title}
            </Typography>

            {actionComponents.map((component, idx) => (
              <button
                type="button"
                key={`${component.name}-${String(idx)}`}
                style={{
                  backgroundColor: component.backgroundColor,
                  color: component.color
                }}
                onClick={component.onClick}
              >
                {component.name}
              </button>
            ))}
          </Box>
        </Box>
      </Modal>
    </CustomModal.Stylesheet>
  )
}

CustomModal.Stylesheet = styled.div`
  .container {
    border: 1px solid red;
  }
`
