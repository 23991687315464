import React from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'
import theme from 'styles/theme'

function BackdropLoader({ open }) {
  return (
    <BackdropLoader.Backdrop open={open}>
      <CircularProgress color="inherit" />
    </BackdropLoader.Backdrop>
  )
}

BackdropLoader.Backdrop = styled(Backdrop)`
  z-index: ${theme.zIndex.backdrop} !important;
`

export default BackdropLoader
